.switcher.language.switcher-language {
    .switcher-label {
        display: none;
    }

    .switcher-trigger {
        display: inline-block;
    }

    strong {
        span {
            font-weight: normal;
            font-size: 11px;
            text-transform: uppercase;

            &:before {
                vertical-align: text-top;
            }
        }
    }

    .switcher-trigger,
    .switcher-dropdown {
        [class^='view'],
        [class*=' view'] {
            white-space: nowrap;
            padding-bottom: 6px;
            display: inline-block;

            :before {
                display: inline-block;
                width: 16px;
                height: 12px;
                margin-right: 4px;
                content: ' ';
                vertical-align: middle;
            }
        }

        .view-de :before {
            background-image: url(../images/flags/de.png);
        }

        .view-en :before {
            background-image: url(../images/flags/en.png);
        }

        .view-it :before {
            background-image: url(../images/flags/it.png);
        }
    }
}



@include min-screen($screen__m) {
    .header:not(.links) {
        display: flex;
    }
    .header.panel {
        transition: all ease .2s;
        min-height: 98px;
    }
    .header-top--left {
        margin-top: 7px;
        display: flex;
    }
    .header-top--right {
        display: flex;
        margin-left: 88%;
        align-items: center;
        z-index: 999;
        @media (min-width: $screen__s) and (max-width: $screen__l) {
            position: relative;
            left: -25px;
        }
    }
    .header-minicart {
        float: right;
        width: 25px;
    }
}

.top-row {
    float: left;
    width: 100%;
    background-color: $theme--grey-light;
    height: 25px;
    position: fixed;
    top: 0;
    z-index: 999;

    @include max-screen($screen__m) {
        z-index: 1002;
    }

    .panel {
        max-width: 1170px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;

        .content-wrapper {
            float: right;

            .header-wishlist__trigger {
                position: relative;

                span.header-wishlist__text {
                    position: static;
                    font-size: 11px;
                    color: $text__color__grey;
                    padding-left: 5px;
                }

                &:before {
                    font-size: 15px;
                    color: $text__color__grey;
                    position: absolute;
                    left: -19px;
                    top: 1px;
                    width: 22px;
                }
            }

            div#switcher-language {
                float: left;
                cursor: pointer;
                font-size: 11px;
                padding-top: 5px;
                position: relative;
                color: $text__color__grey;
                position: relative;

                .switcher-label {
                    text-transform: uppercase;
                    font-weight: normal;
                }

                .switcher-options .dropdown {
                    display: none;
                }

                .switcher-options.active .dropdown {
                    display: block;
                    background: #fff;
                    z-index: 99999;
                    position: absolute;
                    padding: 5px;
                    border: 1px solid $border-grey-color;
                    left: -1px;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                    li {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    span.wishlist-counter.wishlist-qty {
        font-size: 8px;
    }
}

.header.content {
    padding-left: 0;
}

.minicart-wrapper {
    .action.showcart, .action.showcart.active {
        &:before {
            font-family: 'Pe-icon-7-stroke';
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            content: '\e66e';
            font-size: 36px;
            line-height: 40px;
            font-size: 40px;
            color: #666;
        }

        &:hover {
            color: #666;
        }
    }
}

@include min-screen($screen__m) {
    .header {
        .header-links {
            .action.showlinks {
                &:before {
                    font-family: $icons-customer__font-name;
                    font-style: normal;
                    font-weight: normal;
                    speak: none;
                    display: inline-block;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content: $icon-user-1;
                    font-size: 38px;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .minicart-wrapper {
        .action.showcart, .action.showlinks.active {
            &:before {
                font-size: 28px !important;
                margin-top: -8px !important;
            }
        }
    }
    .header.content {
        float: left;
    }
    .header--wrap.page-header {
        .logo {
            margin: 0 0 5px 0px;
        }
    }
    .top-row {
        .panel {
            .content-wrapper {
                float: left;
                width: 100%;

                .header-wishlist {
                    float: left;
                    width: 50%;
                }

                div#switcher-language {
                    float: left;
                    width: 50%;
                }
            }
        }
    }
    .page-wrapper {
        float: left;
        width: 100%;
    }
}

@media (min-width: $screen__m) and (max-width: $screen__m_plus) {
    .top-row {
        .content-wrapper {
            float: right;
            margin-right: 30px;
        }
    }
}

@media (min-width: $screen__m) and (max-width: $screen__l) {
    .header--wrap.page-header {
        .logo {
            position: relative;
            left: 65px;
            top: 0;
        }
    }
}


