@mixin box-shadow($hl: 0, $vl: 0, $blur: 15px, $spread: 1px, $color: rgba(0,0,0,.12)) {
  box-shadow: $hl $vl $blur $spread $color;
}

@mixin first($num) {

  @if $num == 1 {
    &:first-child {
      @content;
    }
  }

  @else {
    &:nth-child(-n + #{$num}) {
      @content;
    }
  }
}
@mixin last($num) {
  &:nth-last-child(-n + #{$num}) {
    @content;
  }
}
@mixin after($num) {
  &:nth-child(n+#{$num + 1}) {
    @content
  }
}
@mixin orange-btn {
  background: $theme--special-color;
  border-color: $theme--special-color;
  &:hover, &:focus, &:visited {
    background: $color-white;
    border-color: $theme--special-color;
    color: $theme--special-color;
  }
}

