//
//  Shopping cart
//  ---------------------------------------------
.checkout-cart-index {

}
.action.update.orange {
    background: $theme--accent-color--hover;
    border-color: $theme--accent-color--hover;

    &:hover {
        background-color: $color-white;
    }
}
//  Summary block
.cart-summary {
	@extend .abs-add-box-sizing;
	@extend .abs-adjustment-incl-excl-tax;
	background: $sidebar__background-color;
	margin-bottom: $indent__m;
	padding: 1px 15px $indent__m;

	& > .title {
		display: block;
		@include lib-heading(h3);
		font-size: $font-size__l - 2;
	}

	.block {
		@extend .abs-discount-block;
		margin-bottom: 0;

		.item-options {
			margin-left: 0;
		}

		.fieldset {
			margin: 15px 0 $indent__m $indent__xs;

			.field {
				margin: 0 0 $indent__s;

				&.note {
					font-size: $font-size__s;
				}
			}

			.methods {
				.field {
					& > .label {
						display: inline;
					}
				}
			}
		}

		.fieldset.estimate {
			& > .legend,
			& > .legend + br {
				@extend .abs-no-display;
			}
		}
	}
	.actions-toolbar {
		& > .primary {
			button {
				@extend .abs-revert-secondary-color;
			}
		}
	}
}
.cart-totals,
table-totals {
	tr {
		display: flex;
		width: 100%;
		th {
			display: block;
			flex-grow: 10;
			padding: 4px;
			&:last-child {
				padding-bottom: 8px;
			}
		}
		td {
			display: block;
			flex: 1 0 auto;
		}
	}
}
// Cart Sidebar
.data.table.totals {
	table-layout: fixed;
}

//  Totals block

.cart-totals {
	@extend .abs-sidebar-totals;
	border-top: 1px solid $border-color__base;
	padding-top: $indent__s;

	.table-wrapper {
		margin-bottom: 0;
		overflow: inherit;
	}
}

//  Products table
.cart.table-wrapper {
	.items {
		thead + .item {
			border-top: $border-width__base solid $border-color__base;
		}

		& > .item {
			border-bottom: $border-width__base solid $border-color__base;
			position: relative;
		}
	}

	.control.qty {
		display: flex;
		input {
			min-height: 26px;
		    height: 26px;
			min-width: 25px;
			font-size: 14px;
			font-weight: 700;
		}
	}

	.col {
		padding-top: $indent__base;

		&.qty {
			.input-text {
				@extend .abs-input-qty;
			}

			.label {
				@extend .abs-visually-hidden;
			}
		}
	}

	.item {
		&-actions td {
			padding-bottom: $indent__s;
			text-align: center;
			white-space: normal;
		}

		.col {
			&.item {
				display: flex;
				min-height: 95px;
				padding: $indent__m 0 $indent__s 0;
				position: relative;
			}
		}
	}

	.actions-toolbar {
		@extend .abs-add-clearfix;
		position: relative;
		padding: 0 0 32px;
		> .gift-options-cart-item {
			.action-gift {
				bottom: -40px;
				position: relative;
				&._active {
					bottom: 0;
				}
			}
		}

		& > .action {
			@include lib-button-as-link();
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			font-size: $font-size__base;
			margin: 0;
			background: transparent !important;
			border: 0 none !important;
			&:last-child {
				margin-right: 0;
			}
		}
		> .action-towishlist,
		> .action-edit,
		> .action-delete {
			position: absolute;
			right: 29px;
			bottom: 0;
			@include lib-icon-font(
					$icon-edit,
				$_icon-font-size: 32px,
				$_icon-font-line-height: 40px,
				$_icon-font-text-hide: true,
				$_icon-font-color: $text__color,
				$_icon-font-color-hover: $theme--accent-color--hover,
				$_icon-font-color-active: $text__color
			);
		}
		> .action-edit {
			@extend .abs-action-button-as-link;
			@include lib-icon-font-symbol(
				$_icon-font-content: $icon-edit
			);
		}
		> .action-delete {
			@extend .abs-action-button-as-link;
			right: 0;
			@include lib-icon-font-symbol(
				$_icon-font-content: $icon-trash
			);
		}
		> .action-towishlist {
			@extend .abs-action-button-as-link;

			@include lib-icon-font(
					$icon-wishlist,
				$_icon-font: $icons-customer__font-name,
				$_icon-font-size: $font-size__l - 2
			);
			right: 64px;
			width: 28px;
			text-align: center;
			height: 31px;
			padding-top: 0;

		}

	}

	.action {
		&.help.map {
			@extend .abs-action-button-as-link;
			font-weight: $font-weight__regular;
		}
	}

	.product {
		&-item-photo {
			display: block;
			left: 0;
			max-width: 80px;
			padding: 0;
			top: 15px;
			width: 100%;
		}
		&-image-photo {
			transform: none;
			left: inherit;
			top: inherit;
		}
		&-item-details {
			white-space: normal;
		}
		&-image-container {
			margin-bottom: 5px;
		}
		&-item-name {
			display: inline-block;
			font-weight: $font-weight__regular;
			margin-top: 0;
		}
	}

	.gift-registry-name-label {
		&:after {
			content: ':';
		}
	}

	//  Product options
	.item-options {
		@extend .abs-product-options-list;
		@extend .abs-add-clearfix;
		font-size: $font-size__s;
		margin-bottom: $indent__s;
	}

	.product-item-name + .item-options {
		margin-top: $indent__s;
	}

	.product-image-wrapper {
	}

	.action.configure {
		display: inline-block;
		margin: 0 0 $indent__base;
	}
}

.cart-container {
	font-size: $font-size__sm;
	.form-cart {
		@extend .abs-shopping-cart-items;
	}

	.checkout-methods-items {
		@extend .abs-reset-list;
		margin-top: $indent__base;
		text-align: center;

		.action.primary {
			@extend .abs-button-l;
			width: 100%;
		}

		.item {
			& + .item {
				margin-top: $indent__base;
			}
		}
	}
	.original-price {
		text-decoration: line-through;
	}
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
	margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
	& + .cart-tax-total {
		display: block;
	}
}

.cart.table-wrapper,
.order-items.table-wrapper {
	.col.price,
	.col.qty,
	.col.subtotal,
	.col.msrp {
		text-align: right;
	}
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
	.checkout-cart-index {
		.page-main .columns {
			padding-left: 0;
			padding-right: 0;
			float: left;
			width: 100%;
		}
	}
	.cart {
		&.table-wrapper {
			overflow: inherit;

			thead {
				.col {
					&:not(.item) {
						display: none;
					}
				}
			}

			.col {
				&.qty,
				&.price,
				&.subtotal,
				&.msrp {
					box-sizing: border-box;
					display: block;
					float: left;
					text-align: center;
					white-space: nowrap;
					width: 33%;
					padding: 5px;

					&:before {
						content: attr(data-th) ':';
						display: block;
						font-weight: $font-weight__bold;
						padding-bottom: $indent__s;
						overflow:hidden;
						white-space:nowrap;
						text-overflow: ellipsis;
					}
					&.subtotal {
						width: 33%;
					}
				}

				&.msrp {
					white-space: normal;
				}
			}

			.item {
				.col.item {
					padding-bottom: 0;
				}
			}
		}
	}

	.cart-container {
		.form-cart {
			@extend .abs-shopping-cart-items-mobile;
		}
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
	.cart-container {
		@extend .abs-add-clearfix-desktop;
		.form-cart {
			@extend .abs-shopping-cart-items-desktop;
			width: 64%;
			.action.continue,
			.action.clear,
			.action.update {
				margin-bottom: 10px;
			}
		}

		.widget {
			float: left;
		}
	}

	.cart-summary {
		float: right;
		position: relative;
		width: 34%;

		.actions-toolbar {
			.column.main & {
				@extend .abs-reset-left-margin-desktop;
				& > .secondary {
					float: none;
				}
			}
		}

		.block {
			.fieldset {
				.field {
					@include lib-form-field-type-revert($_type: block);
					margin: 0 0 $indent__s;
				}
			}
		}
	}

	.cart {
		&.table-wrapper {
			.items { // Google Chrome version 44.0.2403.107 m fix
				min-width: 100%;
				width: auto;
			}

			.item {
				.col {
					&.item {
						padding: 27px 8px $indent__s;
					}
				}

				&-actions {
					td {
						text-align: right;
					}
				}
			}

			.product {
				&-item-photo {
					padding-right: $indent__base;
					position: static;
					vertical-align: top;
				}

				&-item-details {
					vertical-align: top;
					white-space: normal;
					width: 99%;
				}
			}

			.item-actions {
				.actions-toolbar {
					@extend .abs-reset-left-margin-desktop;
					text-align: left;
				}
			}
		}
	}
}

@include min-screen($screen__l) {
	.cart-container {
		.form-cart {
			width: 73%;
		}
	}
	.cart-summary {
		width: 23%;
	}
}