//
//  Checkout Estimated Total
//  _____________________________________________

.opc-estimated-wrapper {
	@extend .abs-add-clearfix;
	@extend .abs-no-display-desktop;
	background: $theme-light;
	border-bottom: $border-width__base solid $color-gray80;
	margin: 0 -15px 15px;
	padding: 18px 15px;

	.estimated-block {
		font-size: $checkout-step-title-mobile__font-size;
		font-weight: $font-weight__bold;
		float: left;

		.estimated-label {
			display: block;
			margin: 0 0 $indent__xs;
		}
	}

	.minicart-wrapper {
		.action {
			&.showcart {
				background: none;
				border: 0;
				box-shadow: none;
				line-height: inherit;
				margin: 0;
				padding: 0;
				text-decoration: none;
				text-shadow: none;
				font-weight: 400;
				&:before {
					color: $primary__color;
				}
			}
		}
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
	.opc-estimated-wrapper {
		display: none;
	}
}