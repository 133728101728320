@import "module/buttons";
@import "module/listings-extend";
//   if your changes deeper copy
//  delete this file and make the changes on the Original File

// Line 46
.category {
    &-image {
    }

    &-view {
        .page-title-wrapper {
            text-align: center;
        }
    }

    &-description {
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;

        p {
            font-size: $font-size__sm;
            line-height: 28px;
        }

        h1 {
        }
    }
}

.catalog-category-view {
    .listed-divider {
        + .page-title-wrapper {
            margin-top: 20px;

            .page-title {
                margin-bottom: 12px;
            }
        }
    }
}

@include max-screen($screen__m) {
    .category {

        &-image,
        &-description {
            h1 {
                margin-bottom: $indent__base;
            }
        }
    }
}

@include min-screen($screen__m) {
    .category {
        &-image {
            margin-bottom: 27px;
        }

        &-description {
            margin-bottom: 20px;
        }

        &-view {
        }
    }
    .category-view {
        .category-description {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}


.product.attribute.description {
    + .listed-items {
        margin-top: 40px;
    }

    @extend %clearfix;
    background: transparent;

    > div {
    }

    p, ul {
        font-size: $font-size__sm;
        line-height: 2;
        margin-top: 0;
        margin-bottom: 21px;
        line-height: 27px;
    }

    .wrapper-text, .wrapper-text--noImg {
        padding: 15px;
        box-sizing: border-box;
    }

    .map-description {
        height: 0;
        overflow: hidden;
        padding-bottom: 46%;
        position: relative;

        &:empty {
            display: none;
        }
    }

    .map-description iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        pointer-events: none;
    }

    @include min-screen($screen__m) {

        [class*="description-"] {
            width: 50%;
            box-sizing: border-box;
            margin-bottom: 15px;

            img {
                width: 100%;
            }
        }
        .description-left {
            float: right;
            padding-left: 15px;
        }
        .description-right {
            float: left;
            padding-right: 15px;
        }
        .wrapper-text--noImg > :not(div), .value > :not(div) {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include min-screen($screen__l2) {
        + .listed-items {
            margin-top: 85px
        }
        .wrapper-text {
            max-width: 50%;
        }
        [class*="description-"] {
            margin-bottom: 0;
        }
        .wrapper-text > p {
            max-width: 615px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @include min-screen($screen__xl) {
        .wrapper-text, .wrapper-text--noImg {

        }
        .value > {
            p, ul {
                &:first-child {
                }

                &:last-child {
                }
            }
        }
    }
    @include max-screen($screen__m) {
        [class*="description-"] {
            float: none;
        }
    }


}


//line 60
// improve that the Image can be smaller then the parent Conatiner

.product-image {
    &-container {
        display: inline-block;
        //max-width: 175px;
        margin-bottom: 21px;
        width: 100%;
        @include max-screen($screen__s) {
            margin-bottom: 0;
        }

        img {
            @include max-screen($screen__s) {
                margin-bottom: 0 !important;
            }
        }
    }

    &-wrapper {
        display: block;
        overflow: hidden;
        position: relative;
        z-index: 1;
        // remove height
        @include max-screen($screen__m) {
            padding-bottom: 100% !important;
        }
    }

    &-photo {
        position: absolute;
        opacity: 0;
        left: 50%;
        top: 0;
        bottom: auto;
        right: auto;
        display: block;
        height: auto;
        margin: auto;
        width: 100%;
        //max-width: 175px;
        transform: translate(-50%, 0);
        transition: opacity 0.1s ease-in;

        &.lazyloaded {
            top: 0;
            opacity: 1;
            transition: opacity 0.1s ease-in;
        }

        &.lazyloading {
            opacity: 0.5;
        }
    }
}

// Line 124

.product.info.detailed {
    background: transparent;
    clear: both;
    margin-bottom: 30px;
    @include min-screen($screen__m) {
        margin: 40px auto 65px;
        width: 100%;
        max-width: 100%;
    }

    .product.data.items {
        padding: 35px 0 30px;
        border-top: 1px solid $theme--grey-middle;
        border-bottom: 1px solid $theme--grey-middle;
    }

    .additional-attributes {
        display: block;
        width: auto;
        margin-bottom: 0;
        @include lib-table-resize(
            $_td-padding-top: 12px,
            $_td-padding-right: 12px,
            $_td-padding-bottom: 12px,
            $_td-padding-left: 12px,
            $_th-padding-top: 12px,
            $_th-padding-right: 12px,
            $_th-padding-bottom: 12px,
            $_th-padding-left: 12px
        );

        tbody {
            display: block;

            @include min-screen($screen__s + 1) {
                column-count: 2;
                column-gap: 0;
            }
        }

        tr {
            display: block;
            box-sizing: border-box;
            @include min-screen($screen__s + 1) {
                -moz-column-break-inside: avoid;
                -webkit-column-break-inside: avoid;
                -mx-column-break-inside: avoid;
                column-break-inside: avoid;
                &:not(.top) {
                    th, td {
                        border-top: 1px solid $color-white !important;
                    }
                }
            }
            @include min-screen($screen__m) {
                padding-left: 30px;

            }
        }

        th, td {
            box-sizing: border-box;
            font-weight: $font-weight__regular;
            @include min-screen($screen__s + 1) {
            }

        }

        th {
            min-width: 125px;
            font-size: $font-size__sm;
            color: $theme--grey-dark;

        }

        td {
            font-size: $font-size__m;
            @include min-screen($screen__s + 1) {
                width: 75%;
            }
            @include max-screen($screen__s) {
            }

            &:before {
                width: 25%;
                font-size: $font-size__sm !important;
                color: $theme--grey-dark !important;
                font-weight: $font-weight__regular !important;
            }
        }
    }

    .product.data.items > .item.title {
        font-size: 24px;
    }
}

.product {
    &.name a {
        font-weight: $font-weight__heavier;
    }
}

// Line 139
.product-info-main {
    .page-title-wrapper {
        h1.page-title {
            font-size: 20px;
            font-weight: 500;
            color: $color-dark-gray;
        }
    }

    .product-reviews-summary .reviews-actions {
        font-size: $font-size__s;
        text-transform: uppercase;

        a {
            color: $color-dark-gray;

            &:hover {
                color: $theme--special-color;
            }
        }
    }

    .price-label {
        @extend .abs-visually-hidden;
    }

    .product-options-wrapper {
        margin-bottom: 21px;
    }

    .product-options-bottom {
        @extend %clearfix;
    }

    .product-add-form {
        width: 100%;
        float: left;
        margin-top: 30px;
    }

    .divider--product-options {
        clear: both;
        float: none;
        height: 1px;
        background: transparent;
    }

    .product-social-links {
        width: 100%;
        float: left;
    }

    .product-info-price {
        @include max-screen($screen__m) {
            width: 100%;
        }
    }

    .special-price {
        margin: 0;
        color: $theme__special-color;
        order: 2;

        + .old-price {
            display: block;
        }
    }

    .onlyBasePrice {
        color: $theme--grey-middle!important;
    }

    .old-price {
        order: 0;
        margin-right: 15px;

        .price {
            position: relative;

            &:after {
                border-top: 2px solid #000;
                position: absolute;
                content: "";
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }

        }
    }

    .price-box {
        display: flex;
        align-items: center;

        .price-wrapper .price {
            font-family: $heading__font-family__base;
            font-size: $product-info-price;
            font-weight: $font-weight__bold;
            display: inline-block;
        }
    }

    .stock-delivery{
        margin-bottom: 30px;
    }

    .price-final_price{
        margin-top: 0;
    }

    .prices-tier {
        background: none;
    }

    .price-details, .shipping-cost {
        font-family: $font-family__base;
        font-size: ceil(($font-size__base * .8));
        display: inline-block;
        position: relative;

        a {
            color: $theme--special-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .box-tocart {
        .actions {
            padding-top: 25px;
            @include max-screen($screen__m) {
                width: 100%;
                display: block;
            }
        }

        .field.qty {
            display: flex;
            align-items: center;
        }
    }

    .field.qty {
        display: flex;
        margin-bottom: 0;
        align-items: center;
        @include max-screen($screen__m) {
            width: 100%;
        }

        &:after, &:before {
            content: '';
            clear: both;
            display: table;

        }

        .control {
            display: flex;
        }

        .label {
            margin-bottom: 0;
            padding: 0 15px 0 0;
            font-size: $font-size__m;
            font-weight: $font-weight__bold;
            box-sizing: border-box;
            flex: 0 1 21%;
            @include min-screen($screen__m) {
                flex: 0 1 100px;
            }
        }
    }

    .input-text.qty {
        height: 44px;
        width: 45px;
        text-align: center;
    }

    .product-addto-links {
        display: block;
        vertical-align: bottom;
        margin: 20px 0 0;
        @include max-screen($screen__m) {
            width: 100%;
            display: block;
        }
    }

    .product-addto-links-2 {
        margin-bottom: 20px;
        display: flex;
        @include max-screen($screen__s) {
            width: 100%;
            display: block;
        }
    }

    .stock.available, .product-delivery-standard {
        font-size: $font-size__s;
        font-family: $font-family__base;
        font-weight: $font-weight__regular;
        text-transform: none;
        position: relative;
        align-items: center;
        text-transform: uppercase;
        color: $theme--special-color;

    }

    .stock-available__icon,
    .delivery-time__icon {
        @include lib-icon-font(
            $_icon-font: $icons-customer__font-name,
            $_icon-font-content: none,
            $_icon-font-color: $text__color,
            $_icon-font-size: $icon-lagernd--font-size,
            $_icon-font-line-height: $icon-lagernd--font-size,
            $_icon-font-display: block,
            $_icon-font-text-hide: true
        );
        margin-right: 5px;

        &:hover {
            &:before {
                color: $theme__base--color;
            }
        }
    }

    .stock-available__icon {
        &:before {
            content: $icon-lagernd;
            height: $icon-lagernd--font-size + 1;
            width: $icon-lagernd--font-size + 1;
            line-height: $icon-lagernd--font-size + 1;
        }
    }

    .delivery-time__icon {
        &:before {
            content: $icon-lieferzeit;
            height: $icon-lagernd--font-size + 1;
            width: $icon-lagernd--font-size + 1;
            line-height: $icon-lagernd--font-size + 1;
        }
    }

    .stock.available {

    }

    .product-delivery-standard {
        margin-right: 20px;
    }


    .product {
        &.attribute {
            &.sku {
                font-family: $font-family__base;
                margin-bottom: $indent__s;
                font-size: $font-size__m;

                strong {
                    font-weight: $font-weight__regular;
                }
            }

            &.overview {
                font-size: $font-size__sm;
                line-height: 2;
                margin: 0 0 10px;
                float: left;
                width: 100%;
            }
        }
    }

}

// Button plus/minus style

button#btn-dec {
    width: 42px;
    height: 44px;
    font-weight: bold;
    background: #d1d1d1;
    border-color: #d1d1d1;
    font-size: 2.2rem;
}

button#btn-upt {
    width: 42px;
    height: 44px;
    font-weight: bold;
    background: #d1d1d1;
    border-color: #d1d1d1;
    font-size: 2.2rem;
}

button.decreaseQty, button.increaseQty {
    width: 26px;
    height: 26px;
    padding: 0;
    font-weight: bold;
    background: #d1d1d1;
    border-color: #d1d1d1;
    font-size: 1.7rem;
}

// 237
.product-options-bottom {
    .product-reviews-summary {
        margin-bottom: 20px;
    }
}

// Line 494
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-layout-1column {
        .product-info-main {
            width: 48%;
        }

        .product.media {
            width: 50%;
        }
    }

    .product-info-main {

        .product-addto-links {
            margin-top: 20px;
        }

        .page-title-wrapper {
            .page-title {
                margin-top: 0;
                font-size: ceil(($font-size__base * 2.15));
            }
        }

    }
}

.abs-product-link > a:visited, .product-item-name > a:visited, .product.name a > a:visited {
    color: $text__color__grey;
}

/*
Core Bug - Quick Fix
You can also scroll with the arrows above. There are currently two fotorama arrows in media gallery, the second one can be hidden, because they don't work anyway
https://github.com/magento/magento2/issues/25652
 */
.fotorama__nav--thumbs {
    .fotorama__thumb__arr {
        display: none !important;
    }
}

.c2-financing-label {
    color: $theme--special-color;
}

.fotorama {
    &--fullscreen {
        z-index: 1002 !important;
    }
}
