
// Content Banner
.js-content-banners {
  &:not(.slick-initialized) {
    .banner__item {
      min-height: 590px;
      &:first-child {
        img:not(.lazyloaded) {
          padding-bottom: 41.9%; // aspect ratio -> screendesign
        }
      }
    }
  }
  .banner {
    &__item {
    }
    &__content {
    }
  }
  .slick-prev {
    left: -10px;
  }
  .slick-next {
    right: -10px;
  }
  .slick-arrow {
    background: $theme--accent-color;
    width: 30px;
    height: 22px;
    &:hover, &:focus {
      background: $color-white;
      border: 1px solid $theme--accent-color;
      svg {
        fill: $theme--accent-color;
      }
    }
    svg {
      height: 13px;
      width: 17px;
      fill: $color-white;
    }
  }
}
@include min-screen($screen__m) {
  .js-content-banners {
    // Content Wide
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;

    max-width: 100%;
    width: 100%;
    .slick-arrow {
      width: 50px;
      height: 40px;
      svg {
        height: 13px;
        width: 32px;
      }
    }
  }

}
@include min-screen($screen__l2) {
  .js-content-banners {
    .slick-prev {
      left: -15px;
    }
    .slick-next {
      right: -15px;
    }
    .slick-arrow {
      width: 60px;
      height: 45px;
      svg {
        height: 13px;
        width: 42px;
      }
    }
  }
}

