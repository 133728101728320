$divider__base--color: $theme--grey-middle;

// Custom Listing Container
.listed-items {
	text-align: center;
	ol.products {
		margin: 0;
	}
	// this can be a class
	+ .listed-items,
	&.section--accent {

		> .block {
			padding-top: 10px;
		}
	}
	&.section--accent {
		+ .section--accent {
			+ div {
			}
		}
	}
	> .block,
	> .block-content {
		max-width: $layout__max-width;
		margin: 0 auto;
		padding: 5px 15px 0;
		box-sizing: border-box;
		@include min-screen($screen__l2) {
			padding-top: 15px;
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 20px;
		}
		.block {
			margin-bottom: 0;
		}
	}
	.action.select {
		@include lib-button-primary(
			$_button-background: $link__color,
			$_button-border: 1px solid $link__color,
			$_button-background-hover: $link__hover__color,
			$_button-border-hover: 1px solid $link__hover__color,
			$_button-background-active: $link__color,
			$_button-border-active: 1px solid $link__color,
			$_button-padding: 15px 20px
		);
		font-size: $font-size__sm;
		font-family: $font-family__base;
		text-transform: uppercase;
		margin: 5px auto 0;
		display: block;
		@include min-screen($screen__m) {
			display: inline-block;
			margin: 0 0 0 7px;
		}
	}
	.block-title,
	.block-title.title {
		margin-bottom: 0;
		position: relative;
		@include min-screen($screen__m) {
			margin-bottom: 25px;
		}
		strong {
			@include lib-heading(h1);
			display: inline-block;
			position: relative;
			font-size: 25px;
			text-transform: none;
			padding: 0 15px;
			@include min-screen($screen__m) {
				font-size: 24px;
			}
		}
	}
	.title {
		margin-bottom: 30px;
		@include lib-heading(h1);
		display: inline-block;
		position: relative;
		font-size: 20px;
		text-transform: none;
		padding: 0 15px;
		@include min-screen($screen__m) {
			font-size: 30px;
		}
	}
	p {
		font-size: $font-size__m;
	}
	@include min-screen($screen__m) {
		+ .listed-items,
		&.section--accent {
		}
	}
	@include min-screen($screen__l2) {
		+ .listed-items,
		&.section--accent {
		}
	}
	@include max-screen($screen__m) {
	}
}

.listed {
	&-divider {
		@extend %primary-trail--desktop;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		&:after {
			content: '';
			height: 1px;
			background: #393939;
			display: inline-block;
			position: absolute;
			z-index: -1;
			left: 0;
			top: calc(50% - 5px);
			right: 0;
		}
	}
	&__icon {
		display: inline-block;
		padding: 10px 10px 20px;
		background: white;
	}
	&__icon:before {
		font-size: 35px;
		@include min-screen($screen__m) {
			font-size: 65px;
		}

	}
}

.products {
	margin: 0;
}
.page-layout-2columns-left .column.main {
	.products {
		margin-top: 10px;
	}
}
.product {
	&-item {
		&-actions {
			display: block;
			a.action.tocompare {
				display: none;
			}
			.actions-primary {
				display: none;
			}
			.actions-secondary {
				display: block;
				padding-left: 5px;
				white-space: nowrap;
				width: auto;
				position: absolute;
				top: 18px;
				right: 18px;
				z-index: 1;
				display: none;
				& > * {
					white-space: normal;
				}
				.towishlist.active {
					.add-to-wishlist__svg {
						fill: $color-white;
						.st0 {
							fill: $theme__base--color;
						}
					}
				}
				&:hover {
					.add-to-wishlist__svg {
						fill: $color-white;
						.st0 {
							fill: $theme__base--color;
						}
					}
				}
			}
		}
		&-name {
			font-family: $font-family-name__heading;
			font-weight: $font-weight__heavier;
			font-size: $font-size__s + 1;
			margin-bottom: 0;
			margin-top: 10px;
			a {
				font-family: $font-family-name__heading;
				font-size: $font-size__s + 1;
				color: $color-dark-gray;
				&:hover {
					color: $link__hover__color;
					text-decoration: none;
				}
			}
		}



		&--sale {
			font-family: $font-family-name__heading;
			font-size: $font-size__s + 1;
			font-weight: $font-weight__regular;
			text-transform: uppercase;
			color: white;
			background: $theme__secondary--accent-color;
			padding: 4px 11px 3px;
			position: absolute;
			left: 0;
			top: 23px;
			z-index: 1;
			.products-list & {
				top: 53px;

			}
		}
		.extended-pricebox {
			float: left;
			width: 100%;
			max-height: 40px;
		}
		.price-box {
			display: block;
			justify-content: center;
			align-items: center;
			margin-top: 0;
			margin-bottom: 0;
            float: left;
            width: 100%;
            min-height: 40px;
			@include max-screen($screen__s) {
				position: relative;
			}
				.products-list & {
				justify-content: inherit;
			}
			.price-label {
                &:after {
                    content: '';
                }
			}
			.price {
				font-family: $font-family-name__heading;
				font-weight: 700;
				line-height: 23px;
			}
			.special-price {
				margin: 0;
				color: $theme__special-color;
				order: 2;
				width: 50%;
				text-align: right;
				padding-right: 10px;
				.price-including-tax
				+ .price-excluding-tax {
					display: none;
				}
				.price-label {
					display: none;
				}
			}
			.old-price {
				color: $color-dark-gray;
				order: 0;
				float: left;
				width: 50%;
				text-align: left;
				padding-left: 10px;
				.price {
					position: relative;
					&:after {
						border-top: 2px solid #000;
						position: absolute;
						content: "";
						right: 0;
						top:50%;
						transform: translateY(-50%);
						left: 0;
					}
				}
				.price-label {
					display: none;
				}
			}
			.regular-price {
				float: left;
				width: 100%;
				.price-label {
					display: none;
				}
			}
            .price-container {
                float: left;
                width: 100%;
                display: block;
            }
            .minimal-price-link {
                float: left;
                width: 100%;
                display: block;
				@include max-screen($screen__s) {
					position: absolute;
					top: 17px;
				}
            }
		}
		&-description {
			.products-list & {
				margin: 10px 0;
			}
		}
		&-inner {
			.products-list & {
				padding-top: 0;
			}
		}
		.tocart {
			overflow:hidden;
			white-space:nowrap;
			text-overflow: ellipsis;
		}
	}

}

.catalogsearch-result-index {
	.products-list .product-item-actions .actions-secondary {
		top: 19px !important;
		left: 18px !important;
	}
}
