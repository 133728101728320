//
//  Variables
//  _____________________________________________

$checkout-wrapper__margin             : $indent__base !default;
$checkout-wrapper__columns            : 8 !default;

$checkout-step-title__border          : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size       : 26px !default;
$checkout-step-title__font-weight     : $font-weight__light !default;
$checkout-step-title__padding         : $indent__s !default;

$checkout-step-title-mobile__font-size: 18px !default;

//
//  Common
//  _____________________________________________
.table-checkout-shipping-method {
	width: 100%;
	table-layout: fixed;
	tr.row {
		padding-left: 15px;
		padding-right: 15px;
	}
}
.page-layout-checkout {
	.page-header {
		margin-bottom: 0;
	}
	.header.content  {
		transform: none;
		left: inherit;
		margin-bottom: 10px;
		margin-top: 40px;
		position: relative;
		@include max-screen($screen__s) {
			width: 100%;
		}
		.logo {
			position: initial;
			transform:none;
			left: inherit;
		}
	}
	#tooltip-label {
		display: none;
	}
}

.checkout-index-index,
.checkout-onepage-success {
	.page-title-wrapper {
		a {
			display: block;
			margin-bottom: 10px;
			text-decoration: underline;
		}
	}
}

.checkout-container {
	@extend .abs-add-clearfix;
	margin: 0 0 $checkout-wrapper__margin;
	.action.primary {
		@include orange-btn;
	}
}

.opc-wrapper {
	margin: 0 0 $checkout-wrapper__margin;

	.opc {
		@extend .abs-reset-list;
	}

	.step-title {
		@extend .abs-checkout-title;
	}

	.step-content {
		margin: 0 0 $indent__xl;
	}
}

.checkout-index-index {
	.nav-sections,
	.nav-toggle {
		display: none;
	}

	.logo {
		margin-left: 0;
	}
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
	.opc-wrapper {
		.step-title {
			font-size: $checkout-step-title-mobile__font-size;
			border-bottom: 0;
			padding-bottom: 0;
		}
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
	.opc-wrapper {
		@extend .abs-add-box-sizing-desktop-m;
		@include lib-layout-column(2, 1, $checkout-wrapper__columns);
		padding-right: $indent__l;
	}
}