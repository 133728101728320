.um-gallery-container {
	float: left;
	width: 100%;
	.block-title {
		float: left;
		width: 100%;
		padding: 10px;
		h1 {
			margin: 0px;
			padding: 10px 0px;
			text-align: center;
		}
	}
	.block-content {
		float: left;
		width: 100%;
		padding: 10px;
	}
	.um-gallery-block {
		float: left;
		width: 100%;
	}
}
.um-gallery-filters {
	float: left;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 0px;
	text-align: center;
}
.um-gallery-image-items {
	float: left;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 0px;
}
.um-gallery-controls {
	float: left;
	width: 100%;
	padding-top: 10px;
	padding-bottom: 20px;
}
.um-gallery-items {
	margin: 0px;
	padding-top: 0px;
	padding-bottom: 20px;
	li {
		display: inline-block;
		margin-right: 10px;
		padding: 4px 15px;
		border: 1px solid #888;
		margin-bottom: 0px;
		a {
			color: #333;
			text-decoration: none;
			cursor: pointer;
		}
	}
	.active {
		background: #888;
		a {
			color: #fff;
		}
	}
}
.um-controls-wrap {
	display: flex;
	align-items: center;
	justify-content: center;
}
.um-shuffle-block {
	float: left;
	width: auto;
	.shuffle-btn {
		border: 1px solid #888;
		margin-bottom: 0px;
		background: #888;
		box-shadow: none;
		border-radius: 0px;
		color: #fff;
	}
}
.um-search-block {
	float: left;
	width: auto;
	padding: 0px 10px;
	input {
		box-shadow: none !important;
	}
}
.um-sorting-block {
	float: left;
	width: auto;
}
.um-sortandshuffle {
	float: left;
	width: auto;
	padding: 0px 10px;
	margin: 0px;
	li {
		display: inline-block;
		margin-right: 10px;
		padding: 4px 15px;
		border: 1px solid #888;
		margin-bottom: 0px;
		background: #888;
		color: #fff;
	}
}
.um-fltr-sorting {
	float: left;
	width: auto;
	box-shadow: none !important;
}
#um-filtr-item {
	width: 25%;
	padding: 7px;
}
.um-gal-image {
	img {
		-webkit-filter: grayscale(70%);
		filter: grayscale(70%);
		&:hover {
			filter: none;
		}
	}
	&:hover {
		.um-gi-icon {
			visibility: visible;
		}
	}
}
.um-gi-caption-block {
	background-color: rgba(0,0,0,0.5);
	border-radius: 0px;
	left: 0.7rem;
	right: 0.7rem;
	bottom: 1.1rem;
	color: #fff;
	font-weight: 300;
	display: block;
	padding: 7px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	h4 {
		text-transform: none;
		margin: 0px;
		padding: 5px;
		text-align: left;
	}
	h5 {
		text-transform: none;
		margin: 0px;
		padding: 5px;
		text-align: left;
		font-weight: 400;
		line-height: 20px;
		font-size: 14px;
		color: #f3f0f0;
		white-space: nowrap;
		overflow: hidden;
		width: 100%;
	}
}
.um-gallery-block {
	.um-gi-icon {
		position: absolute;
		bottom: 55%;
		left: 50%;
		visibility: hidden;
		cursor: pointer;
		i {
			font-size: 30px;
			color: #fff;
		}
	}
}
