.message.info {
    @include lib-message-icon-inner(info);
    margin-bottom: 25px;
}

.message.error {
    @include lib-message-icon-inner(error);
    margin-bottom: 25px;
    background: #fae5e5;
    color: #e02b27;
}

.message.warning {
    @include lib-message-icon-inner(warning);
    margin-bottom: 25px;
}

.message.notice {
    @include lib-message-icon-inner(notice);
    margin-bottom: 25px;
}

.message.success {
    @include lib-message-icon-inner(success);
    margin-bottom: 25px;
    color: rgb(0, 100, 0);
    background: rgb(229, 239, 229);

    a {
        color: rgb(0, 100, 0);
        text-decoration: underline;
    }
}

.page.messages {
    float: left;
    width: 100%;
    .message {
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding: 10px 20px;
    }
}