// Brand
.right-brand {
	margin-bottom: 10px;
	img {
		max-height: 25px;
		max-width: 190px;
		width: auto;
	}
}

.product {
	&-item {
		&__brand {
			font-family: $font-family-name__heading;
			font-weight: $font-weight__regular;
			font-size: $font-size__s + 1;
			margin-bottom: 0;
			margin-top: 7px;
		}
	}
}