// ThemeStyle
//  ---------------------------------------------
// Override the Standard Module
//
//  Variables
//  _____________________________________________

$active-nav-indent: 54px;

//
//  Common
//  _____________________________________________

.panel.header {
    .links,
    .switcher {
        display: none;
    }
}

.nav-sections {
    background: $theme__base--color;
}

.nav-toggle {
    @include lib-icon-font(
                    $icon-menu,
            $_icon-font-size: 28px,
            $_icon-font-color: $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover
    );
    @include lib-icon-text-hide();
    cursor: pointer;
    display: block;
    font-size: 0;
    left: 15px;
    position: absolute;
    top: 15px;
    z-index: 14;
    @include max-screen($screen__l_plus) {
        &:before {
            width: 20px;
            background-image: url(../images/svgs/burger_blue.svg);
            background-repeat: no-repeat;
            background-position: center center;
            content: "";
            height: 30px;
            background-size: contain;
            display: block;
            position: absolute;
            top: 0px;
            left: -6px;
        }
    }
    @media (min-width: $screen__m) and (max-width: $screen__l_plus) {
        &:before {
            width: 35px;
            height: 35px;
            top: 0;
            left: 5px;
        }
    }

}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l_plus) {
    .nav-sections-item-title.active {
        background: $theme__base--color !important;

        > a {
            color: #F5F7F9 !important;
        }
    }
    .nav-sections-item-title {
        background: #F5F7F9 !important;
        border: none !important;

        > a {
            text-transform: uppercase;
            font-size: 10px;
            color: $theme__base--color !important;
            font-weight: normal;
        }

    }
    .nav-sections-item-content {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .navigation .active > a:not(.ui-state-active), .navigation .has-active > a:not(.ui-state-active) {
        border-color: #F5F7F9 !important;
    }
    .cms-home-new .navigation .active > a:not(.ui-state-active), .cms-home-new .navigation .has-active > a:not(.ui-state-active) {
        border-color: $theme__base--color !important;
    }
    .navigation .level0.active .all-category .ui-state-focus {
        border-color: #F5F7F9 !important;
    }
    .navigation {
        padding: 0;

        .parent {
            .level-top {
                position: relative;
                @include lib-icon-font(
                        $_icon-font-content: $icon-down,
                        $_icon-font-size: 42px,
                        $_icon-font-position: after,
                        $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: 10px;
                    top: 18px;
                }

                &.ui-state-active {
                    @include lib-icon-font-symbol(
                            $_icon-font-content: $icon-up,
                            $_icon-font-position: after
                    );
                }
            }
        }

        ul {
            .level0 > .level-top {
                padding: 15px 40px 15px 15px;
                font-weight: normal;
                color: #F5F7F9;
            }

            background: $theme__base--color;

            li {
                border-top: none !important;
                border-bottom: 2px solid #6A676B !important;
            }

            a {
                text-transform: none !important;
            }
        }

        .submenu li {
            border: none !important;

            a {
                color: #F5F7F9 !important;
                font-weight: normal;

            }
        }

        .parent .level-top:after {
            color: #B8B2B9 !important;
        }

        .parent .level-top:after {
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../images/svgs/chevron_right_grey.svg");
            width: 14px;
            height: 12px;
        }

        .parent .level-top.ui-state-active:after {
            -ms-transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("../images/svgs/chevron_right_grey.svg");
            width: 14px;
            height: 12px;
        }
    }

    .nav-sections {
        -webkit-overflow-scrolling: touch;
        transition: left 0.3s;
        height: 100%;
        left: -80%;
        left: calc(-1 * (100% - #{$active-nav-indent}));
        overflow: auto;
        position: fixed;
        top: 0;
        width: 80%;
        width: calc(100% - #{$active-nav-indent});

        .switcher {
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0;
            padding: 0.8rem 3.5rem 0.8rem 2rem;

            .label {
                display: block;
                margin-bottom: $indent__xs;
            }

            &:last-child {

            }
        }

        .switcher-trigger {
            strong {
                position: relative;
                @include lib-icon-font(
                        $_icon-font-content: $icon-down,
                        $_icon-font-size: 42px,
                        $_icon-font-position: after,
                        $_icon-font-display: block
                );

                &:after {
                    position: absolute;
                    right: -40px;
                    top: -16px;
                }
            }

            &.active strong {
                @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: after
                );
            }
        }

        .switcher-dropdown {
            @include lib-list-reset-styles();
            padding: $indent__s 0;
        }

        .header.links {
            @include lib-list-reset-styles();

            li {
                font-size: 1.6rem;
                margin: 0;
                border-top: none !important;
                border-bottom: 2px solid #6A676B !important;

                &.greet.welcome {
                    font-weight: 700;
                    padding: 0.8rem $indent__base;
                }

                & > a {
                    @include max-screen($screen__l_plus) {
                        padding: 15px 40px 15px 15px;
                        font-weight: normal;
                        color: #F5F7F9;
                    }

                }
            }

            a {
                color: $navigation-level0-item__color;
                text-decoration: $navigation-level0-item__text-decoration;
                display: block;
                font-weight: 700;
                padding: 0.8rem $indent__base;
            }

            .header.links {
                border: 0;
            }
        }
    }

    .nav-before-open {
        height: 100%;
        overflow-x: hidden;
        width: 100%;

        .page-wrapper {
            transition: left 0.3s;
            height: 100%;
            left: 0;
            overflow: hidden;
            position: relative;
        }

        body {
            height: 100%;
            overflow: hidden;
            position: relative;
            width: 100%;
        }
    }

    .nav-open {
        .page-wrapper {
            left: 80%;
            left: calc(100% - #{$active-nav-indent});
        }
        .header--wrap {
            @media (min-width: $screen__m) and (max-width: $screen__l_plus) {
                padding-top: 0;
            }
        }
        .top-row {
            display: none;
        }
        .nav-sections {
            $_shadow: 0 0 5px 0 rgba(50, 50, 50, 0.75);

            box-shadow: $_shadow;
            left: 0;
            z-index: 99999;
        }

        .nav-toggle {
            &:after {
                background: rgba(0, 0, 0, 0.7);
                content: '';
                display: block;
                height: 100%;
                position: fixed;
                right: 0;
                top: 0;
                width: 100%;
                z-index: 1;
                @media (min-width: $screen__m) and (max-width: $screen__l_plus) {
                    min-height: 1030px;
                    top: -15px;
                }
            }
        }
    }

    .nav-sections-items {
        position: relative;
        z-index: 1;
    }
    .nav-sections-item-content {
        &:after {
            display: none !important;
        }
    }

    .nav-sections-item-title {
        background: darken($navigation__background, 5%);
        border: solid darken($navigation__background, 10%);
        border-width: 0 0 1px 1px;
        box-sizing: border-box;
        float: left;
        height: 71px;
        padding-top: 24px;
        text-align: center;
        width: 33.33%;

        &.active {
            background: transparent;
            border-bottom: 0;
        }

        .nav-sections-item-switch {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .nav-sections-item-content {
        @include lib-clearfix();
        box-sizing: border-box;
        float: right;
        margin-left: -100%;
        margin-top: 71px;
        width: 100%;

        &.active {
            display: block;
        }

        &:after {
            content: "";
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../images/svgs/line_short_white.svg);
            width: 26px;
            height: 43px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 25px;
        }

        padding: $indent__m 0;
    }

    @include lib-main-navigation();

    .page-header .switcher {
        span, a {
            font-weight: normal;
        }

        .options ul.dropdown li {
            &:hover {
                background: transparent !important;
            }
        }
    }

}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l_plus) {
    .nav-toggle {
        display: none;
    }
    .nav-sections {
        background: $theme__base--color;
        flex-shrink: 0;
        flex-basis: auto;
        margin-bottom: 0;
        position: relative;
        top: 0;
        float: left;
        width: 100%;
    }

    .nav-sections-item-title {
        display: none;
    }
    .nav-sections-item-content {
        display: block;

    }

    .nav-sections-item-content > * {
        display: none;
    }

    .nav-sections-item-content {
        > .navigation {
            display: block;
            text-align: left; // ThemeStyle
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    @include lib-main-navigation-desktop(
        // ThemeStyle Begin
            $_nav-background-color: white,
            $_nav-font-size: $font-family__base,
            $_nav-level0-item-color: #ffffff,
            $_nav-font-weight: $font-weight__regular,
            $_nav-level0-item-line-height: 35px,
            $_nav-level0-item-color-active: #ffffff,
            $_nav-level0-item__active__border-color: transparent,
            $_nav-level0-item-border-hover: $theme--nav-color,
            $_nav-level0-item-color-hover: #ffffff,
            $_nav-level0-item__active__border-width: 0,
            $_submenu-item-color: $theme--nav-color,
            $_submenu-font-size: $font-size__sm,
            $_submenu-font-weight: $font-weight__regular,
            $_submenu-background-color: $theme__base--color,
            $_submenu-box-shadow: none,
            $_submenu-border-color: $color-white,
            $_submenu-border-width: 0,
            $_submenu-item-color-hover: $theme__base--color,
            $_submenu-item__active__border-color: white,
            $_submenu-item-color-active: #ffffff // ThemeStyle End
    );
    // ThemeStyle Begin
    .navigation {
        padding: 0 30px;
        z-index: inherit;
        border-top: 0 none;
        background: $theme__base--color;

        > ul {
            display: block;
            margin: 0 -20px;
            padding: 0;
            justify-content: space-between;
        }

        li.level0 {
            font-weight: normal;
            //transition: all ease .2s;
            text-align: left;

            &:before, &:after {
                display: block;
                content: '';
                border-top: solid 2px #ffffff;
                transform: scaleX(0);
                transition: transform 250ms linear;
                transform-origin: 0 50%;
            }

            &:hover {
                &:after, &:before {
                    transform: scaleX(1);
                }
            }

            &.deskptop-menu-space {
                width: 100px;
                max-width: 100%;
                flex: 0 1 auto;
                display: none;
            }

            &:nth-child(n + 5) {
                text-align: right;
            }

            &.active {
                &:before, &:after {
                    display: block;
                    content: '';
                    border-top: solid 2px #ffffff;
                    transform: scaleX(1);
                    transition: transform 250ms linear;
                    transform-origin: 0 50%;
                }

                > a {
                    &:before, &:after {
                        color: #ffffff;
                    }
                }
            }

            &:nth-child(2),
            &:last-child {
                & > .level-top {

                }
            }

            & > .level-top {
                position: relative;
                font-weight: normal;
                font-size: 13px;
                color: #ffffff;
                padding-bottom: 0;
                //padding-left: 20px;
                //padding-right: 20px;
                margin: 0;
                text-transform: uppercase;
                display: inline-block;
                white-space: nowrap;
                line-height: 30px !important;
                //&.ui-state-focus,
                &.ui-state-active {

                }

                &:before {
                    //left: 0;
                }

                &:after {
                    //right: 0;
                }

                &:before, &:after {
                }

            }

            .submenu {
                font-weight: 400;
                font-size: $font-size__sm;
                text-align: left;
                z-index: 11;
                background: $theme__base--color;

                &.level0 {

                }

                > li {
                    @include max-screen($screen__m) {
                        margin: 1px 0;
                        padding: 1px 0;
                    }
                    position: relative;

                    &.parent {
                        &:after {
                            content: "";
                            background-repeat: no-repeat;
                            background-position: center;
                            background-image: url("../images/svgs/chevron_right_grey.svg");
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            right: 25px;
                            top: 8px;
                        }
                    }
                }

                a {
                    color: #ffffff;
                    text-transform: none;
                    font-weight: 400;
                    @include max-screen($screen__m) {
                        padding: 3px 20px;
                    }
                    padding: 5px 20px;

                    &:hover {
                        background: #F2FFF8;
                        //border-bottom: 1px solid #ffffff;
                    }
                }

                .submenu {
                    border-left: 3px solid #F2FFF8;
                }

                padding-bottom: 7px;
                padding-top: 0;
            }
        }
    }
    // ThemeStyle END
    .panel.header {
        .links,
        .switcher {
            display: inline-block;
        }
    }
}

@include max-screen($screen__l2) {
    .nav-sections-item-content > .navigation {
        text-align: left;
    }
}

.deskptop-menu-space {
    border: 0 none;
    @extend .abs-visually-hidden;
}

@include max-screen($screen__l2) {
    .navigation {
        > ul {
            margin: 0 -15px;
        }

        li.level0 {
            margin: 0;

            > .level-top {
                margin-left: 18px;
                margin-right: 18px;
                font-size: $font-size__sm;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.cms-home-new .navigation li.level0.active {
    border-top: none;
    border-bottom: none;
}
