//   if your changes deeper copy
//  delete this file and make the changes on the Original File


// Line 5
.rating-summary {
	.rating-label {
		display: inline-block;
		padding-bottom: 5px;
		padding-right: 25px;
		/* padding-top: 1px; */
		vertical-align: bottom;
	}
	.rating-result {
		display: inline-block;
		position: relative;
		vertical-align: text-bottom;
	}
}
// lIne 18
.review-control-vote {
	@include lib-rating-vote(
		$_icon-font-size     : 50px
	);
	&:before {
		line-height: 32px;
		height: 32px;
	}
	label {
		&:before {
			line-height: 32px;
			height: 32px;
		}
	}
}


// Line 89 remove Table style
.product-reviews-summary {
	display: block;
	margin: 0 0 10px;
	bottom: 170px;
	float: left;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	margin-bottom: 0;
	.products.wrapper.list & {
		margin: 0 auto;
	}

	&.empty {
		margin-left: 0;
		position: static;
		float: left;
	}
	.rating-summary {
		display: inline-block;
		left: auto;
		margin-right: 20px;
	}

	.reviews-actions {
		display: inline-block;
		line-height: 1.42857143;
		a {
			margin-right: 0;
			&.view {

				.product-info-main & {
					margin-right: 20px;
				}
			}
		}
	}
}

.product-info-price {
	width: 100%;
}

// 181

.review-field-ratings {
	.nested {
		display: block;
	}

	.review-field-rating {

		.label,
		.control {
			display: block;
			padding-bottom: 0;
			vertical-align: top;
		}

		.label {
			padding-right: $indent__s;
			padding-top: 0;
			font-weight: 400;
		}

		.control {
			padding-top: 0;
		}
	}
}


// line 211
.review {
	&-title {
		font-weight: 600;
	}
}

.review-legend {
	> strong {
		display: inline-block;
		margin-left: 10px;
	}
}

// custom
.review-form-actions {
	.actions-primary {
		display: block;
		float: none;
	}
}
#customer-reviews {
	.block-title {
		strong {
			font-size: 24px;
		}
	}
}
