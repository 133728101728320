img.lazyload {
  opacity: 0;
}
img.lazyloading {
  opacity: 1;
  transition: 600ms opacity;
  background: transparent url(../images/loader.gif) no-repeat center;
  min-height: 60px;

}

//.ratio-container {
//  position: relative;
//}
//.ratio-container:after {
//  content:'';
//  display: block;
//  height: 0;
//  width: 1px;
//  /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
//  padding-bottom: 41.9%;
//}



img.lazyloaded {
  opacity: 1;
  transition: .15s opacity;
}
// Category Image
.category-view {

  .category-image {
    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      transform: translateY(-50%);
      + :after {
        display: none;
      }
    }
    &.ratio-container {
      position: relative;
      overflow: hidden;
      &:after {
        content:'';
        display: block;
        height: 0;
        width: 100%;
        /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
        padding-bottom: 29.2%;
        transition: height .2s ease;
      }
    }
  }
}