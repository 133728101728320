.d-flex {
    display: flex;
    @extend .justify-content-md-center;
}

.footer.content {
    position: relative;
}

.page-footer {
    float: left;
    width: 100%;
    font-size: $font-size__sm;
    background: $theme--footer-background-grey;

    a {
        color: $color-dark-gray;
    }

    p.h5 {
        color: $color-dark-gray;
        font-weight: 600;
        margin-bottom: 25px;
        text-transform: uppercase;
    }
}

.footer-top {
    background: $theme--footer-background-dark-grey;
    width: 100%;
    text-align: center;

    a {
        color: $color-white;

        &:hover {
            color: $theme--accent-color--hover;
            text-decoration: none;
        }
    }

    .block.newsletter {
        .field {
            float: left;
            width: 80%;
            margin-top: 30px;
            max-width: 442px;
            margin-right: 10px;
            input {
                min-height: 42px;
                border-color: $theme--border--dark-color;
                font-size: 14px;
                &::placeholder {
                    color: $theme--grey-dark;
                }
            }

            .control {
                &:before {
                    line-height: 42px;
                }
            }
        }

        .actions {
            display: block;
            width: 20%;
            float: left;

            .action.subscribe {
                display: block;
                width: 100%;
                margin-left: 0;
                max-height: 41px;
                margin-top: 30px;
                padding: 12px 5px;
                font-size: 12px;
                color: $theme--grey-dark;
                @include max-screen($screen__s) {
                    max-width: 270px;
                    margin: 0 auto;
                }
            }
        }
    }

    .footer-social {
        margin-top: 30px;
        a {
            display: inline-block;
            vertical-align: top;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            float: left;
            margin: 5px;
            -moz-transition: all 500ms ease;
            -webkit-transition: all 500ms ease;
            -o-transition: all 500ms ease;
            transition: all 500ms ease;
            -moz-backface-visibility: hidden;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            border: 1px solid #666;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            color: #666;
            background-color: transparent;
            border-color: #666;
            span {
                span {
                    &:before {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.footer-top--middle {
    float: left;
    width: 100%;
    margin: 30px 0;

    li {
        padding: 10px 0;
        border-bottom: 1px solid $color-dark-gray;
        font-size: 13px;
        color: $color-dark-gray;

        div {
            margin: 0 !important;
        }
    }

    li.last {
        border-bottom: 0;
    }
}

@include min-screen($screen__m) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1170px;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }
    .row {
        width: 100%;
    }
}

.footer-bottom {
    width: 100%;
    float: left;
    background: $theme--footer-background-dark-grey;
    color: $color-dark-gray;
    padding: 15px 0;
    border-top: 1px solid $color-dark-gray;
    &--inner {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    a {
        color: $color-dark-gray;

        &:hover {
            color: $theme--accent-color--hover;
            text-decoration: none;
            text-decoration: none;
        }
    }

    p {
        color: $color-dark-gray;
    }

    @include max-screen($screen__m) {
        .d-flex {
            flex-wrap: wrap;
        }
    }

    .footer-bottom__copyright {
        float: left;
        width: 50%;
        text-align: left;
    }

    .footer-bottom__payments {
        float: left;
        width: 50%;
        text-align: right;
    }
}

.footer {
    &-column {
        margin-bottom: 20px;
    }

    .footer-column__heading {
        font-family: $font-family-name__heading;
        font-weight: $font-weight__heavier;
        text-align: center;
        text-transform: uppercase;
        margin: 6px auto 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $theme--grey-light;
        font-size: $font-size__s + 1;

        span {
            display: inline-block;
            position: relative;
            padding-left: 20px;
            padding-right: 20px;

            &:after,
            &:before {
                content: '•';
                position: absolute;
                font-size: 40px;
                display: inline-block;
                top: calc(50% - 3px);
                transform: translateY(-50%);
            }

            &:after {
                left: 0;
            }

            &:before {
                right: 0;
            }
        }
    }

    &__shipping {
        margin-top: 25px;

        p {
            margin-bottom: 8px;
        }
    }
}
@include max-screen($screen__l) {
    .page-footer {
        .footer-top {
            .block.newsletter {
                .field {
                    max-width: 270px;
                    input {
                        max-width: 280px;
                    }
                }
                .actions {
                    width: 22%;
                    .action {

                    }
                }
            }
            .footer-social {
            }
        }
    }
}
@include max-screen($screen__m) {
    .page-footer {
        padding: 5px 5px;
        .footer-top {
            background: none;
            .block.newsletter {
                .field {
                    float: left;
                    width: 100%;
                    input {
                        max-width: 260px;
                    }
                }
                .actions {
                    width: 100%;
                    .action {
                        margin-top: 5px;
                    }
                }
            }
            .footer-social {
                margin-top: -30px;
            }
        }
    }
}
@include max-screen($screen__s) {
    .page-footer {
        .footer-top {
            .block.newsletter {
                .field {
                    max-width: 100%;
                    input {
                        max-width: 100%;
                    }
                }
                .actions {
                    .action {
                    }
                }
            }
        }
    }
}


