@media only screen and (max-width:767px) {
	.um-gal-template-grid {
		.um-gal-image-wrapper {
			width: 100%;
		}
	}
	.um-gallery-layout-no-filter {
		.um-gallery-item {
			width: 100%;
		}
	}
	.um-gal-no-move-gal-caption {
		.um-gi-nf-caption-block {
			position: absolute;
		}
	}
}
@media only screen and (min-width: 100px) and (max-width:585px) {
	.um-controls-wrap {
		display: block;
		padding: 0px 20px;
	}
	.um-shuffle-block {
		width: 100% !important;
		float: left;
	}
	.um-search-block {
		width: 100% !important;
		float: left;
	}
	.um-sorting-block {
		width: 100% !important;
		float: left;
		padding-top: 0px;
	}
	.um-fltr-sorting {
		width: 100% !important;
		float: left;
	}
	.um-sortandshuffle {
		width: 100% !important;
		text-align: center !important;
		padding: 4px 4px !important;
	}
}
@media only screen and (min-width: 100px) and (max-width:767px) {
	.um-gi-caption-block {
		h5 {
			display: none;
		}
		h4 {
			font-weight: 400;
			font-size: 13px;
			line-height: 15px;
		}
	}
	#um-filtr-item {
		width: 50%;
	}
	.um-search-block {
		width: auto;
		padding: 10px;
		input {
			text-align: center;
		}
	}
	.um-sorting-block {
		width: auto;
		padding-top: 0px;
		text-align: center;
	}
	.um-shuffle-block {
		padding: 5px 10px;
		width: auto;
		text-align: center;
	}
	.um-gallery-items {
		li {
			margin-bottom: 10px;
		}
	}
	.um-gallery-filters {
		ul {
			margin: 0px;
			padding: 0px;
		}
	}
	.um-gallery-controls {
		float: left;
		width: auto;
		padding-bottom: 0px;
		padding-top: 0px;
	}
	.um-sortandshuffle {
		li {
			margin-right: 0px;
			padding: 5px 5px;
		}
		width: auto;
		text-align: right;
		padding: 0px 4px;
	}
	.um-fltr-sorting {
		width: auto;
	}
}
@media only screen and (min-width: 767px) and (max-width:1050px) {
	.um-gi-caption-block {
		h5 {
			display: none;
		}
	}
	.um-sortandshuffle {
		padding: 0px 2px;
		margin: 0px;
		width: auto;
	}
	.um-search-block {
		padding: 0px 15px;
	}
	.um-fltr-sorting {
		width: auto;
	}
}
