$layout-column-main__sidebar-offset: 2%;
$layout-column__additional-sidebar-offset: $layout-column-main__sidebar-offset;

//
//  Common
//  _____________________________________________

.columns {

    .column.main {

        > div:last-child:not(.slick-slider--outer) {
            margin-bottom: 30px;
        }
        .customer-account-create &,
        .customer-account-forgotpassword &,
        .account & {
            padding-bottom: 40px;
        }
        font-size: $font-size__sm;
    }
    .sidebar-main {

    }

    .sidebar-additional {
    }
}

//
// CUSTOM THEME STYLE
//
.account {
    .columns {
        .column.main {
            @include max-screen($screen__m) {
                order: 2
            }
        }
    }
}

.catalog-category-view.page-products {
    .columns {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        width: 100%;
        padding-top: 31px;

        @include max-screen($screen__m) {
            .products {
                margin-top: 0;
            }
            #authenticationPopup + .toolbar-products {
                margin-bottom: 0;
                padding-top: 30px;
            }
            .sorter {
                top: 19px;
            }
            .filter.block:not(.active) .filter-title strong {
                top: 20px;
            }
        }

    }
    @include min-screen($screen__m) {
        &.page-layout-2columns-left {
            .columns {
                padding-left: 0;
                padding-right: 0;
            }
            .column.main {
                padding-left: 15px;
                padding-right: 0;
            }
            .sidebar-main {
                padding-left: 0;
                padding-right: 15px;
            }
        }
    }
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .theme-container,
    .footer-top--inner,
    .footer-bottom--inner {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
    }

    .page-main {
        padding-bottom: 30px;
        .account &,
        .cms-privacy-policy & {
            padding-top: 41px;
            position: relative;
        }
    }
    .page-main .columns {
        padding-left: $layout__width-xs-indent;
        padding-right: $layout__width-xs-indent;
        .cms-index-index & {
            padding-left: 0;
            padding-right: 0;
        }
    }
    body:not(.catalog-product-view) .page-main {
        padding-bottom: 30px;
    }
    .catalog-product-view {
        .page-bottom {
            padding-bottom: 30px;
        }
    }
    .columns .column.main {
        padding-bottom: 0;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    %primary-trail--reset,
    .page-wrapper > .page-bottom {
        max-width: inherit;
        padding-left: 0;
        padding-right: 0;
    }

    %primary-trail--desktop,
    .theme-container,
    .footer-top--inner,
    .footer-bottom--inner,
    .block.category.event {
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
        width: 100%;
    }

	// reset base css {
	.page-main,
	.footer.content {
		max-width: inherit;
		padding-left: 0;
		padding-right: 0;
	}

    .page-main {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: auto;
        overflow-x: initial;
        .ie9 & {
            width: auto;
        }
    }
    body:not(.catalog-product-view) .page-main {
        padding-bottom: 0;
        + .page-bottom {
            .js-multicol--outer {
                padding-top: 0;
                &:last-child > div {
                    padding-top: 0;
                }
            }
        }
    }
    .columns {
        display: block;
    }

    .column.main {
        .cms-index-index & {
            padding-top: 0px;
        }
        .column.main {
            padding-bottom: 15px;
        }
        .slick-slider--outer {
            margin-bottom: 50px;
        }
    }

    .page-main .columns {
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        max-width: $layout__max-width;
        padding-left: $layout-indent__width;
        padding-right: $layout-indent__width;
        width: 100%;
        .cms-index-index & {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
        }
    }
}
