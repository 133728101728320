//  //   if your changes deeper copy
//  Magento_CatalogSearch/styles/module/_common.scss


.header {
    position: relative;

    .block-search {

        .control {

        }

        #search {
            border: 3px solid $border-grey-color;
            min-height: 35px;

            @include max-screen($screen__m) {
                min-height: 53px;
            }
        }
        #search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $text__color__grey;
            opacity: 1; /* Firefox */
        }

        #search:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $text__color__grey;
        }

        #search::-ms-input-placeholder { /* Microsoft Edge */
            color: $text__color__grey;
        }
        .action.search:before {

        }
    }
}

.header-search {
    position: relative;
    float: left;
    width: 50%;
    margin-left: 140px;
    &--trigger {
        z-index: 4;
        @include lib-icon-font(
                        $icon-search-2,
                $_icon-font: $icons-customer__font-name,
                $_icon-font-size: 23px
        );

        &:hover {
            color: $link__hover__color;
            text-decoration: none;
        }

        &:before {
            width: 24px;
            color: $theme__base--color;
        }
    }

    &__text {
        @extend .abs-visually-hidden;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .header-search {
        position: absolute;
        margin-top: -10px;
        float: left;
        &__text {
            display: block;
        }

        &--trigger {
            width: 30px;
            height: 30px;
            position: absolute;
            right: 40px;
            top: 12px;
            z-index: 12;
        }

        .block-search {
            display: none;
            top: calc(-100% - 100px);
            right: auto;
            left: -10px;
            width: calc(100% - 70px);
            z-index: 20;
            margin-top: 0;

            .label {
                display: none;
            }

            .control {
                border: 0 none;
            }

            #search {
                background: #ffffff;
                height: 40px;
                position: fixed !important;
                left: 0px;
                top: 48px;
                width: 100vw;
                margin-top: 25px !important;
                right: 0;
                min-width: 250px;
                font-size: 14px;
            }
        }
    }
    .show-search {
        .block-search {
            display: block;
            position: absolute;
            z-index: 9999;
            width: 100%;
            float: left;
            left: -75px;
            top: 15px;
        }
    }
}

@include max-screen(479px) {
    body{
        .header-search {
            .block-search {
                #search {
                    position: absolute !important;
                    top: -7px;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    // CUSTOM Trigger Search
    .header-search {
        &--trigger {
            transition: top 0.25s ease-in-out;
            position: relative;
            padding: 0 18px 0 0;
            display: block;
            top: 0;

            &:hover {
            }
        }

        &__svg {
            left: 0;
            top: 2px;
        }

        &__text {
            display: inline-block;
            font-size: $font-size__base;
            text-transform: uppercase;
            color: $text__color;
            line-height: 22px;
            font-weight: $font-weight__regular;

            &:hover {
                color: $link__hover__color;
            }
        }
    }
    #nav-search-trigger {
        display: none;
    }
    .show-search {
        .header-search {
            &--trigger {

            }
        }

        .block-search {
            top: 0;
        }
    }
    .block-search {
        width: 100%;
        float: left;
        margin-top: 15px;
        max-width: 475px;
    }
}
//
//  Tablet
//  _____________________________________________
@media (min-width: $screen__m) and (max-width: $screen__m_plus) {
    .header-search {
        margin-left: 60px;
        .block-search {
            max-width: 325px;
        }
    }
}
