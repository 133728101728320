//
//  Variables
//  ---------------------------------------------

$product-name-link__color                   : $text__color !default;
$product-name-link__color__active           : $text__color !default;
$product-name-link__color__hover            : $text__color !default;
$product-name-link__color__visited          : $text__color !default;

$product-name-link__text-decoration         : none !default;
$product-name-link__text-decoration__active : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover  : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited: $link__hover__text-decoration !default;


//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l 0;
}

.product {
    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
            width: 50%;
            margin-bottom: 20px;
            padding: 0 15px 30px;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: $indent__xs 0;
            word-wrap: break-word;
            hyphens: auto;
        }

        &-info {
            max-width: 100%;
            width: 152px;

            .page-products & {
                width: 240px;
            }
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;
                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
            }
        }

        .price-box {
            margin: $indent__s 0 $indent__m;

            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }
}

.column.main {
    .product {
        &-items {
            margin: 0;
            .page-layout-2columns-left & {
                margin: 0 -15px;
            }
        }

        &-item {
            padding: 0 15px;
        }
    }

}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax {
        display: none;
    }

    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-items {
             padding: 15px 15px 0;
           }
        &-image-container {
            max-width: 100%;
            width: 240px;
             margin-bottom: 0;
        }
        &-image-photo {
            bottom: 0;
            top: 0;
            transform: translate(0,0);
        }
        &-item {
            display: table;
            width: 100%;
            position: relative;
            background: white;
            margin-bottom: 20px;
            .column.main & {
                padding-top: 15px;
            }
            &-info {
                display: table-row;
            }
            &-photo {
                display: table-cell;
                padding: 0 $indent__l 15px 0;
                vertical-align: top;
                position: relative;
                @include min-screen($screen__s) {
                    width: 1%;
                }
            }
            &-details {
                display: table-cell;
                vertical-align: top;
            }
            &-actions {
                .actions-secondary {
                    width: 30px;
                    left: 18px;
                    z-index: 1;
                    top: 19px;
                    right: inherit;
                }
            }
            &-inner {
                padding-top: 15px;
            }
        }
        &-image-container {
            max-width: 100%;
            width: 240px;
        }
        &-image-photo {
            bottom: 0;
            display: block;
            height: auto;
            left: 0;
            margin: auto;
            max-width: 100%;
            right: 0;
            top: 0;
            transform: translate(0,0);
        }
    }

    .product-image-wrapper {
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
    .products-grid {
        .product-item {
            width: 100%;
        }
        .product-item-name {
            @include max-screen($screen__m) {
                margin-top: 30px;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-secondary {
                    display: block;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: auto;
                    position: absolute;
                    top: 18px;
                    right: 18px;
                    z-index: 1;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {

                }
            }
        }
    }

    .products-grid .product-item {
        width: 33.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}
@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 33.333333333%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
            }

            .product-item {
                margin-left: 0;
                width: 33.333333333%;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 25%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 25%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
