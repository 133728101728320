.breadcrumbs {
    min-height: 18px;
    @include lib-breadcrumbs(
        $_breadcrumbs-container-margin: 10px auto 17px,
        $_breadcrumbs-font-size: $font-size__s + 1,
        $_breadcrumbs-link-text-decoration: underline,
        $_breadcrumbs-icon-font-content: '•',
        $_icon-font-line-height: 16px,
        $_icon-font: $font-family-name__base,
        $_icon-font-size: $font-size__sm + 8
    );
    padding-left: 10px;
    padding-right: 10px;
    a {
        color: $text__color__grey;
        text-transform: uppercase;
        &:visited {
            color: $text__color__grey;
        }
    }
    strong {
        color: $text__color__grey;
        text-transform: uppercase;
    }
    .items .item {

        &:not(:last-child) {
            position: relative;
            padding-right: 17px;
            margin-right: 7px;
        }
        &:after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0;
        }
    }
}
.catalog-product-view {
    .breadcrumbs {
        @include min-screen($screen__m) {
            margin-bottom: 50px;
        }

    }
}