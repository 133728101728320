.footer-newsletter {
  input[type="text"], input[type="password"], input[type="url"], input[type="tel"], input[type="search"], input[type="number"], input[type="datetime"], input[type="email"] {
    border: 0 none;
    color: $color-white;
    padding: 14px 8px 14px 17px;
    background: transparent;
    border: 1px solid $color-white;
    height: auto;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder { /* Chrome/Opera/Safari */
      color: $theme--grey-light;
      font-size: $font-size__sm;
    }
  }
  .block.newsletter .field .control {
    &:before {
      content: none;
    }
  }
  input.mage-error, select.mage-error, textarea.mage-error {
    color: $color-white;
    border: 1px solid $theme__secondary--accent-color;
  }
  &__text {
    text-align: center;
    margin-bottom: 24px;
  }
}