.actions-toolbar {
    a.action:not(.primary) {
        &:hover {
            color: $theme--secondary--accent-color--hover;
        }
    }
    > .primary,
    > .secondary {
        .action {
            float: none;
            text-align: center;
            border-radius: 0;
            margin-bottom: $indent__s;
            &:last-child {
            }
            @include max-screen($screen__m) {
                width: calc(50% - 10px);
                /* display: inline-block; */
                margin-left: 10px;
            }
            @include max-screen($screen__s) {
                width: 100%;
                margin-left: 0;
            }

        }
        margin-bottom: $indent__s;
        &:last-child {
            margin-bottom: 0;
        }
    }
    > .secondary {
        .action {

        }
    }
}
@include max-screen($screen__m) {
    .actions-toolbar {
        > .primary {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

//
//  Desktop
//  _____________________________________________
@include min-screen($screen__m) {
    .actions-toolbar {
        @include lib-actions-toolbar(
            $_actions-toolbar-secondary-actions-links-margin-top                             : 10px
        );
        > .secondary,
        > .primary {
            float: none;
            display: block;
            margin-bottom: 0;
            .action {
                margin-bottom: 10px;
                font-size: $font-size__s + 1;
            }

        }
    }
    form {
        .actions-toolbar {
            .column:not(.sidebar-additional) &,
            .column:not(.sidebar-main) & {
                margin-left: 35%;
            }
        }
    }
}
