//   if your changes deeper copy
// copy Magento_Checkout/styles/module/mincart.scss
.minicart-wrapper .action.close {
    color: $theme__base--color;
}

.minicart-wrapper .action.showcart .counter.qty {
    height: 18px;
    line-height: 20px;
    background: #fc7070;
    color: #fff;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    font-size: 11px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    min-width: 18px;
    overflow: hidden;
    padding: 0 3px;
    text-align: center;
    white-space: normal;
    position: absolute;
    top: -3px;
    left: 27px;
    @include max-screen($screen__m) {
        left: 20px;
    }
    border: 0px solid #666;

}

.minicart-items-wrapper {
    padding-bottom: 0;
    border-color: transparent;
    overflow-x: hidden;

    + .actions {
        border-top: 1px solid $theme--border--base-color;
        padding-top: 20px;
    }
}

.minicart-wrapper .block-minicart {
    padding: 0;
    box-shadow: none !important;
    border: 1px solid $theme--border--base-color !important;

    &:before, &:after {
        display: none !important;
    }

    #minicart-content-wrapper {
        .block-content {
            padding: 40px 20px 20px 20px;
            font-size: $font-size__s + 1;

            > .actions > .primary {
                margin: 0 0 15px;

                .action.primary {
                    @include orange-btn;
                    font-size: $font-size__sm;
                    font-weight: $font-weight__semibold;

                }
            }

            .actions .secondary {
                position: relative;
                text-align: right;

            }

            a.action.primary.viewcart {
                &:hover {
                    background: $color-white !important;
                    border-color: $theme--special-color;
                    color: $theme--special-color !important;
                }
            }
        }
    }

    .items-total {
        margin-left: 0;
    }

    .subtotal {
        margin-right: 0;
    }
}

.checkout-index-index .minicart-items {
    .product-item-details {
        margin-top: 10px;
        margin-bottom: 15px;
    }
}

.minicart-items {
    .product-item-details {

        @include min-screen($screen__m) {
            padding-left: 98px;
        }

        .details-qty {
            display: flex;
            align-items: center;
        }
    }

    .product-image-wrapper {
        margin-top: 10px;
        padding-bottom: 100% !important;

        .product-image-photo {
            position: absolute;
        }
    }

    .product-item-name {
        margin-bottom: 0;
    }

    .product-item-pricing {
        .label {
            font-weight: $font-weight__semibold;
            width: auto;
            padding-right: 10px;
        }
    }

    dl.product.options.list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 2px;

        dt {
            margin-bottom: 0;
            margin-right: 2px;

            &:after {
                content: ': ';
                display: inline-block;
            }
        }

        dd {
            margin-right: 10px;
        }
    }

    .product .actions {
        margin: 0 0 -34px;
        top: -34px;
        position: relative;
    }

    .product-item:not(:first-child), .item-qty {
        border-color: $theme--border--base-color;
    }

    .item-qty {
        height: 45px;
    }
}

@include max-screen($screen__m) {
    .minicart-wrapper {
        margin-top: 16px;
        z-index: 10;
    }
}

@include min-screen($screen__m) {

    .minicart-wrapper {
        float: none;
        display: inline-block;
        @include lib-dropdown(
                $_toggle-selector: '.action.showcart',
                $_options-selector: '.block-minicart',
                $_icon-font: $icons-customer__font-name,
                $_dropdown-list-shadow: none,
                $_dropdown-list-item-border: 1px solid $theme__border--base-color,
                $_dropdown-toggle-icon-content: $icon-bag,
                $_dropdown-toggle-active-icon-content: $icon-bag,
                $_dropdown-list-item-padding: false,
                $_dropdown-list-item-hover: false,
                $_icon-font-position: before,
                $_icon-font-size: 25px,
                $_icon-font-line-height: 25px,
                $_icon-font-color: $theme__secondary--accent-color,
                $_icon-font-color-hover: $minicart-icons-color-hover,
                $_icon-font-color-active: $theme__secondary--accent-color--hover
        );

        .action.showcart {
            color: $text__color;
            font-size: $font-size__base;
            font-weight: $font-weight__regular;
            text-transform: uppercase;

            &:hover {
                color: $link__hover__color;
            }

            span.text {

            }
        }

        .action {
            &.close {
                @include lib-button-icon(
                                $icon-remove,
                        $_icon-font-size: 32px,
                        $_icon-font-line-height: 32px,
                        $_icon-font-text-hide: true,
                        $_icon-font-color: $text__color,
                        $_icon-font-color-hover: $link__hover__color,
                        $_icon-font-color-active: $text__color
                );
            }
        }

        .block-minicart li {

        }
    }
}
