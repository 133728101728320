
.header--wrap {
    position: fixed;
    padding-top: 25px;
    width: 100%;
    background: $color-white;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.2s linear;

    @include max-screen($screen__m) {
        z-index: 1001;
    }

    .filter-active & {
        z-index: -1;
        opacity: 0;
    }
}

@include min-screen($screen__m) {
    .header--wrap {

        + #maincontent {
            padding-top: 20px;
            margin-top: 177px !important;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-width: 1170px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
        }

        + div:empty + #maincontent {
            padding-top: 20px;
            margin-top: 177px !important;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-width: 1170px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
        }

        .logo {
            transition: top 0.2s ease;

            img {
                transform: scale(1) translate3d(0, 0, 0);
                transition: transform 0.2s ease;
                transform-origin: 0 0 0;

            }
        }

        .nav-sections {
            transition: all 0.5s linear;
        }

        &.hide-nav {
            .logo {

                img {
                    transform: scale(0.8);

                }
            }

            .nav-sections {
                margin-top: 0;

                .level0 > .level-top {
                    line-height: 55px;
                }
            }

            .header.panel {
                padding-top: 5px;
                padding-bottom: 5px;
            }
        }
    }
}

@media (min-width: $screen__m) and (max-width: $screen__l_plus) {
    .header--wrap {

        + #maincontent {
            margin-top: 123px !important;
        }

        + div:empty + #maincontent {
            margin-top: 123px !important;
        }
    }
}

@include max-screen($screen__m) {
    .header--wrap {
        transition: all 0.3s linear;
        top: 0;

        + #maincontent {
            padding-top: 18px;
            margin-top: 59px;
        }

        + div:empty + #maincontent {
            padding-top: 18px;
        }
    }
}
