[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: $icons-customer__font-name;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-bag:before {
    content: $icon-bag;
}

.icon-emblem:before {
    content: $icon-emblem;
}

.icon-facebook:before {
    content: $icon-facebook;
}

.icon-instagram:before {
    content: $icon-instagram;
}

.icon-lagernd:before {
    content: $icon-lagernd;
}

.icon-lieferzeit:before {
    content: $icon-lieferzeit;
}

.icon-nicht-lagernd:before {
    content: $icon-nicht-lagernd;
}

.icon-search:before {
    content: $icon-search;
}

.icon-twitter:before {
    content: $icon-twitter;
}

.icon-up:before {
    content: $icon-up;
}

.icon-user:before {
    content: $icon-user;
}

.icon-user-1:before {
    content: $icon-user-1;
}

.icon-wishlist_full:before {
    content: $icon-wishlist_full;
}

.icon-wishlist:before {
    content: $icon-wishlist;
}

.icon-twitter-2:before {
    content: $icon-twitter-2;
}

.icon-facebook-2:before {
    content: $icon-facebook-2;
}

.icon-gplus:before {
    content: $icon-gplus;
}

.icon-youtube:before {
    content: $icon-youtube;
}

.icon-instagram-2:before {
    content: $icon-instagram-2;
}

.icon-pinterest:before {
    content: $icon-pinterest;
}

.icon-vimeo:before {
    content: $icon-vimeo;
}

.icon-location:before {
    content: $icon-location;
}

.icon-phone:before {
    content: $icon-phone;
}

.icon-mail:before {
    content: $icon-mail;
}

.icon-clock:before {
    content: $icon-clock;
}
