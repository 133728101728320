/*
* prevent collapsing while loading
 */
* {
  min-height: 0;
  min-width: 0;
}
.slick-slider--outer {
  margin-top: 50px;
  padding-left: 0;
  padding-right: 0;
  + .page-main {
    padding-top: 10px;
    @include min-screen($screen__m) {
      padding-top: 30px;
    }
  }
  @include min-screen($screen__m) {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.page-header + .slick-slider--outer {
  margin-top: 59px;
  padding-left: 0;
  padding-right: 0;
}

// default
.slick-slider--default {
  &:not(.slick-initialized) {
    .banner__item {
      &:not(:first-child) {
        display: none;
        visibility: hidden;
      }
    }
  }
}
.js-home-banners {
  &:hover {
    .slick-next,
    .slick-prev {
      display: block !important;
    }
  }
}
.slick-initialized {
  .slick-slide.banner--background {
    display: flex;
    visibility: visible;

  }
}
/* Arrows */
.slick-next,
.slick-prev {
  height: 35px;
  width: 15px;
  z-index: 2;
  display: none !important;
  &:focus, &:active, &:hover {
    outline: 0 none;
    border: 0 none;
  }
  svg {
    fill: $color-white;
  }
  @include min-screen($screen__l) {
    height: 45px;
    width: 25px;
    svg {
      fill: $color-white;
    }
  }
  &:before {
    content: none;
  }
}
.slick-prev {
  left: 20px;
}

.slick-next {
  right: 20px;
}

.slick-dotted.slick-slider {
  margin-bottom: 30;
}


@include min-screen($screen__m) {
  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }

}
@include min-screen($screen__l) {
  .slick-prev {
    left: 30px;
  }

  .slick-next {
    right: 30px;
  }
}

/* Dots */
.slick-dots {
  bottom: -32px;
  li {
    height: auto;
    width: auto;
    margin: 0 6px;
    background: transparent;
    border-radius: 0;
    text-indent: -9999em;
    border: 0 none;
    button {
      border:  8px solid #FFF;
      border-left-width: 0;
      border-right-width: 0;
      background: $theme__base--color;
      height: 18px;
      width: 28px;
      outline: 0 none;
      padding: 0;
      border-radius: 0px;
      &:before {
        content: none;
      }
    }
  }
  .slick-active {
    button {
      border-top-width: 6px;
      border-bottom-width: 6px;
    }
  }
}

/* Default Content */
.banner__content {
  &--absolute {
    position: absolute;
    top:50%;
    transform: translate(0, -50%);
    box-sizing: border-box;
    margin: 0 auto;
    z-index: 1;
    text-align: left;
    padding: 15px 30px 20px;
    font-family: $font-family__base;
    background: transparent;

    @include min-screen($screen__m) {
      padding: 35px 60px 30px;
      max-width: 90%;
    }
    @include min-screen($screen__l2) {
      max-width: 80%;
    }
  }
  &--relative {
    box-sizing: border-box;
    position: relative;
    margin: 0 auto;
    z-index: 1;
    text-align: center;
    width: 100%;
    padding: 15px 30px 20px;
    font-family: $font-family__base;
    background: transparent;

    @include min-screen($screen__m) {
      padding: 35px 30px 30px;
      max-width: 960px;
    }
    @include min-screen($screen__l) {
      > * {
        max-width: 650px;
        margin-right: auto;
        margin-left: auto;
      }
      max-width: 1000px;
    }
    @include min-screen($screen__l2) {
      max-width: 1200px;
    }
  }
}


.banner {
  &__item {
    position: relative;
  }
  &__image {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  &--background {
    box-sizing: border-box;
    background-position: center;
    background-size: cover;
    width: 100%;
    padding: 10px 20px 10px;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: left; /* align horizontal */
    align-items: center; /* align vertical */
    &.ratio-container {
      position: relative;
    }
    &.ratio-container:after {
      content:'';
      display: block;
      height: 0;
      width: 1px;
      padding-bottom: calc(41.9% - 20px);
    }
  }
  &__header {
    font-family: $heading__font-family__base;
    color: $color-white;
    font-weight: $font-weight__heavier;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 1.35;
    text-transform: none;
    text-shadow: -4px 1px 13px rgba(0,0,0,.44);
    strong {
      font-weight: 500;
      font-family: $heading__font-family__base;

    }
  }
  &__description {
    color: $color-white;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
    &:empty {
      display: none;
      + .banner__button {
        margin-top: 5px;
      }
    }
  }
  &__button {
    display: inline-block;
    color: $theme__base--color;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid $theme__base--color;
    font-weight: $font-weight__heavier;
    font-size: 10px;
    padding: 15px 15px;
    max-width: 100%;
    background: rgba(255, 255, 255, 0.80);
    text-transform: uppercase;
    &:hover, &:focus {
      border-color: $link__hover__color;
      background: $link__hover__color;
      text-decoration: none;
      color: $color-white;
    }
  }
  &__bubble {
  }
}

@include min-screen($screen__xs2) {
  .banner {
    &__header {
      font-size: 20px;
    }
    &__description {
      font-size: 17px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    &__button {
      font-size: 11px;
    }
  }
}
@include min-screen($screen__xs) {
  .banner {
    &--background {
      // min-height: 200px;
      &.ratio-container:after {
        padding-bottom: calc(41.9% - 60px);
      }
    }
  }
}
@include min-screen($screen__m) {
  .banner {
    &--background {
      padding: 25px 20px 35px;
    }
    &__header {
      font-size: 30px;
    }
    &__description {
      font-size: 20px;
    }
    &__item {
      position: relative;
    }
    &__button {
      font-size: 13px;
    }
    &__bubble {
      position: absolute;
      right: 10px;
      top: 20px;
    }
  }
}

@include min-screen($screen__l) {
  .banner {
    &--background {
      padding: 45px 55px;
      //  min-height: 385px;
      &.ratio-container:after {
        padding-bottom: calc(41.9% - 90px);
      }
    }
    &__header {
      font-size: 50px;
    }
    &__description {
      font-size: 24px;
      margin-bottom: 30px;
    }
    &__button {
      padding: 17px 35px;
      font-size: 15px;
    }
  }
}

@include min-screen($screen__l2) {
  .banner {
    &--background {
      padding: 65px;
      min-height:  590px;
      &.ratio-container:after {
        padding-bottom: calc(41.9% - 130px);
      }
    }
    &__header {
      font-size: 60px;
      margin-bottom: 30px;
    }
    &__description {
      font-size: 34px;
      margin-bottom: 25px;
    }
    &__button {
      padding: 19px 40px 17px;
    }
  }
}
@include min-screen($screen__xl + 100) {
  .banner {
    &__item {
      .after-element & {
        min-height: 590px;
      }
    }
    &--background {
      padding: 65px;
      &.ratio-container:after {
        display: none;
      }
    }
  }
}
