.um-gal-template-slider {
	.um-gal-image-wrapper {
		float: left;
		width: 100%;
		padding: 0px;
		position: relative;
	}
	.um-ginf-caption-block {
		h5 {
			display: none;
		}
		background-color: rgba(0,0,0,0.5);
		border-radius: 0px;
		left: 0;
		right: 0;
		bottom: 0;
		color: #FFF;
		font-weight: 300;
		display: block;
		padding: 7px;
		position: absolute;
		text-align: center;
		text-transform: uppercase;
		width: 100%;
	}
	.fa-arrow-circle-left {
		font-size: 35px;
	}
	.fa-arrow-circle-right {
		font-size: 35px;
	}
}
.pp_pic_holder {
	.ppt {
		opacity: 1;
		display: block;
		width: 100%;
		z-index: 9999;
		font-size: 16px;
		background: rgba(0,0,0,.8);
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 0px;
		text-shadow: 0 1px 2px #222;
		color: #FFF;
		font-weight: bold;
		line-height: 22px;
		text-align: center;
		margin: 10px 10px;
		padding: 10px;
	}
}


.um-gallery-image-items-view {
	float: left;
	width: 100%;
}
.um-gal-view-toolbar-bottom {
	float: left;
	width: 100%;
	padding-top: 30px;
	.toolbar-amount {
		display: none;
	}
	.limiter {
		display: none;
	}
}
.um-gallery-container {
	.block-description {
		text-align: center;
		line-height: 23px;
	}
}
.um-gallery-layout-no-filter {
	.um-gallery-item {
		h5 {
			font-size: 13px;
		}
	}
}
.um-gi-caption-block {
	h4 {
		font-weight: 600;
	}
}
.um-gal-image {
	img {
		cursor: pointer;
	}
}
.um-gal-template-grid {
	.um-gal-image-wrapper {
		float: left;
		width: 25%;
		padding: 10px;
		position: relative;
	}
}
.um-gallery-container-view {
	float: left;
	width: 100%;
	.block-title {
		float: left;
		width: 100%;
		text-align: center;
		h1 {
			margin: 0px;
			padding: 0px;
		}
	}
	.block-content {
		float: left;
		width: 100%;
		text-align: center;
	}
	.block-description {
		text-align: center;
		text-align: center;
		padding: 20px 0px;
	}
	.um-gal-image {
		.um-gi-icon {
			position: absolute;
			bottom: 55%;
			left: 50%;
			visibility: hidden;
			cursor: pointer;
			i {
				font-size: 30px;
				color: #FFF;
			}
		}
		&:hover {
			.um-gi-icon {
				visibility: visible;
			}
		}
	}
}
.um-gallery-container-view {
	.block-description {
		float: left;
		width: 100%;
	}
}
.um-ginf-caption-block {
	background-color: rgba(0,0,0,0.5);
	border-radius: 0px;
	left: 0.9rem;
	right: 0.9rem;
	bottom: 1.4rem;
	color: #FFF;
	font-weight: 300;
	display: block;
	padding: 7px;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	h4 {
		text-transform: none;
		margin: 0px;
		padding: 5px;
		text-align: left;
	}
}
#um-gallery-image-items-view {
	.um-ginf-caption-block {
		h5 {
			text-transform: none;
			margin: 0px;
			padding: 5px;
			text-align: left;
			font-weight: 400;
			line-height: 20px;
			font-size: 14px;
			white-space: nowrap;
			overflow: hidden;
			width: 100%;
		}
	}
}

