
.product.data.items {
    .data.item.content {
        > div {
            position: relative;
            z-index: 1;
        }
    }


    padding-right: 30px;
    @include lib-data-accordion(
    $_accordion-content-background-color       : transparent,
    $_accordion-content-border                 : 0 none,
    $_accordion-content-margin: 0 auto
    );

    > .item.content {
        font-family: $font-family__base;
        line-height: 28px;
        position: relative;
        > div {
        }

    }
    > .item.title {
	    font-size: $font-size__xl;
        cursor: pointer;
	    &:after,
	    &:before {
		    content: '•';
		    display: inline-block;
		    padding: 0 3px;
            color: $color-white;
	    }
        &.active {
            &:after,
            &:before {
                color: $theme--base--color;
            }
        }
        &:not(.active) {
            color: $theme--grey-middle;
        }
    }
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product.data.items {
        @extend %primary-trail--desktop;
        margin-right: auto;
        margin-left: auto;
        @include lib-data-tabs(
        $_tab-control-font-family            : $font-family-name__heading,
        $_tab-control-font-weight            : $font-weight__regular,
        $_tab-control-font-size              : $font-size__xl,
        $_tab-control-line-height            : $font-size__xl,
        $_tab-control-padding-top            : $indent__xs,
        $_tab-control-padding-right          : 0,
        $_tab-control-padding-bottom         : $indent__s,
        $_tab-control-padding-left           : 0,
        $_tab-control-margin-right           : 12px,
        $_tab-content-background-color       : transparent,
        $_tab-content-border                 : 0 none,
        $_tab-content-margin-top             : 0,
        $_tab-content-padding-top            : 20px,
        $_tab-content-padding-right          : 15px,
        $_tab-content-padding-bottom         : 0,
        $_tab-content-padding-left           : 15px
        );
        > .item.title {

            > .switch {
                background: none;
                border: none;
                height: auto;
                padding-bottom: 11px;
                cursor: pointer;

            }
            &:not(.disabled) > .switch:focus,
            &:not(.disabled) > .switch:hover {
                background: none;
                &:after,
                &:before {
                    color: $theme--grey-middle;
                }
            }

            &:not(.disabled) > .switch:active,
            &.active > .switch,
            &.active > .switch:focus,
            &.active > .switch:hover {
                padding-bottom: 11px;
                background: none;
                &:after,
                &:before {
                    color: $text__color;
                }
            }

        }
        > .item.title {
            margin-left: 12px;
            order: 0;
        }
        > .item.content {
            order: 3;

            float: none;
            margin-left: 0;

        }
        .data.item.content {
            > div {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                &:not(.table-wrapper) {
                    padding: 0;
                }
            }
        }
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@include max-screen($screen__m) {
    .product.data.items {
        @include lib-data-accordion(
                $_accordion-control-border-bottom          : 3px solid $theme--grey-light,
                $_accordion-content-background-color       : $theme--grey-lighter,
                $_accordion-content-border                 : 0 none,
                $_accordion-control-padding-top            : 10px,
                $_accordion-content-margin                 : 0
        );
        .data.item {
            display: block;
            text-align: center;
        }
        > .item.title {
            > .switch {
                background: none;
                border-left: 0 none;
                border-top: 0 none;
                border-right: 0 none;
                height: auto;
            }
            &:not(.disabled) > .switch:focus,
            &:not(.disabled) > .switch:hover {
                background: none;
            }

            &:not(.disabled) > .switch:active,
            &.active > .switch,
            &.active > .switch:focus,
            &.active > .switch:hover {
                background: none;
                border-bottom: 3px solid $theme__secondary--accent-color;
            }
        }
    }
}
