// ThemeStyle Begin
//  ---------------------------------------------
// Override the Standard Module

$_button-font-family: $font-family__base;
$_button-font-weight: $font-weight__regular;
a.action,
button {
    &:hover {
        text-decoration: none;
    }
}
button.action.primary {
    @include orange-btn;
}
%standard-button {
    @include  lib-button(
        $_button-font-family: $font-family-name__heading,
        $_button-font-weight: $font-weight__heavier,
        $_button-font-size: $font-size__15,
        $_button-color: $color-white,
        $_button-background: $theme--accent-color,
        $_button-border: 1px solid $theme--accent-color,
        $_button-color-hover: $color-white,
        $_button-background-hover: $theme__secondary--accent-color--hover,
        $_button-border-hover: 1px solid $theme__secondary--accent-color--hover,
        $_button-color-active: $color-white,
        $_button-background-active: $theme__secondary--accent-color,
        $_button-border-active: 1px solid $theme__secondary--accent-color,
        $_button-padding: 18px 40px 18px 40px
    );

    border-radius: 0;
    width: 370px;
    max-width: 100%;
    text-transform: uppercase;
    font-family: $font-family-name__heading;
    font-weight: $font-weight__heavier;
}

button {
  appearance: inherit;
  @include lib-button(
          $_button-font-family: $font-family__base,
          $_button-font-weight: $font-weight__regular,
          $_button-font-size: $font-size__sm,
          $_button-color: $color-white,
          $_button-padding: 12px 15px 12px,
          $_button-background: $theme__base--color,
          $_button-border: 1px solid $theme__base--color,
          $_button-color-hover: $link__hover__color,
          $_button-background-hover: transparent,
          $_button-border-hover: 1px solid $link__hover__color,
          $_button-color-active: $color-white,
          $_button-background-active: $theme__base--color,
          $_button-border-active: 1px solid $theme__base--color
  );
    &:active {
        box-shadow: $button__shadow;
    }
    border-radius: 0;
}
a.action.primary {
    @include lib-link-as-button();
}
.action.primary {

    @include  lib-button(
        $_button-font-family: $font-family-name__heading,
        $_button-font-weight: $font-weight__heavier,
        $_button-font-size: $font-size__sm,
        $_button-color: $color-white,
        $_button-background: $theme--accent-color,
        $_button-border: 1px solid $theme--accent-color,
        $_button-color-hover: $color-white,
        $_button-background-hover: $theme__secondary--accent-color--hover,
        $_button-border-hover: 1px solid $theme__secondary--accent-color--hover,
        $_button-color-active: $color-white,
        $_button-background-active: $theme__secondary--accent-color,
        $_button-border-active: 1px solid $theme__secondary--accent-color,
        $_button-padding: 12px 15px 12px
    );

    border-radius: 0;
    width: 100%;
    max-width: 370px;
  text-transform: uppercase;
  font-family: $font-family-name__heading;
  font-weight: $font-weight__heavier;
}

.transparen-button {
    @include  lib-button(
        $_button-font-family: $font-family-name__heading,
        $_button-font-weight: $font-weight__heavier,
        $_button-color: $link__color,
        $_button-background: transparent,
        $_button-border: 1px solid $link__color,
        $_button-color-hover: $link__hover__color,
        $_button-background-hover: transparent,
        $_button-border-hover: 1px solid $link__hover__color,
        $_button-color-active: $link__color,
        $_button-background-active: transparent,
        $_button-border-active: 1px solid $link__color,
        $_button-padding: 18px 40px 18px 40px

    );
  border-radius:0;
}
.transparen-button-white{
    @include  lib-button(
            $_button-font-family: $font-family__base,
            $_button-font-weight: $font-weight__semibold,
            $_button-color: $color-white,
            $_button-background: transparent,
            $_button-border: 1px solid $color-white,
            $_button-color-hover: $color-white,
            $_button-background-hover: $link__hover__color,
            $_button-border-hover: 1px solid $color-white,
            $_button-color-active: $color-white,
            $_button-background-active: transparent,
            $_button-border-active: 1px solid $color-white,
            $_button-padding: 7px 23px

    );
}

.action.subscribe.primary {
  @include lib-button(
      $_button-font-family: $font-family-name__heading,
      $_button-font-weight: $font-weight__heavier,
        $_button-font-size: $font-size__sm,
      $_button-color: $text__color,
      $_button-background: $color-white,
      $_button-border: 1px solid $color-white,
      $_button-color-hover: $color-white,
      $_button-background-hover: $link__hover__color,
      $_button-border-hover: 1px solid $link__hover__color,
      $_button-color-active: $link__color,
      $_button-background-active: transparent,
      $_button-border-active: 1px solid $link__color,
      $_button-padding: 14px 40px 14px 40px
  );
  &:active {
    box-shadow: $button__shadow;
  }
}


// ThemeStyle END
//  ---------------------------------------------