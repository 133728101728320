.block.newsletter {
	margin-bottom: $indent__xl;

	.form.subscribe {
		display: table;
		width: 100%;
	}

	.fieldset {
		display: block;
	}

	.field {
		margin: 0;

		.control {
			@include lib-icon-font(
				$icon-envelope,
				$_icon-font-size       : 35px,
				$_icon-font-line-height: 33px,
				$_icon-font-color      : $form-element-input-placeholder__color,
				$_icon-font-color-hover: $form-element-input-placeholder__color,
				$_icon-font-color-active: $form-element-input-placeholder__color
			);
			display: block;

			&:before {
				position: absolute;
			}
		}
	}

	input {
		padding-left: 35px;
	}

	.title {
		display: none;
	}

	.label {
		@extend .abs-visually-hidden;
	}

	.actions {
		display: block;
		width: 100%;
	}

	.action.subscribe {
		display: block;
		margin-top: 15px;
		width: 100%;
		margin-left: 0;
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
	.block.newsletter {
		width: 100%;

		.field {
			margin-right: 0;

			.control {
				width: 100%;
			}
		}
		.action.subscribe {
			border-radius: 0;
		}
	}
}
