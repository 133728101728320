.bubble {
  box-sizing: border-box;
  position: absolute;
  display: none;
  right: 0;
  top: 0;
  padding: 12px 9px;
  transform: translateX(-20%);
  color: $color-white;
  font-family: $heading__font-family__base;
  white-space: nowrap;
  &:after {
    content: '';
    width: 100%;
    border-radius:50%;
    background: $theme__secondary--accent-color;
    padding-bottom: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }

  &__content {
    display:table;
    position: relative;
    vertical-align: baseline;
    white-space: pre-line;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    padding: 7px 0 0;
    margin: 0;
    width: 30px;
    z-index: 1;
    transform: rotate(-15deg);

  }
  @include min-screen($screen__m) {
    display: flex;
    align-items: center;
      right: 0;
      top: 20px;
      padding: 25px 12px;
    &__content {
        font-size: 25px;
        line-height: 25px;
        padding: 12px 0 0;
      }
  }
  @include min-screen($screen__l2) {
      right: 20px;
      padding: 35px 17px;
    &__content {
        font-size: 38px;
        line-height: 38px;
      }
  }
}