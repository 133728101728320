.js-multicol--outer {
  margin-top: 0;
  &:last-child {
    > div {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  @include min-screen($screen__m) {
    padding-top: 10px;
  }
  @include min-screen($screen__l) {
    padding-top: 40px;
    &:last-child {
      > div {
        padding-bottom: 81px;

      }
    }
  }
}

// ARROWS
.js-multicol-banners {
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 5px;
  }
  .slick-arrow {
    background: $theme--accent-color;
    width: 22px;
    height: 30px;
    &:hover, &:focus {
      background: $color-white;
      border: 1px solid $theme--accent-color;
      svg {
        fill: $theme--accent-color;
      }
    }
    svg {
      fill: $color-white;
      width: 12px;
      height: 22px;
    }
  }
}

@include min-screen($screen__m) {
  .js-multicol-banners {
    .slick-arrow {
      height: 45px;
      width: 35px;
    }
  }
}
@include min-screen($screen__l2) {
  .js-multicol-banners {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .slick-arrow {
      width: 60px;
      height: 45px;
      svg {
      }
    }
  }
}


.js-multicol-banners {
  padding-left: 0;
  padding-right: 0;

  .banner {
    &--background {
      overflow: inherit;
      padding: 0;
      display: block;
    }
    &__item {
      min-height: inherit;
      margin-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
      &--inner {
        &:after, &:before {
          content: '';
          display: inline-block;
          position: absolute;
        }
        &:before {
          width: 50%;
          height: 100%;
          top: 0;
          right: 0;
          background: rgba(0,0,0, 0.3);
        }
        &:after {
          width: 100%;
          height: 100%;
          border: 1px solid $theme--grey-light;
          top: 9px;
          left: 9px;
        }
      }
      &--outer {
        &.ratio-container {
          position: relative;
          &:after {
            content:'';
            display: block;
            /* 16:9 = 56.25% = calc(9 / 16 * 100%) */
            padding-bottom: calc(58.6%);
          }
        }
      }
    }
    &__content {
      display: inline-block;
      padding: 15px;
      text-align: left;
      max-width: 370px;
      width: 90%;
      top: 5px;
      left: 5px;
      margin: 0;
      transform: inherit;
    }

    &__header {
      font-size: $font-size__m;
      margin-bottom: 5px;
    }
    &__description {
      font-size: $font-size__s;
      color: #fff;
      display: none;
    }
    &__button {
      white-space: nowrap;
      font-family: $font-family__base;
      font-size: $font-size__xs;
      text-transform: uppercase;
      padding: 10px 20px 10px;
      border: 0 none;
      svg {
        width: 42px;
        height: 12px;
        position: relative;
        top: 2px;
        fill: $theme__base--color;
        margin-left: 5px;

      }
      &:hover {
        svg {
          fill: $color-white;
        }
      }
    }
  }
}
@include min-screen($screen__xs) {
  .js-multicol-banners {
    .banner {
      &__content {
        padding: 30px;
        top: 0;
        left: 0;
      }
      &__header {
        margin-bottom: 15px;
      }
      &__description {
        display: block;
      }
    }
  }
}
@include min-screen($screen__m) {

  .js-multicol-banners {
    .banner {
      &__item {
        margin-bottom: 15px;
      }
      &__button {
        font-size: $font-size__sm - 1;
      }
    }
  }
}
@include min-screen($screen__l) {
  .js-multicol-banners {
    .banner {
      &__content {
        padding-left: 50px;
      }
      &__header {
        font-size: 28px;
      }
      &__description {
        font-size: 14px;
      }
    }
  }
}
@include min-screen($screen__l2) {
  .js-multicol-banners {
    .banner {
      &__content {
        top: 30px;
        left: 70px;
        bottom: 0;
      }
      &__button {
        display: inline-block;
        position: absolute;
        left: 50px;
        bottom: 70px;
        font-size: 13px;
      }
    }
  }
}