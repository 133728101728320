%text-dots {
	display: inline-block;
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
	&:after,
	&:before {
		content: '•';
		position: absolute;
		font-size: 28px;
		display: inline-block;
		top: calc(50% - 2px);
		transform: translateY(-50%);
	}
	&:after {
		left: 0;
	}
	&:before {
		right: 0;
	}
}

.text-orange {
    color: $theme--special-color;
}
