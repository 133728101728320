// gallery styles
@import 'gallery/general';
@import 'gallery/list';
@import 'gallery/list-with-filters';
@import 'gallery/owlcarousel-custom';
@import 'gallery/rwd';

// lib styles
@import 'lib/prettyphoto/prettyphoto';
@import 'lib/fontawesome/fontawesome';
@import 'lib/owlcarousel/owlcarousel';

@import 'gallery/kawa';

//   if your changes deeper copy
//  delete this file and make the changes on the Original File
// line 19
@include min-screen($screen__m) {
  .header.content {
    padding: 0px 15px 0;
    display: block;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    max-width: 1170px;
    z-index: 1;
    .logo {
      width: auto;
      max-width: 25%;
      float: left;
      margin: -10px 0 0px 0;
      img {
        display: inline-block;
        max-width: 100%;
        max-height: 75px;
      }
    }
  }
  // line 294
  .page-header {
    .panel.wrapper {
      border-bottom: 0 none;
    }
  }
}

// line 43
body:not(.catalog-product-view) {
  .page-main {
    .page-title-wrapper {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box;
      .page-title {
        margin-bottom: 30px;
        float: left;
        width: 100%;
        text-align: left;
        @include min-screen($screen__m) {
          font-size: 24px;
        }
        font-weight: $font-weight__heavier;
      }
      .page-title + .action {
        margin-top: $indent__l;
        margin-bottom: 24px;
      }
    }
  }
}


// Line 112
.footer {
  &.content {
    border-top: 0 none;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
  .copyright,
  .bugs {
    display: block;
    margin: 0;
  }
}
// line 330 Footer
@include min-screen($screen__m) {
  .page-wrapper {
    overflow-x: hidden;
  }
  .page-header .header.panel {
    padding-bottom: 15px;
    padding-top: 7px;
  }
  .navigation {
   > ul {
     padding: 0;
    }
  }
  .footer {
      &.content {
      .block {
        float: none;
      }
    }
  }
}
@include max-screen($screen__m) {
  .logo {
    margin-top: 5px;
    margin-left: 54px !important;

    img {
      max-width: 145px;
      height: 33px;
      width: auto;
    }
  }
}
.message.global.noscript, .message.global.cookie {
  border-top: 1px solid $theme__base--color;
  background: $color-white;
  color: $theme__base--color;
  text-align: center;
  padding: 15px;
  a {
    color: $theme__secondary--accent-color;
  }
}

.headline-big-title {
  h3 {
    color: #003d7c;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -.4px;
    text-align: center;
    margin-bottom: 6px;
    font-weight: 400;
  }

  a:hover {
    text-decoration: none;
  }

  hr {
    line-height: 1em;
    outline: 0;
    border: 0;
    text-align: center;
    height: 1.5em;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 48px;
    margin-top: 15px;
    position: relative;
    background: #fff;
    color: #003d7c;
    padding: 0 20px;

    &:before {
      content: '';
      background: #003d7c;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }

    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      background: #096;
      padding: 0 .5em;
      color: #003d7c;
      line-height: 1.5em;
      background-color: #fff;
    }
  }
}

.block-new-products {
  .products-grid {
    .product-item-info {
      border: none;
    }
  }
  .headline-big-title {
    hr {
      margin-bottom: 10px;
    }
  }
}

.row-homepage-images {
  width: auto !important;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 10px;

  h5 {
    line-height: 1.6;
    letter-spacing: .19px;
    font-size: 20px;
    font-weight: 300;
    color: #003d7c;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 32px;
    line-height: 1;
    color: #003d7c;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .btn {
    display: inline-block;
    padding: 2px 30px;
    margin-bottom: 0px;
    margin-top: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 1.16px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0px;
    color: #fff;
    text-transform: uppercase;
    background-color: #003d7c;

    &:hover {
      color: #fff;
      background-color: #234f7a;
      text-decoration: none;
    }
  }
}

.cms-index-index {
  .product-image-photo {

  }
}

html {
  .product-image-photo {

  }
  .blog-post-view .products-grid .product-item {
    margin-left: 0 !important;
  }
}

@include max-screen($screen__l_plus) {
  .nav-sections {
    background-color: #fff;
  }

  .navigation {
    background-color: transparent;
  }

  .navigation ul {
    background-color: transparent;
  }

  .nav-sections-item-content {
    margin-top: 0;
  }

  .nav-sections-item-title[aria-controls="store.menu"],
  .nav-sections-item-title[aria-controls="store.links"] {
    display: none;
  }

  .nav-sections-item-content[id="store.menu"] {
    padding-top: 68px !important;
    background-image: url('../images/logo.svg');
    background-repeat: no-repeat;
    background-position: 20px 15px;
    background-size: 70px;

    ul {
      > li.level0 {
        border: none !important;

        > a {
          border: 1px solid #234f7a !important;
          background-color: #b4d2f0;
          color: #234f7a;
          text-align: center;
          padding-top: 10px;
          padding-bottom: 10px;

          &.ui-state-active {
            background-color: #234f7a;
            color: #fff;
          }

          &:after {
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: auto;
            height: auto;
            content: '';
            background-image: none !important;
          }
        }

        &:not(:last-child) {
          > a {
            border-bottom: none !important;
          }
        }

        > ul {
          > li {
            padding-left: 15px;

            a {
              color: #666 !important;
              border: 0 !important;
              padding-left: 15px !important;
            }

            &.all-category {
              a {
                font-weight: bold;
              }
            }

            &:not(.all-category) {
              padding-left: 35px;
              background-repeat: no-repeat;
              background-position: 35px 5px;
              background-image: url(../images/svgs/chevron_right_grey.svg);
              background-size: 6px;

              a {
                padding-top: 0px;
              }
            }

            &.parent {
              ul {
                display: none !important;
              }
            }

          }
        }
      }
    }
  }

  [id="store.links"] {
    display: block !important;
    clear: both;

    ul.header.links {
      margin-top: 30px;

      li {
        border: none !important;

        > a {
          color: #666;
          padding-top: 8px;
          padding-bottom: 8px;
          padding-left: 20px;
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .headline-big-title {
    h3 {
      font-size: 32px;
    }
  }

  .row-homepage-images {
    h5 {
      font-size: 18px;
    }

    h3 {
      font-size: 28px;
    }
  }
}

@include min-screen($screen__m) {
  .hide-on-desktop {
    display: none !important;
  }
}
@include max-screen($screen__m) {
  .hide-on-mobile {
    display: none !important;
  }

  .row-homepage-images {
    [class^="col-"] {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
