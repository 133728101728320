
// Content Banner
.listed-items {
  .products-grid .product-item {
    margin-bottom: 15px;
    padding-bottom: 0;
  }
  .slick-prev {
    left: 5px;
  }
  .slick-next {
    right: 5px;
  }
  .slick-arrow {
    background: $theme--accent-color;
    width: 22px;
    height: 30px;
    &:hover, &:focus {
      background: $color-white;
      border: 1px solid $theme--accent-color;
      svg {
        fill: $theme--accent-color;
      }
    }
    svg {
      fill: $color-white;
      width: 12px;
      height: 22px;
    }
  }
}
@include min-screen($screen__m) {
  .listed-items {
    .product-items {
      // Content Wide
      box-sizing: border-box;
    }
    .slick-arrow {
      height: 45px;
      width: 35px;
    }
  }
}
@include min-screen($screen__l2) {
  .listed-items {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
    .slick-arrow {
      width: 60px;
      height: 45px;
      svg {
      }
    }
  }
}

