.sidebar-subtitle--filter {
	cursor: pointer;
	position: relative;
	font-weight: $font-weight__semibold;
	font-family: $font-family__base;
	text-transform: uppercase;
	border: 1px solid $theme--grey-light;
	padding-bottom: $indent__s;
	display: block;
	padding-top: 10px;
	padding-left: 10px;
	margin-bottom: 0;
	svg {
		display: inline-block;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		position: absolute;
		margin: 0;
		height: 8px;
		width: 12px;
		fill: $link__color;
		pointer-events: none;
		&.arrow-up {
			display: none;
		}
	}
	&:hover {
		svg {
			fill: $link__hover__color;
		}
	}
	.active & {
		svg {
			&.arrow-up {
				display: block;
			}
			&.arrow-down {
				display: none;
			}
		}
	}
	@include min-screen($screen__m) {
		display: none;
	}
}
.sidebar-nav-title {
	text-align: center;
	span {
		@extend %text-dots;
	}
}

.categories-menu {
	display: none;
	@include min-screen($screen__m) {
		display: block;
		margin-bottom: 40px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none none;
			font-family: $font-family__base;

			font-size: $font-size__m;
		}
		.title {
			font-weight: $font-weight__heavier;
			font-family: $font-family-name__heading;
			text-transform: uppercase;
			font-size: $font-size__15;
			border-top: 1px solid $theme__base--color;
			padding-bottom: 9px;
			padding-top: 17px;
			display: block;
			margin-bottom: 0;
			margin-top: 5px;

		}
		.item {

			 ul {
				visibility: hidden;
				opacity: 0;
				height: 0;
				position: relative;
				z-index: -1;
			}
			&.open {
				> ul {
					height: auto;
					opacity: 1;
					visibility: visible;
					z-index: 1;

				}
				.sidenav-link-current {
					> ul {
						height: auto;
						opacity: 1;
						visibility: visible;
						z-index: 1;

					}
				}
			}

			.sidenav-level-2, .sidenav-level-3  {
				padding-left: 20px;

				li {
					font-size: $font-size__sm;
					word-break: break-all;
					font-weight: $font-weight__heavier;
					padding: 10px 0;
					margin-bottom: 0;
					&:first-child {
						padding-top: 14px;
					}
					&:last-child {
						padding-bottom: 0;
					}
					a {
						padding-left: 10px;
					}
				}
			}

		}
		.sidenav--has-child {
			position: relative;
			&:after {
				-webkit-font-smoothing: antialiased;
				transform: rotate(45deg);
				font-size: 25px;
				color: $theme__base--color;
				content: $icon-remove;
				font-family: $icons__font-name;
				vertical-align: middle;
				display: inline-block;
				font-weight: normal;
				overflow: hidden;
				speak: none;
				text-align: center;
				position: absolute;
				right: 13px;
				top: 10px;
				line-height: 30px;
				cursor: pointer;
			}
			&:hover {
				color: $color-black;
			}
			&.open {
				&:after {
					transform: rotate(0);
				}
			}
		}
		.sidenav-level-1 {

			> .item {
				margin: 0;
				font-size: $font-size__sm;
				word-break: break-all;
				font-weight: $font-weight__semibold;
				padding: 10px 0;
				color: $text__color;
				&:last-child {
					border: 0 none;
				}
				&:hover {
					a {

					}
				}
			}
			a {
				display: block;
				color: $text__color;
				padding: 6px 0 6px 20px;
				&:hover {
					text-decoration: none;
					color: $link__hover__color;
				}
			}
			.sidenav-link-current {
				> a {
					color: $link__color;
					font-weight: $font-weight__semibold;
					border-left: 1px solid $theme__base--color;
					&:hover {
						color: $link__hover__color;
					}

				}
			}
		}


	}
}