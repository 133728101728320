.filter.block {
	margin-bottom: 0;

	.filter-title {
		margin-bottom: 0;
		strong {
			cursor: pointer;
			background-color: transparent;
			box-shadow: none;
			color: $theme__base--color;
			border: 1px solid $theme__base--color;
			left: 10px;
			line-height: 19px;
			padding: 14px 10px 14px 10px;
			position: absolute;
			text-align: center;
			top: 0;
			z-index: 2;
			font-size: $font-size__sm;
			font-weight: $font-weight__heavier;
			&[data-count]:after {
				color: #ffffff;
				background: #ff5501;
				border-radius: 2px;
				content: attr(data-count);
				display: inline-block;
				font-size: 0.8em;
				line-height: 1;
				margin: 0 5px;
				min-width: 1em;
				padding: 2px;
			}
			&.disabled {
				opacity: 0.5;
			}
		}
	}
}
.filter .block-subtitle {
	text-align: center;
	> span {
		@extend %text-dots;
		color: $text__color__grey;
	}
	font-weight: $font-weight__heavier;
	font-family: $font-family-name__heading;
	text-transform: uppercase;
	font-size: $font-size__15;
	border-top: 1px solid $theme__base--color;
	padding: 15px 10px 10px 0;
	@include max-screen($screen__m) {
    padding-left: 10px;
  }
}

.filter-subtitle {
	display: none;
}


.filter-current {
	.item {
		margin-bottom: $indent__s;
	}

	.filter-label {
		display: block;
		font-weight: $font-weight__bold;

		&:after {
			content: ':';
		}
	}

	+ .filter-actions {
		margin-bottom: 10px;
		a {
			font-family: $font-family-name__heading;
			font-weight: $font-weight__heavier;
			font-size: $font-size__s + 1;
			text-transform: uppercase;
		}
	}
}

.filter-options-content {
	.filter-count-label {
		@extend .abs-visually-hidden;
	}
}

.filter-options {
	margin: 0;

	.filter-options-title {
		@include lib-heading(h4);
		margin: 0 0 $indent__s;
		word-break: break-all;
	}

	.filter-options-content {
		margin: 0 0 $indent__m;

		.item {
			margin-bottom: 3px;
		}
	}

	.count {
		@include lib-css(color, $text__color__muted);

		&:before {
			content: '(';
		}

		&:after {
			content: ')';
		}
	}
}

.items {
	@extend .abs-reset-list;
}

.filtered {
	.items {
		margin: 15px 0;
	}

	.item {
		margin-bottom: 6px;
		padding-left: 22px;
		position: relative;

		.label {
			font-weight: $font-weight__bold;
		}

		.action.remove {
			@extend .abs-remove-button-for-blocks;
			left: -6px;
			position: absolute;
			top: 0;
		}
	}

	& + .actions {
		margin-bottom: 35px;
	}
}


//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
	.page-with-filter {
		.columns {
			.sidebar-main {
				@include lib-vendor-prefix-order(0);
			}
		}
	}
}

// Completely from Luma Theme

.filter-content .filter-current {
	margin: 0;
	.items {
		padding: 5px 0;
	}
	.filter-value {
		color: $theme__base--color;
	}
	.item {
		line-height: 25px;
		position: relative;
		z-index: 1;
		margin: 0 5px 5px 0;
		padding: 0 15px 0 27px;
		display: inline-block;
		border: 1px solid $theme--grey-light;
		border-radius: 14px;
		font-size: $font-size__s + 1;
	}
}

.filter .filter-current-subtitle {
	display: block;
	font-family: $font-family__base;
	font-size: $font-size__sm;
	padding-bottom: 5px;
	text-transform: none;
	text-align: left;
}

.filter-current {
	.action.remove {
		left: 0;
		position: absolute;
		top: -1px;
		> span {
			border: 0;
			clip: rect(0, 0, 0, 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
		&:before {
			line-height: 15px;
			-webkit-font-smoothing: antialiased;
			font-size: 27px;
			color: $theme--grey-light;
			content: $icon-remove;
			font-family: $icons__font-name;
			vertical-align: middle;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: left;

		}
	}
	.filter-label {
		font-weight: $font-weight__regular;
		display: inline-block;
		line-height: 20px;
		&:after {
			content: ': ';
		}
	}
}

.filter-actions {
	margin-bottom: 25px;
	padding: 0 10px;
}



.filter-value {
	color: $theme--grey-middle;
}

.filter-options {
	display: none;
	margin: 0;
}
.filter-options {

	.swatch-attribute-options {
		padding: 10px 0 0 20px;
	}

	.filter-options-item {

		padding: 10px 0;
		&.active {
			.filter-options-title {
			}
		}
	}

	.filter-options-title {
		cursor: pointer;
		font-weight: $font-weight__semibold;
		font-family: $font-family__base;
		margin: 0;
		font-size: $font-size__sm;
		overflow: hidden;
		display: block;
		color: $text__color;
		padding: 6px 0 6px 20px;
		position: relative;
		text-transform: none;
		word-break: break-all;
		z-index: 1;
		display: block;
		text-decoration: none;
		@include max-screen($screen__m) {
			padding-left: 10px;
		}

		&:after {
			-webkit-font-smoothing: antialiased;
			transform: rotate(45deg);
			font-size: 25px;
			color: $theme__base--color;
			content: $icon-remove;
			font-family: $icons__font-name;
			vertical-align: middle;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: center;
			position: absolute;
			right: 28px;
			top: 0;
			line-height: 30px;
			cursor: pointer;
		}
		&:hover {
			color: $link__hover__color;
		}
		&.open {
			&:after {
				transform: rotate(0);
			}
		}
	}

	.active > .filter-options-title:after {
		transform: rotate(0);
	}

	.filter-options-content {
		margin: 0;
		.items {
			padding-left: 20px;
		}
		.item {
			padding: 10px 0;
			margin: 0;
			&:last-child {
				padding-bottom: 0;
			}
		}
		a {
			font-size: $font-size__sm;
			color: $text__color;
			font-weight: $font-weight__heavier;
			padding: 6px 0 6px 10px;
			&:hover {
				background-color: transparent;
				text-decoration: none;
				color: $link__hover__color;
				+ .count {
					color: $theme--accent-color;
				}
			}
		}
		.count {
			color: $text__color;
			padding-right: 5px;
			&:before {
				content: '(';
			}
			&:after {
				content: ')';
			}
		}
	}
}
/// MIN WIDTH 768
@include min-screen($screen__m) {
	.filter.block {
		margin-bottom: 40px;
	}

	.filter-title {
		display: none;
	}

	.filter-content .item {
		margin: 10px 0;
	}

	.filter-actions {
		margin-bottom: 30px;
	}

	.filter.active .filter-options,
	.filter-options {
		background: transparent;
		clear: both;
		display: block;
		overflow: initial;
		position: static;
	}

	.filter-subtitle {
		display: block;
		position: static;
	}

	.page-layout-1column {

		.toolbar-products {
			float: left;
			width: 100%;
		}
		.products ~ .toolbar-products {
			position: static;
		}
		&.page-with-filter .column.main {
			padding-top: 45px;
			position: relative;
			z-index: 1;
		}
		.filter.block {
			border-top: 1px solid $theme--grey-light;
		}
		.filter-content {
			margin-top: 10px;
		}
		.filter-subtitle {
			display: none;
		}
		.filter-options-item {
			border: 0;
			display: inline-block;
			margin-right: 25px;
			position: relative;
			&.active {
				z-index: 2;
				.filter-options-content {
					visibility: visible;
				}
				&:hover {
					z-index: 3;
				}
				&:after,
				&:before {
					border: 8px solid transparent;
					height: 0;
					width: 0;
					border-bottom-color: $color-black;
					bottom: -1px;
					content: '';
					display: block;
					left: 5px;
					position: absolute;
					z-index: 3;
				}
				&:after {
					border-bottom-color: $color-white;
					margin-top: 2px;
					z-index: 4;
				}
			}
		}
		.filter-options-title {
			padding: 0 20px 0 0;
			&:after {
				right: 2px;
				top: 3px;
				z-index: 3;
			}
		}
		.filter-options-content {
			background: $color-white;
			box-shadow: 0 3px 5px 0 $theme--grey-light;
			border: 1px solid $theme--grey-light;
			padding: 5px 0;
			position: absolute;
			top: 100%;
			visibility: hidden;
			width: 180px;
			z-index: 2;
			.item {
				margin: 0;
				padding: 5px;
				a {
					margin-left: 0;
				}
				&:hover {
					color: $theme--accent-color;
				}
			}
		}
		.filter-current {
			display: inline;
			line-height: 35px;
		}
		.filter-current-subtitle {
			color: #7d7d7d;
			display: inline;
			font-size: 14px;
			font-weight: normal;
			padding: 0;
			&:after {
				content: ':';
			}
		}
		.filter-current {
			.item,
			.items {
				display: inline;
			}
			.item {
				margin-right: 25px;
				white-space: nowrap;
			}
			.action.remove {
				line-height: normal;
			}
		}
		.filter-actions {
			display: inline;
			white-space: nowrap;
			~ .filter-options {
				margin-top: 25px;
			}
		}
	}
}


@include max-screen($screen__m) {

	.filter {
		&.active {
			position: relative;
			visibility: visible;
			z-index: 99;
			.filter-options-item:last-child {
				margin-bottom: 40px;
			}
			.filter-title {
				height: 55px;
				left: 0;
				position: fixed;
				right: 0;
				top: 0;
				z-index: 2;
				border-bottom: 1px solid $theme__base--color;
				strong {
					padding: 19px 10px 14px 10px;
					box-shadow: none;
					background: none;
					border: 0;
					cursor: pointer;
					color: transparent;
					left: auto;
					right: 3px;
					top: 2px;
					display: inline-block;
					text-decoration: none;
					&:after {
						-webkit-font-smoothing: antialiased;
						font-size: 32px;
						line-height: inherit;
						color: $theme--grey-dark;
						content: $icon-remove;
						font-family: $icons__font-name;
						vertical-align: middle;
						display: inline-block;
						font-weight: normal;
						overflow: hidden;
						speak: none;
						text-align: center;
						z-index: 9999;
						position: relative;
						top: 7px;
						right: 0;
					}
				}
			}
			.filter-subtitle {
				background: $color-white;
				display: block;
				height: 50px;
				left: 0;
				line-height: 32px;
				position: fixed;
				right: 0;
				top: 0;
				z-index: 1;
			}
			.filter-options {
				background: $color-white;
				bottom: 0;
				display: block;
				left: 0;
				overflow: scroll;
				position: fixed;
				right: 0;
				top: 55px;
				z-index: 2;
				.filter-options-title {
					float: left;
					width: 100%;
				}
			}
		}
		.filter-subtitle {
			font-size: 12px;
			font-weight: 300;
			padding-top: 22px;
		}
	}

	.filter-actions {
		margin: -35px -10px 25px;
	}

	.filter-options-content {
		padding: 5px 10px;
	}

	.filter {
		.filter-current {
			border: solid $theme--grey-light;
			border-width: 1px 0;
			margin: 55px -10px 0;
			.items {
				display: none;
			}
		}
		.filter-current-subtitle {
			position: relative;
			text-transform: uppercase;
			z-index: 1;
			display: block;
			text-decoration: none;
			padding-bottom: 10px;
			padding-top: 10px;
			&:before {
				-webkit-font-smoothing: antialiased;
				font-size: 42px;
				line-height: inherit;
				color: inherit;
				content: $icon-down;
				font-family: $icons__font-name;
				vertical-align: middle;
				display: inline-block;
				font-weight: normal;
				overflow: hidden;
				speak: none;
				text-align: center;
				position: absolute;
				right: 10px;
				top: 10px;
			}
			&:after {
				color: #7d7d7d;
				content: " (" attr(data-count) ")";
				font-size: 0.9em;
			}
		}
		.filter-current {
			&.active {
				padding-bottom: 30px;
				.block-subtitle:before {
					content: $icon-up;
				}
				.items,
				+ .block-actions {
					display: block;
				}
			}
			+ .block-actions {
				display: none;
			}
		}
	}

	.filter-no-options {
		.filter-title:before {
			background: rgba(255, 255, 255, 0.5);
			content: '';
			display: block;
			height: 40px;
			left: -15px;
			margin-top: -60px;
			position: relative;
			width: 100px;
			z-index: 99;
		}
		.filter-content {
			margin-bottom: 20px;
		}

	}

}
