$toolbar-mode-icon-font-size: 24px !default;
$toolbar-element-background : $panel__background-color !default;

// Style Select
.toolbar {
    select {
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $theme__base--color;
        &::-ms-expand {
            display: none;
        }
    }
}
select {
    &::-ms-expand {
        display: none;
    }
}

//Issue 53264
.category-guiding-fliegenfischen, .categorypath-trainings-fliegenbinden, .categorypath-trainings-fliegenfischerkurse {
    .page-wrapper {
        .columns {
            z-index: -1;
        }
    }
}

// Line 14

.page-products {
    .columns {
        padding-top: 45px;
    }
}
@include max-screen($screen__m) {
    .sorter-label {
        display: none;
    }
    .toolbar-amount {
        position: static;
        left: 0;
        top: 0;
        text-align: left;
    }
    .page-products .sorter {
        position: absolute;
        top: 0;
        right: 5px;
        padding: 0;
    }
}

@include max-screen($screen__xs) {
    .page-products .sorter {
        position: relative;
        float: left;
        left: -10px;
    }
    .filter.block{
        float: left;
        width: 100%;
    }
}
//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .columns {
            padding-top: 0;
        }
    }
}

.page-products {
    .columns {
        position: relative;
        z-index: 1;
    }
}

.toolbar {
    @extend .abs-add-clearfix;
}

.toolbar-amount {
    display: block;
    font-size: $font-size__sm;
    font-weight: $font-weight__heavier;
    line-height: $toolbar-mode-icon-font-size + 2;
    margin: 0;
    padding: 14px 0 0;

    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.toolbar-products {
    @extend .abs-add-clearfix;
    margin-bottom: 17px;
    padding: 0;
    text-align: center;

    .pages {
        display: none;

        .products.wrapper ~ & {
            display: block;
        }
    }

    .limiter {
        display: none;

        .control {
            display: inline-block;
        }
    }
}

.sorter {
    position: relative;
    padding: 4px 0 0;
    font-size: $font-size__sm;
    font-weight: $font-weight__heavier;
    .products.wrapper ~ .toolbar & {
        display: none;
    }
}

.sorter-options, .limiter-options {
    margin: 0 0 0 7px;
    width: auto;
    padding: 14px 25px 14px 10px;
    max-width: 100%;
    height: auto;
    font-size: $font-size__sm;
    border: 1px solid $theme__base--color;
    background: transparent;
    @include min-screen($screen__m) {
        width: 266px;
    }
}

.sorter-action {
    vertical-align: top;
    @include lib-icon-font(
            $icon-down,
        $_icon-font-size       : 42px,
        $_icon-font-line-height: 42px,
        $_icon-font-color      : $header-icons-color,
        $_icon-font-color-hover: $header-icons-color-hover,
        $_icon-font-text-hide  : true
    );
    position: absolute;
    top: calc(50% + 2px);
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    &:before {
        margin-right: -19px;
    }
}

.sorter.sort-desc {
    &:before {
        content: $icon-arrow-down;
    }
}

.modes {
    display: none;
}
.field.limiter {
    .control {
        position: relative;
        @include lib-icon-font(
                $icon-down,
            $_icon-font-size       : 42px,
            $_icon-font-line-height: 42px,
            $_icon-font-color      : $header-icons-color,
            $_icon-font-color-hover: $header-icons-color-hover,
            $_icon-font-text-hide  : true
        );
        &:before {
            pointer-events: none;
            position: absolute;
            right: 25px;
            top: 5px;
        }
    }
}
.limiter-options {
    margin: 0 22px 0 22px;
    padding: 14px 55px 14px 15px;
    width: auto;

}

.limiter-label {
    font-weight: 400;
}

.limiter {
    .page-products .toolbar & {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .page-products {
        .columns {
            padding-top: 0;
            position: relative;
            z-index: 1;
        }
    }

    .toolbar {
        .products.wrapper ~ & .pages {
            float: left;
        }
    }

    .toolbar-amount {
        float: left;
    }

    .sorter {
        float: right;
    }

    .modes {
        display: block;
        float: left;
        margin-right: $indent__base;

        .products.wrapper ~ .toolbar & {
            display: none;
        }
    }

    .modes-label {
        @extend .abs-visually-hidden-desktop;
    }

    .modes-mode {
        color: $theme__base--color;
        border: 1px solid $theme__base--color;
        border-right: 0;
        float: left;
        font-weight: $font-weight__regular;
        line-height: 1;
        position: relative;
        top: 2px;
        padding: 11px 10px;
        text-align: center;
        @include lib-icon-font(
            $icon-grid,
            $_icon-font-size       : $toolbar-mode-icon-font-size,
            $_icon-font-text-hide  : true,
            $_icon-font-color      : $text__color__muted,
            $_icon-font-color-hover: $text__color__muted,
            $_icon-font-display    : block
        );

        &:not(.active) {
            &:hover {
                color: $text__color__muted;
                background: darken($toolbar-element-background, 7%);
            }
        }

        &:last-child {
            border-right: 1px solid $theme__base--color;
        }

        &.active {
            color: $primary__color__light;
        }
    }

    .mode-list {
        @include lib-icon-font-symbol($icon-list);
    }

    .limiter {
        float: right;

        .products.wrapper ~ .toolbar & {
            display: block;
            font-size: $font-size__sm;
        }
    }
}
