.pages {
    @include lib-pager(
            $_pager-font-size                   : $font-size__sm,
            $_pager-item-margin: 0 15px 0 0,
    $_pager-current-color               : $color-white,
    $_pager-current-background: $theme__base--color,
            $_pager-font-weight                 : $font-weight__regular,
            $_pager-color                       : $text__color,
            $_pager-color-visited               : $text__color,
            $_pager-color-hover                 : $link__hover__color,
            $_pager-color-active                : $text__color,
            $_pager-action-background-visited   : transparent,
            $_pager-action-background-active    : transparent,
            $_pager-action-background           : transparent,
            $_pager-action-background-hover     : $theme--accent-color--hover,
            $_pager-action-color-hover          : $color-white
    );

    .action {
        width: 34px;
        border: 1px solid $theme--grey-light;

        &.previous {
            margin-right: 12px;
            display: none;
        }

        &.next {
            margin-left: 12px;
            display: none;
        }
    }
    .item {
       > strong, > a {
           padding: 0 12px;
           min-width: 32px;
           border-radius: 16px;
       }
    }
}
