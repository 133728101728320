#algolia-autocomplete-container {
    .aa-dropdown-menu {
        @include min-screen($screen__l) {
            max-width: 30%;
        }
        @media (min-width: $screen__m) and (max-width: $screen__m_plus) {
            max-width: 70%;
        }
        @include max-screen($screen__m) {
            max-width: 100vw;

            height: calc(100vh - 130px);
        }
    }

    // Rewrite standard behaviour with sticky effect
    .aa-dropdown-menu.sticky {
        @media (min-width: $screen__m) {
            top: 86px !important;
            position: fixed !important;
        }

        /* Portrait */
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
            left: auto !important;
        }
    }

    .aa-dataset-products {
        width: 100%;

        @include max-screen($screen__m) {
            position: relative;
            padding-bottom: 40px;

            #autocomplete-products-footer {
                display: block;
                background: none;
                text-align: left;
                margin-left: 15px;
            }
        }
    }
}

body:not(.algolia-search) {
    #algolia-autocomplete-container {
        @include max-screen($screen__m) {
            display: none;
        }
    }
}

body.algolia-search {
    @include max-screen($screen__m) {
        height: 100vh;
        overflow-y: hidden;
    }

    #algolia-autocomplete-container {
        @include max-screen($screen__m) {
            display: block;
        }

        .aa-dropdown-menu {
            @include max-screen($screen__m) {
                overflow-y: scroll;
            }
        }
    }
}

#algolia-searchbox {
    .algolia-search-input:focus:not([value=""]) {
        &:focus {
            background: #fff;
            @include max-screen($screen__m) {
                border-color: $theme--special-color;
            }
        }
    }

    #algolia-glass {
        @include max-screen($screen__m) {
            display: none;
        }
    }

    .algolia-search-input {
        @include max-screen($screen__xs) {
            margin-top: 25px;
            margin-left: -80px;
        }
        /* Landscape */
        @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
            margin-top: 35px;
        }
    }

    .ais-search-box--magnifier {
        display: none;
    }

    .ais-search-box--reset {
        top: 2px;
        right: 30px;

        &:hover {
            border: none;
        }
    }
}

#algolia_instant_selector {
    .result {
        padding: 25px 15px !important;
        transition: transform .2s;

        .result-title {
            height: 36px;
            overflow: hidden;
            margin-bottom: 0px;
        }



        .price-wrapper {
            float: left;
            width: 100%;
            margin: 10px 0;
            font-family: Montserrat, Helvetica, Arial, sans-serif, Helvetica Neue;

            .after_special {
                text-align: center;
                font-weight: 700;
                color: #666;
            }

            .after_special.promotion {
                color: #ff7800;
            }

            .tier_price {
                float: left;
                width: 100%;
                color: #666;
                font-size: 14px;

                .tier_value {
                    color: #666;
                }
            }
        }

        .product-reviews-summary .rating-summary {
            margin-right: auto;
        }
    }
    #instant-search-bar-container {
        .instant-search-bar-label {
            width: 160px;

            span {
                font-size: 14px;
            }
        }

        #instant-search-bar {
            width: calc(100% - 160px);
        }

        .ais-SearchBox-input {
            border: none;
            box-shadow: none;
        }

        .ais-SearchBox-reset {
            top: 0;
        }
    }
}
@include max-screen($screen__l) {
    #algolia_instant_selector #algolia-left-container {
        width: 100%;
        max-width: initial;
        flex: initial;
    }

    #algolia_instant_selector #algolia-right-container {
        width: 100%;
        max-width: initial;
        flex: initial;
        .content-wrapper {
            width: 100%;
        }
    }
}

@media (min-width: $screen__m) {
    #algolia_instant_selector {
        .result {
            &:hover {
                transform: scale(1.1);
                padding: 8px 15px 22px !important;

                .result-title {
                    height: 71px;
                    line-height: 71px;

                    span {
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 1.3;
                    }
                }

                .price-wrapper {
                    margin-top: 5px;
                    margin-bottom: 0px;

                    > div {
                        line-height: 1;
                        margin-top: 4px;
                    }
                }
            }
        }
    }

    #algolia-left-container {
        max-width: 24% !important;
    }
}

@media (max-width: $screen__m) {
    #algolia_instant_selector {
        .result {

            .result-title {
                height: auto;
            }
        }
    }

}

#instant-search-pagination-container {
    .ais-pagination {
        padding-left: 0;
    }
}

.ais-RangeSlider .rheostat {
    width: 70% !important;
    margin-left: 13% !important;
    font-size: 12px;
}

.search-bar-wrapper {
    width: 100%;
}


.ais-SearchBox-submit {
    display: none !important;
}

.ais-CurrentRefinements {
    .ais-CurrentRefinements-item {
        margin-right: 0;
        display: block;
        border: 0;
        background: transparent;
        padding-top: 0;
        padding-bottom: 0;
        text-overflow: ellipsis;
        padding-right: 20px;
        position: relative;

        .ais-CurrentRefinements-delete {
            display: inline !important;
            background: transparent !important;
            padding-top: 4px !important;
            position: absolute;
            right: -6px;
            top: -1px !important;

            &:hover {
                color: #000!important;
            }
        }
    }
}

#algolia_instant_selector button:not(.ais-price-ranges--button) {
    top: 4px;
}

.ais-SearchBox {
    margin-bottom: 0;
}

.rheostat-tooltip {
    background-color: #fff !important;
}

body {
    .ais-Hits {
        padding: 0px;

        .ais-Hits-item .result-wrapper {
            padding: 0px !important;
        }
    }
}

#algolia-autocomplete-container .aa-dropdown-menu .algoliasearch-autocomplete-price .promotion {
    color: #ff7800;
}

#algolia_instant_selector #clear-refinements button.ais-ClearRefinements-button {
    background-color: #ff7800;
    color: #FFF;
}

.ais-CurrentRefinements .ais-CurrentRefinements-item {
    color: #ff7800;
}

body .ais-HierarchicalMenu-link--selected {
    color: #ff7800;
}

body.catalogsearch-result-index,
body.catalog-category-view {
    #instant-search-bar-container {
        display: none !important;
    }
}
