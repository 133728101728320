//
//  Responsive variables
//  _____________________________________________

$use-flex: true !default; // Use flexbox [true|false]
$responsive: true !default; // Theme is responsive [true|false]

$screen__xxs:       320px;
$screen__xs:        480px;
$screen__s:         640px;
$screen__m_plus:    991px;
$screen__m:         768px;
$screen__l:         1024px;
$screen__l_plus:    1025px;
$screen__xl:        1440px;
