@include min-screen($screen__m) {
  .top-row .header-wishlist {
    float: left;
    &__trigger {
      @include lib-icon-font(
              $icon-wishlist,
          $_icon-font: $icons-customer__font-name,
          $_icon-font-size: $font-size__l
      );
      &:before {
        line-height: 24px;
      }
    }
  }
  .link.wishlist {
    margin-left: 18px;
    margin-right: 18px;
  }
}
.header-wishlist__text {
  @extend .abs-visually-hidden;
}
@include max-screen($screen__m) {
  .header-wishlist {

  }
}

.header-wishlist__trigger {
  color: $text__color;
  font-size: $font-size__base;
  font-family: $font-family__base;
  font-weight: $font-weight__regular;
  text-transform: uppercase;
  &:visited {
    color: $text__color;
  }
  &:hover {
    color: $link__hover__color;
    text-decoration: none;
  }
}