.bundle-actions {
	@extend .abs-box-tocart;
	.action.primary {
		@extend .abs-button-l;
	}
}

.bundle-options-container {
	.product-addto-links {
		.action.towishlist {
			@extend .transparen-button;
			svg {
				display: none;
			}
			@include lib-icon-font(
					$icon-wishlist,
				$_icon-font-position: after,
				$_icon-font: $icons-customer__font-name,
				$_icon-font-size: $font-size__l,
				$_icon-font-margin: 0 0 0 10px
			);
			&:after {
				line-height: 24px;
			}
		}
	}
	clear: both;
	margin-bottom: $indent__xl;

	.legend.title {
		@include lib-heading(h2);
		font-size: $font-size__xl;
	}

	.product-add-form {
		display: none;
	}

	.input-text.qty {
		@extend .abs-input-qty;
	}

	.product-options-wrapper {
		margin-bottom: $indent__l;
	}

	.action.back {
		margin-bottom: $indent__l;
	}

	.price-box {
		.price {
			font-size: $font-size__l;
			font-weight: $font-weight__bold;
		}
	}

	.price-notice {
		@extend .abs-adjustment-incl-excl-tax;
	}

	.block-bundle-summary {

		.bundle-info {
			.field.qty {
				display:  flex;
				align-items: center;
				margin-bottom: 10px;

			}
			.label {
				font-weight: $font-weight__bold;
				margin: 0 0 5px;
				display: inline-block;
				width: 35%;
			}
			 .control {
				width: 65%;
				 margin: 0 0 5px;
			}
			.actions {
			}
		}

		@extend .abs-add-box-sizing;
		@include lib-css(background, $secondary__color);
		padding: $indent__s $indent__base;

		> .title > strong {
			@include lib-heading(h3);
			display: block;
		}

		.bundle-summary {
			margin-top: $indent__l;

			&.empty {
				display: none;
			}

			> .subtitle {
				@include lib-heading(h3);
				display: block;
			}
		}

		.bundle.items {
			@extend .abs-reset-list;

			> li {
				margin-bottom: $indent__s;
			}
		}

		.box-tocart {
			.actions {
				display: block;
				margin-bottom: 20px;
			}

			.action.primary {
				@extend .abs-button-l;
			}
		}

		.product-addto-links {
			> .action {
				@extend .abs-action-addto-product;
				vertical-align: top;
				margin-bottom: 10px;
				text-transform: uppercase;
				font-size: $font-size__sm;
				width: 100%;
				max-width: 370px;
			}
		}
	}
	.field.option {
		margin-bottom: 20px;
		padding-bottom: 10px;
		> .label {
			margin-bottom: 7px;
		}
		.multiselect {
			height: auto;
			padding: 20px 20px 0;
		}
		border-bottom: 1px solid  $border-color__base;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.nested {
		&.options-list {
			padding-top: 10px;
		}
		.product-name {
		}
		.option {
			position: relative;
			top: 10px;
		}
		.field.qty {
			display: flex;
			align-items: center;
			margin-top: 10px;
			.label {
				@include lib-css(font-weight, $form-field-label__font-weight);
				@include lib-css(margin, 0 9px 0 0);
				display: inline-block;
			}

			@include lib-css(margin-top, 5px);
		}
	}

	p.required {
		@include lib-css(color, $form-field-label-asterisk__color);
	}

	.product-options-bottom {
		clear: left;
		flex: 1 1 100%;
		order: 3;
	}
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

	.bundle-options-container {
		#product_addtocart_form {
			display: flex;
			flex-wrap: wrap;
		}

		.legend.title {
			@extend .abs-reset-left-margin-desktop;

		}

		.bundle-options-wrapper,
		.product-options-wrapper {
			float: left;
			width: 60%;
			padding-right: 15px;
			order: 1;
		}
		.bundle-options-wrapper {
			.product-options-wrapper {
				width: 100%;
			}
		}
		.block-bundle-summary {
			float: right;
			position: relative;
			width: 40%;
			order: 2;
		}

		.bundle-options-wrapper,
		.block-bundle-summary {
			.page-layout-2columns-left &,
			.page-layout-2columns-right &,
			.page-layout-3columns & {
				width: 48%;
			}
		}
	}
}
