.footer {
  &-social{
    display: inline-block;

  }
  &-custom__links{
    a {
      display: inline-block;
      margin-top: 8px;
    }
  }
}
.social-icon {
  &__links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @at-root #{&}__link {
    a {
      display: block;
      position: relative;
      height: 46px;
      width: 46px;
      margin-right: 31px;
      border: 1px solid $color-white;
      border-radius: 23px;
      @include lib-icon-font(
              $icon-facebook,
          $_icon-font: $icons-customer__font-name,
          $_icon-font-size: $font-size__l
      );
      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        border: 1px solid $link__hover__color;
      }
    }
    &:last-child {
      a {
        margin-right: 0;
      }
    }

  }
  &--facebook {

  }
  &--instagram {
    a:before {
      content: $icon-instagram;
    }
  }
  &--twitter {
    a:before {
      content: $icon-twitter;
    }
  }
}