.footer.content .footer-bottom {
  &__links, ul {
    > li {
      display: inline-block;
      padding: 0 13px 0 0;
      margin-right: 13px;
      margin-bottom: 5px;
      text-transform: none;
      border-right: 1px solid lighten($theme--grey-dark, 15%);
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        margin-right: 13px;
      }
    }
  }
  &__list {
    @include max-screen($screen__m) {
      margin-bottom: 10px;
    }
  }
}


