
.um-gallery-layout-no-filter {
	.um-gallery-wrap {
		float: left;
		width: 100%;
		padding: 10px;
	}
	.um-gallery-items {
		float: left;
		width: 100%;
	}
	.um-gallery-item {
		float: left;
		width: 25%;
		padding: 10px;
		position: relative;
		a {
			color: #fff;
			font-size: 16px;
			text-decoration: none;
			cursor: pointer;
		}
		img {
			-webkit-filter: grayscale(70%);
			filter: grayscale(70%);
		}
		&:hover {
			img {
				filter: none;
			}
		}
		h4 {
			text-transform: none;
			margin: 0px;
			padding: 5px;
			text-align: left;
		}
		h5 {
			text-transform: none;
			margin: 0px;
			padding: 5px;
			text-align: left;
			font-weight: 400;
			line-height: 20px;
			font-size: 14px;
			white-space: nowrap;
			overflow: hidden;
			width: 100%;
		}
	}
	.um-item-title {
		h2 {
			margin: 0px;
			padding: 10px 0px;
			font-size: 18px;
			font-weight: 600;
			text-align: center;
		}
	}
	.um-gi-nf-caption-block {
		background-color: rgba(0,0,0,0.5);
		border-radius: 0px;
		left: 0.9rem;
		right: 0.9rem;
		bottom: 1.4rem;
		color: #fff;
		font-weight: 300;
		display: block;
		padding: 7px;
		position: absolute;
		text-align: center;
		text-transform: uppercase;	
	}
}
.um-gal-no-move-gal-caption {
	.um-gi-nf-caption-block {
		position: initial;
		display: block;
		background: white;
		color: #333;
		border: 1px solid transparent;
		margin-top: -7px;
	}
	.um-gallery-item {
		img {
			border: 1px solid transparent;
		}
		h5 {
			color: #5f5f5f;
			text-align: center;
		}
		h4 {
			color: #5f5f5f;
			text-align: center;
		}
		a {
			color: #333;
			font-weight: 600;
		}
	}
}
.um-gal-no-move-img-caption {
	.um-gallery-layout-filter {
		.um-gi-caption-block {
			position: initial;
			display: block;
			background: white;
			color: #333;
			border: 1px solid transparent;
			margin-top: -7px;
			h4 {
				text-align: center;
			}
		}
		.um-gal-image {
			img {
				border: 1px solid transparent;
			}
		}
	}
	.um-gi-caption-block {
		position: initial;
		display: block;
		background: white;
		color: #333;
		border: 1px solid transparent;
		margin-top: -7px;
		h5 {
			color: #5f5f5f;
			text-align: center;
		}
	}
	.um-ginf-caption-block {
		position: initial;
		display: block;
		background: white;
		color: #333;
		border: 1px solid transparent;
		margin-top: -7px;
		h5 {
			color: #5f5f5f;
			text-align: center;
		}
		h4 {
			padding: 10px;
			text-align: center;
			font-weight: 600;
		}
	}
	.um-gal-template-slider {
		.um-ginf-caption-block {
			position: initial;
			display: block;
			background: white;
			color: #333;
			border: 1px solid transparent;
			margin-top: -7px;
			h5 {
				color: #5f5f5f;
				text-align: center;
			}
		}
	}
	um-gallery-layout-filter {
		.um-gi-caption-block {
			h5 {
				color: #5f5f5f;
				text-align: center;
			}
		}
	}
	.um-gal-image {
		img {
			border: 1px solid transparent;
		}
	}
}
.um-gal-no-gray-img {
	.um-gallery-layout-filter {
		.um-gal-image {
			img {
				filter: none;
			}
		}
	}
	.um-gal-image {
		img {
			filter: none;
		}
	}
	.um-gallery-items-widget {
		.um-gal-image {
			img {
				filter: none;
			}
		}
	}
}
.um-gal-no-gray-gal {
	.um-gallery-layout-no-filter {
		.um-gallery-item {
			img {
				filter: none;
			}
		}
	}
}
