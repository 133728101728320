$color_1: #f8f8f8;
$color_2: #000;
$color_3: #999;
$color_4: #c4c4c4;
$color_5: #fff;
$font_family_1: Georgia;
$background_color_1: #fff;
$border_color_1: #fff;

div.pp_default {
	.pp_top {
		height: 13px;
		.pp_middle {
			height: 13px;
			background: url(../images/prettyPhoto/default/sprite_x.png) top left repeat-x;
		}
		.pp_left {
			height: 13px;
			background: url(../images/prettyPhoto/default/sprite.png) -78px -93px no-repeat;
		}
		.pp_right {
			height: 13px;
			background: url(../images/prettyPhoto/default/sprite.png) -112px -93px no-repeat;
		}
	}
	.pp_bottom {
		height: 13px;
		.pp_left {
			height: 13px;
			background: url(../images/prettyPhoto/default/sprite.png) -78px -127px no-repeat;
		}
		.pp_middle {
			height: 13px;
			background: url(../images/prettyPhoto/default/sprite_x.png) bottom left repeat-x;
		}
		.pp_right {
			height: 13px;
			background: url(../images/prettyPhoto/default/sprite.png) -112px -127px no-repeat;
		}
	}
	.pp_content {
		.ppt {
			color: $color_1;
		}
		background-color: $background_color_1;
	}
	.pp_content_container {
		.pp_left {
			background: url(../images/prettyPhoto/default/sprite_y.png) -7px 0 repeat-y;
			padding-left: 13px;
		}
		.pp_right {
			background: url(../images/prettyPhoto/default/sprite_y.png) top right repeat-y;
			padding-right: 13px;
		}
		.pp_details {
			margin-top: 5px;
		}
	}
	.pp_next {
		&:hover {
			background: url(../images/prettyPhoto/default/sprite_next.png) center right no-repeat;
			cursor: pointer;
		}
	}
	.pp_previous {
		&:hover {
			background: url(../images/prettyPhoto/default/sprite_prev.png) center left no-repeat;
			cursor: pointer;
		}
	}
	.pp_expand {
		background: url(../images/prettyPhoto/default/sprite.png) 0 -29px no-repeat;
		cursor: pointer;
		width: 28px;
		height: 28px;
		&:hover {
			background: url(../images/prettyPhoto/default/sprite.png) 0 -56px no-repeat;
			cursor: pointer;
		}
	}
	.pp_contract {
		background: url(../images/prettyPhoto/default/sprite.png) 0 -84px no-repeat;
		cursor: pointer;
		width: 28px;
		height: 28px;
		&:hover {
			background: url(../images/prettyPhoto/default/sprite.png) 0 -113px no-repeat;
			cursor: pointer;
		}
	}
	.pp_close {
		width: 30px;
		height: 30px;
		background: url(../images/prettyPhoto/default/sprite.png) 2px 1px no-repeat;
		cursor: pointer;
		&:hover {
			opacity: 0.7;
		}
	}
	#pp_full_res {
		.pp_inline {
			color: $color_2;
		}
	}
	.pp_gallery {
		ul {
			li {
				a {
					background: url(../images/prettyPhoto/default/default_thumb.png) center center #f8f8f8;
					border: 1px solid #aaa;
					&:hover {
						border-color: $border_color_1;
					}
				}
			}
			li.selected {
				a {
					border-color: $border_color_1;
				}
			}
		}
		a.pp_arrow_previous {
			position: static;
			left: auto;
		}
		a.pp_arrow_next {
			position: static;
			left: auto;
		}
	}
	.pp_social {
		margin-top: 7px;
	}
	.pp_nav {
		.pp_play {
			background: url(../images/prettyPhoto/default/sprite.png) -51px 1px no-repeat;
			height: 30px;
			width: 30px;
			&:hover {
				opacity: 0.7;
			}
		}
		.pp_pause {
			background: url(../images/prettyPhoto/default/sprite.png) -51px 1px no-repeat;
			height: 30px;
			width: 30px;
			background-position: -51px -29px;
			&:hover {
				opacity: 0.7;
			}
		}
		clear: none;
		height: 30px;
		width: 110px;
		position: relative;
		.currentTextHolder {
			font-family: $font_family_1;
			font-style: italic;
			color: $color_3;
			font-size: 11px;
			left: 75px;
			line-height: 25px;
			margin: 0;
			padding: 0 0 0 10px;
			position: absolute;
			top: 2px;
		}
	}
	.pp_details {
		position: relative;
	}
	a.pp_arrow_previous {
		background: url(../images/prettyPhoto/default/sprite.png) -31px -3px no-repeat;
		height: 20px;
		margin: 4px 0 0 0;
		width: 20px;
	}
	a.pp_arrow_next {
		background: url(../images/prettyPhoto/default/sprite.png) -31px -3px no-repeat;
		height: 20px;
		margin: 4px 0 0 0;
		width: 20px;
		left: 52px;
		background-position: -82px -3px;
	}
	.pp_arrow_next {
		&:hover {
			opacity: 0.7;
		}
	}
	.pp_arrow_previous {
		&:hover {
			opacity: 0.7;
		}
	}
	.pp_description {
		font-size: 11px;
		font-weight: bold;
		line-height: 14px;
		margin: 5px 50px 5px 0;
	}
	.pp_loaderIcon {
		background: url(../images/prettyPhoto/default/loader.gif) center center no-repeat;
	}
}
div.light_rounded {
	.pp_top {
		.pp_left {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -53px no-repeat;
		}
		.pp_middle {
			background: #fff;
		}
		.pp_right {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -53px no-repeat;
		}
	}
	.pp_content {
		.ppt {
			color: $color_2;
		}
		background-color: $background_color_1;
	}
	.pp_content_container {
		.pp_left {
			background: #fff;
		}
		.pp_right {
			background: #fff;
		}
	}
	.pp_next {
		&:hover {
			background: url(../images/prettyPhoto/light_rounded/btnNext.png) center right no-repeat;
			cursor: pointer;
		}
	}
	.pp_previous {
		&:hover {
			background: url(../images/prettyPhoto/light_rounded/btnPrevious.png) center left no-repeat;
			cursor: pointer;
		}
	}
	.pp_expand {
		background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -31px -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_contract {
		background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_close {
		width: 75px;
		height: 22px;
		background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -1px no-repeat;
		cursor: pointer;
	}
	.pp_details {
		position: relative;
	}
	.pp_description {
		margin-right: 85px;
	}
	#pp_full_res {
		.pp_inline {
			color: $color_2;
		}
	}
	.pp_gallery {
		a.pp_arrow_previous {
			margin-top: 12px !important;
		}
		a.pp_arrow_next {
			margin-top: 12px !important;
		}
	}
	.pp_nav {
		.pp_play {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -1px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
		.pp_pause {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -24px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
	}
	.pp_arrow_previous {
		background: url(../images/prettyPhoto/light_rounded/sprite.png) 0 -71px no-repeat;
	}
	.pp_arrow_previous.disabled {
		background-position: 0 -87px;
		cursor: default;
	}
	.pp_arrow_next {
		background: url(../images/prettyPhoto/light_rounded/sprite.png) -22px -71px no-repeat;
	}
	.pp_arrow_next.disabled {
		background-position: -22px -87px;
		cursor: default;
	}
	.pp_bottom {
		.pp_left {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -88px -80px no-repeat;
		}
		.pp_middle {
			background: #fff;
		}
		.pp_right {
			background: url(../images/prettyPhoto/light_rounded/sprite.png) -110px -80px no-repeat;
		}
	}
	.pp_loaderIcon {
		background: url(../images/prettyPhoto/light_rounded/loader.gif) center center no-repeat;
	}
}
div.dark_rounded {
	.pp_top {
		.pp_left {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -53px no-repeat;
		}
		.pp_middle {
			background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
		}
		.pp_right {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -53px no-repeat;
		}
	}
	.pp_content_container {
		.pp_left {
			background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat-y;
		}
		.pp_right {
			background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top right repeat-y;
		}
	}
	.pp_content {
		background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
	}
	.pp_next {
		&:hover {
			background: url(../images/prettyPhoto/dark_rounded/btnNext.png) center right no-repeat;
			cursor: pointer;
		}
	}
	.pp_previous {
		&:hover {
			background: url(../images/prettyPhoto/dark_rounded/btnPrevious.png) center left no-repeat;
			cursor: pointer;
		}
	}
	.pp_expand {
		background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -31px -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_contract {
		background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_close {
		width: 75px;
		height: 22px;
		background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -1px no-repeat;
		cursor: pointer;
	}
	.pp_details {
		position: relative;
	}
	.pp_description {
		margin-right: 85px;
		color: $color_5;
	}
	.currentTextHolder {
		color: $color_4;
	}
	#pp_full_res {
		.pp_inline {
			color: $color_5;
		}
	}
	.pp_gallery {
		a.pp_arrow_previous {
			margin-top: 12px !important;
		}
		a.pp_arrow_next {
			margin-top: 12px !important;
		}
	}
	.pp_nav {
		.pp_play {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -1px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
		.pp_pause {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -24px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
	}
	.pp_arrow_previous {
		background: url(../images/prettyPhoto/dark_rounded/sprite.png) 0 -71px no-repeat;
	}
	.pp_arrow_previous.disabled {
		background-position: 0 -87px;
		cursor: default;
	}
	.pp_arrow_next {
		background: url(../images/prettyPhoto/dark_rounded/sprite.png) -22px -71px no-repeat;
	}
	.pp_arrow_next.disabled {
		background-position: -22px -87px;
		cursor: default;
	}
	.pp_bottom {
		.pp_left {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -88px -80px no-repeat;
		}
		.pp_middle {
			background: url(../images/prettyPhoto/dark_rounded/contentPattern.png) top left repeat;
		}
		.pp_right {
			background: url(../images/prettyPhoto/dark_rounded/sprite.png) -110px -80px no-repeat;
		}
	}
	.pp_loaderIcon {
		background: url(../images/prettyPhoto/dark_rounded/loader.gif) center center no-repeat;
	}
}
div.dark_square {
	.pp_left {
		background: #000;
	}
	.pp_middle {
		background: #000;
	}
	.pp_right {
		background: #000;
	}
	.pp_content {
		background: #000;
	}
	.currentTextHolder {
		color: $color_4;
	}
	.pp_description {
		color: $color_5;
		margin: 0 85px 0 0;
	}
	.pp_loaderIcon {
		background: url(../images/prettyPhoto/dark_square/loader.gif) center center no-repeat;
	}
	.pp_expand {
		background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/dark_square/sprite.png) -31px -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_contract {
		background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_close {
		width: 75px;
		height: 22px;
		background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -1px no-repeat;
		cursor: pointer;
	}
	.pp_details {
		position: relative;
	}
	#pp_full_res {
		.pp_inline {
			color: $color_5;
		}
	}
	.pp_gallery {
		a.pp_arrow_previous {
			margin-top: 12px !important;
		}
		a.pp_arrow_next {
			margin-top: 12px !important;
		}
	}
	.pp_nav {
		clear: none;
		.pp_play {
			background: url(../images/prettyPhoto/dark_square/sprite.png) -1px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
		.pp_pause {
			background: url(../images/prettyPhoto/dark_square/sprite.png) -24px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
	}
	.pp_arrow_previous {
		background: url(../images/prettyPhoto/dark_square/sprite.png) 0 -71px no-repeat;
	}
	.pp_arrow_previous.disabled {
		background-position: 0 -87px;
		cursor: default;
	}
	.pp_arrow_next {
		background: url(../images/prettyPhoto/dark_square/sprite.png) -22px -71px no-repeat;
	}
	.pp_arrow_next.disabled {
		background-position: -22px -87px;
		cursor: default;
	}
	.pp_next {
		&:hover {
			background: url(../images/prettyPhoto/dark_square/btnNext.png) center right no-repeat;
			cursor: pointer;
		}
	}
	.pp_previous {
		&:hover {
			background: url(../images/prettyPhoto/dark_square/btnPrevious.png) center left no-repeat;
			cursor: pointer;
		}
	}
}
div.light_square {
	.pp_left {
		background: #fff;
	}
	.pp_middle {
		background: #fff;
	}
	.pp_right {
		background: #fff;
	}
	.pp_content {
		background: #fff;
		.ppt {
			color: $color_2;
		}
	}
	.pp_expand {
		background: url(../images/prettyPhoto/light_square/sprite.png) -31px -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/light_square/sprite.png) -31px -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_contract {
		background: url(../images/prettyPhoto/light_square/sprite.png) 0 -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/light_square/sprite.png) 0 -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_close {
		width: 75px;
		height: 22px;
		background: url(../images/prettyPhoto/light_square/sprite.png) -1px -1px no-repeat;
		cursor: pointer;
	}
	.pp_details {
		position: relative;
	}
	.pp_description {
		margin-right: 85px;
	}
	#pp_full_res {
		.pp_inline {
			color: $color_2;
		}
	}
	.pp_gallery {
		a.pp_arrow_previous {
			margin-top: 12px !important;
		}
		a.pp_arrow_next {
			margin-top: 12px !important;
		}
	}
	.pp_nav {
		.pp_play {
			background: url(../images/prettyPhoto/light_square/sprite.png) -1px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
		.pp_pause {
			background: url(../images/prettyPhoto/light_square/sprite.png) -24px -100px no-repeat;
			height: 15px;
			width: 14px;
		}
	}
	.pp_arrow_previous {
		background: url(../images/prettyPhoto/light_square/sprite.png) 0 -71px no-repeat;
	}
	.pp_arrow_previous.disabled {
		background-position: 0 -87px;
		cursor: default;
	}
	.pp_arrow_next {
		background: url(../images/prettyPhoto/light_square/sprite.png) -22px -71px no-repeat;
	}
	.pp_arrow_next.disabled {
		background-position: -22px -87px;
		cursor: default;
	}
	.pp_next {
		&:hover {
			background: url(../images/prettyPhoto/light_square/btnNext.png) center right no-repeat;
			cursor: pointer;
		}
	}
	.pp_previous {
		&:hover {
			background: url(../images/prettyPhoto/light_square/btnPrevious.png) center left no-repeat;
			cursor: pointer;
		}
	}
	.pp_loaderIcon {
		background: url(../images/prettyPhoto/light_rounded/loader.gif) center center no-repeat;
	}
}
div.facebook {
	.pp_top {
		.pp_left {
			background: url(../images/prettyPhoto/facebook/sprite.png) -88px -53px no-repeat;
		}
		.pp_middle {
			background: url(../images/prettyPhoto/facebook/contentPatternTop.png) top left repeat-x;
		}
		.pp_right {
			background: url(../images/prettyPhoto/facebook/sprite.png) -110px -53px no-repeat;
		}
	}
	.pp_content {
		.ppt {
			color: $color_2;
		}
		background: #fff;
	}
	.pp_content_container {
		.pp_left {
			background: url(../images/prettyPhoto/facebook/contentPatternLeft.png) top left repeat-y;
		}
		.pp_right {
			background: url(../images/prettyPhoto/facebook/contentPatternRight.png) top right repeat-y;
		}
	}
	.pp_expand {
		background: url(../images/prettyPhoto/facebook/sprite.png) -31px -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/facebook/sprite.png) -31px -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_contract {
		background: url(../images/prettyPhoto/facebook/sprite.png) 0 -26px no-repeat;
		cursor: pointer;
		&:hover {
			background: url(../images/prettyPhoto/facebook/sprite.png) 0 -47px no-repeat;
			cursor: pointer;
		}
	}
	.pp_close {
		width: 22px;
		height: 22px;
		background: url(../images/prettyPhoto/facebook/sprite.png) -1px -1px no-repeat;
		cursor: pointer;
	}
	.pp_details {
		position: relative;
	}
	.pp_description {
		margin: 0 37px 0 0;
	}
	#pp_full_res {
		.pp_inline {
			color: $color_2;
		}
	}
	.pp_loaderIcon {
		background: url(../images/prettyPhoto/facebook/loader.gif) center center no-repeat;
	}
	.pp_arrow_previous {
		background: url(../images/prettyPhoto/facebook/sprite.png) 0 -71px no-repeat;
		height: 22px;
		margin-top: 0;
		width: 22px;
	}
	.pp_arrow_previous.disabled {
		background-position: 0 -96px;
		cursor: default;
	}
	.pp_arrow_next {
		background: url(../images/prettyPhoto/facebook/sprite.png) -32px -71px no-repeat;
		height: 22px;
		margin-top: 0;
		width: 22px;
	}
	.pp_arrow_next.disabled {
		background-position: -32px -96px;
		cursor: default;
	}
	.pp_nav {
		margin-top: 0;
		p {
			font-size: 15px;
			padding: 0 3px 0 4px;
		}
		.pp_play {
			background: url(../images/prettyPhoto/facebook/sprite.png) -1px -123px no-repeat;
			height: 22px;
			width: 22px;
		}
		.pp_pause {
			background: url(../images/prettyPhoto/facebook/sprite.png) -32px -123px no-repeat;
			height: 22px;
			width: 22px;
		}
	}
	.pp_next {
		&:hover {
			background: url(../images/prettyPhoto/facebook/btnNext.png) center right no-repeat;
			cursor: pointer;
		}
	}
	.pp_previous {
		&:hover {
			background: url(../images/prettyPhoto/facebook/btnPrevious.png) center left no-repeat;
			cursor: pointer;
		}
	}
	.pp_bottom {
		.pp_left {
			background: url(../images/prettyPhoto/facebook/sprite.png) -88px -80px no-repeat;
		}
		.pp_middle {
			background: url(../images/prettyPhoto/facebook/contentPatternBottom.png) top left repeat-x;
		}
		.pp_right {
			background: url(../images/prettyPhoto/facebook/sprite.png) -110px -80px no-repeat;
		}
	}
}
div.pp_pic_holder {
	a {
		&:focus {
			outline: none;
		}
	}
	display: none;
	position: absolute;
	width: 100px;
	z-index: 10000;
}
div.pp_overlay {
	background: #000;
	display: none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9500;
}
.pp_top {
	height: 20px;
	position: relative;
	.pp_left {
		height: 20px;
		left: 0;
		position: absolute;
		width: 20px;
	}
	.pp_middle {
		height: 20px;
		left: 20px;
		position: absolute;
		right: 20px;
	}
	.pp_right {
		height: 20px;
		left: auto;
		position: absolute;
		right: 0;
		top: 0;
		width: 20px;
	}
}
* {
	html {
		.pp_top {
			padding: 0 20px;
			.pp_middle {
				left: 0;
				position: static;
			}
		}
		.pp_content {
			width: 40px;
		}
		.pp_bottom {
			padding: 0 20px;
			.pp_middle {
				left: 0;
				position: static;
			}
		}
	}
}
.pp_content {
	height: 40px;
	min-width: 40px;
}
.pp_fade {
	display: none;
}
.pp_content_container {
	position: relative;
	text-align: left;
	width: 100%;
	.pp_left {
		padding-left: 20px;
	}
	.pp_right {
		padding-right: 20px;
	}
	.pp_details {
		float: left;
		margin: 10px 0 2px 0;
	}
}
.pp_description {
	display: none;
	margin: 0;
}
.pp_social {
	float: left;
	margin: 0;
	.facebook {
		float: left;
		margin-left: 5px;
		width: 55px;
		overflow: hidden;
	}
	.twitter {
		float: left;
	}
}
.pp_nav {
	clear: right;
	float: left;
	margin: 3px 10px 0 0;
	p {
		float: left;
		margin: 2px 4px;
		white-space: nowrap;
	}
	.pp_play {
		float: left;
		margin-right: 4px;
		text-indent: -10000px;
	}
	.pp_pause {
		float: left;
		margin-right: 4px;
		text-indent: -10000px;
	}
}
a.pp_arrow_previous {
	display: block;
	float: left;
	height: 15px;
	margin-top: 3px;
	overflow: hidden;
	text-indent: -10000px;
	width: 14px;
}
a.pp_arrow_next {
	display: block;
	float: left;
	height: 15px;
	margin-top: 3px;
	overflow: hidden;
	text-indent: -10000px;
	width: 14px;
}
.pp_hoverContainer {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2000;
}
.pp_gallery {
	display: none;
	left: 50%;
	margin-top: -50px;
	position: absolute;
	z-index: 10000;
	div {
		float: left;
		overflow: hidden;
		position: relative;
	}
	ul {
		float: left;
		height: 35px;
		margin: 0 0 0 5px;
		padding: 0;
		position: relative;
		white-space: nowrap;
		a {
			border: 1px #000 solid;
			border: 1px rgba(0,0,0,0.5) solid;
			display: block;
			float: left;
			height: 33px;
			overflow: hidden;
			&:hover {
				border-color: $border_color_1;
			}
			img {
				border: 0;
			}
		}
	}
	li.selected {
		a {
			border-color: $border_color_1;
		}
	}
	li {
		display: block;
		float: left;
		margin: 0 5px 0 0;
		padding: 0;
	}
	li.default {
		a {
			background: url(../images/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
			display: block;
			height: 33px;
			width: 50px;
			img {
				display: none;
			}
		}
	}
	.pp_arrow_previous {
		margin-top: 7px !important;
	}
	.pp_arrow_next {
		margin-top: 7px !important;
	}
}
a.pp_next {
	background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
	display: block;
	float: right;
	height: 100%;
	text-indent: -10000px;
	width: 49%;
}
a.pp_previous {
	background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
	display: block;
	float: left;
	height: 100%;
	text-indent: -10000px;
	width: 49%;
}
a.pp_expand {
	cursor: pointer;
	display: none;
	height: 20px;
	position: absolute;
	right: 30px;
	text-indent: -10000px;
	top: 10px;
	width: 20px;
	z-index: 20000;
}
a.pp_contract {
	cursor: pointer;
	display: none;
	height: 20px;
	position: absolute;
	right: 30px;
	text-indent: -10000px;
	top: 10px;
	width: 20px;
	z-index: 20000;
}
a.pp_close {
	position: absolute;
	right: 0;
	top: 0;
	display: block;
	line-height: 22px;
	text-indent: -10000px;
}
.pp_bottom {
	height: 20px;
	position: relative;
	.pp_left {
		height: 20px;
		left: 0;
		position: absolute;
		width: 20px;
	}
	.pp_middle {
		height: 20px;
		left: 20px;
		position: absolute;
		right: 20px;
	}
	.pp_right {
		height: 20px;
		left: auto;
		position: absolute;
		right: 0;
		top: 0;
		width: 20px;
	}
}
.pp_loaderIcon {
	display: block;
	height: 24px;
	left: 50%;
	margin: -12px 0 0 -12px;
	position: absolute;
	top: 50%;
	width: 24px;
}
#pp_full_res {
	line-height: 1 !important;
	.pp_inline {
		text-align: left;
		p {
			margin: 0 0 15px 0;
		}
	}
}
div.ppt {
	color: $color_5;
	display: none;
	font-size: 17px;
	margin: 0 0 5px 15px;
	z-index: 9999;
}
