//  //   if your changes deeper copy
//  Magento_Theme/styles/module/_common.scss
.header-links {
  font-size: $font-size__sm;
  .text {
    @extend .abs-visually-hidden;
  }
}

@include min-screen($screen__m) {
  .header-links {
    font-size: $font-size__sm;
    display: block;
    position: relative;
    width: auto;
    @include lib-dropdown(
        $_toggle-selector                     : '.action.showlinks',
        $_options-selector                    : '.header.links',
        $_icon-font: $icons-customer__font-name,
        $_dropdown-toggle-icon-content        : $icon-user,
        $_dropdown-toggle-active-icon-content : $icon-user,
        $_dropdown-list-item-padding          : false,
        $_dropdown-list-item-hover            : false,
        $_icon-font-position                  : before,
        $_icon-font-size                      : 22px,
        $_icon-font-line-height               : 22px,
        $_icon-font-color                     : $theme--accent-color,
        $_icon-font-color-hover               : $theme--accent-color--hover,
        $_icon-font-color-active              : $theme--accent-color
    );

    &.is--show-links {
      z-index: 7;
      background: #fff;
      > .header.links {
        @include box-shadow($color: $header-box-shadow);

        display: block;
      }
      li {
        display: block;
      }
    }
    .action.showlinks {
        padding: 0 20px 10px;
        position: relative;
        top: 4px;
      &:before {
        line-height: 24px;
      }
    }

    .header.links {

      @include lib-list-reset-styles();
      font-size: 0;
      top: calc(100%);
      right: -12px;
      position: absolute;
      list-style: none;
      min-width: 200px;

      margin: 0;
      padding: 10px 15px 10px 10px;
      text-transform: uppercase;
      &:before {
        left: auto;
        right: 28px;
      }
      &:after {
        left: auto;
        right: 27px;
      }
      & > li {
        font-size: $font-size__base;
        margin: 0;
        font-family: $font-family__base;
        &.welcome,
        a {
          line-height: 1.4;
          color: $text__color;
          font-weight: $font-weight__regular;
          &:hover {
            color: $link__hover__color;
            text-decoration: none;
          }
        }
        &:not(:first-of-type)   {
          a {
            display: inline-block;
            padding-top: 5px;
            margin-top: 3px;
          }
        }
        &.welcome {
          a {
            padding-left: $indent__xs;
          }
        }
      }
      li {
      }

      li:first-of-type {
      }

    }
  }

}
