//
//  Swatches
//  ---------------------------------------------
$swatches-border: $theme__secondary--color;
$swatches-border-selected: $theme--special-color;

.swatch-opt {
  margin: 10px 0 20px;
  max-width: 380px;
  float: left;
  width: 100%;
}
.swatch-option.color:not(.disabled):hover,
.swatch-option.image:not(.disabled):hover {
  outline: 2px solid $swatches-border-selected;
}
.swatch-attribute {
  display: block;
  /*float: left;*/
  align-items: center;
  margin-top: 15px;
  @include min-screen($screen__m) {
    flex-wrap: wrap;
    float: left;
    width: 100%;
  }
  &-options {
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 34px;
  }
  &-label {
    flex: 0 1 21%;
    @include min-screen($screen__m) {
      flex: 0 1 100px;
    }
  }
  &:first-child {
    margin-top: 0;
  }
}
#layered-filter-block {
  .filter-options-item {
    float: left;
    width: 100%;
    .swatch-option {
      max-width: 22px;
      max-height: 20px;
      min-width: 22px;
    }
  }
}

.swatch-option.text {
  padding: 8px;
  line-height: 1;
  border-radius: 50%;
  font-family: $font-family__base;
  font-weight: $font-weight__regular;
  height: 34px;
}
.swatch-option {
  border-color: transparent;
  display: flex;
  align-items: center;
  height: 30px;
  border: 1px solid #666666;
  max-width: 22px;
  min-width: 22px;
  max-height: 20px;
  background-size: 22px 20px !important;

  &.selected {
    outline: 0 none;
    opacity: 1;
      border-width: 2px;
  }
  &:hover,
  &.color:not(.disabled):hover {
    outline: 0 none;
  }
}
.swatch-attribute.manufacturer .swatch-option,
.swatch-attribute.size .swatch-option {
  background: transparent;
  color: $text__color;
  border: 1px solid $swatches-border;
}
.swatch-attribute.manufacturer .swatch-option.selected,
.swatch-attribute.size .swatch-option.selected,
.swatch-option:not(.disabled):hover {
  border: 1px solid $swatches-border-selected;
  color: $link__hover__color;
  outline: 0 none;
}
.swatch-option.selected {
  outline: 0 none;
  border-color: $swatches-border-selected;
}
.swatch-attribute-label {
  font-weight: $font-weight__bold;
  font-size: $font-size__m;
}
.swatch-attribute-selected-option {
  display: none;
}

.swatch-select {
  width: 265px;
  max-width: 100%;
  background-position: 96% -7px;
}
.swatch-attribute-options {
  display: block;

}

.swatch-option-tooltip .corner:before, .swatch-option-tooltip-layered .corner:before {
  z-index:12;
}
