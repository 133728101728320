.footer {
  &__payment-ship {

  }
  &__payment {
    margin-bottom: 10px;
    p {
      margin-bottom: 16px;
    }
  }

}

.payment-icon {
  &__links {
    display: flex;
    flex-wrap: wrap;
  }
  @at-root #{&}__link  {
    background-image: url('../images/svgs/icons.png');
    background-position: 0 0;
    background-repeat: no-repeat;

    .footer.content & {
      margin-bottom: 10px;
    }
    a {
      display: block;
      height: 30px;
      width: 47px;
      margin-right: 10px;
    }
    &:last-child {
      a {
        margin-right: 0;
      }
    }
    &:hover {
      &.payment-icon--visa {
        background-position:0 -330px;
      }
      &.payment-icon--amex {
        background-position:0 -30px;
      }
      &.payment-icon--maestro {
        background-position:0 -90px;
      }
      &.payment-icon--master {
        background-position:0 -150px;
      }
      &.payment-icon--sofort {
        background-position:0 -270px;
      }
      &.payment-icon--paypal {
        background-position:0 -210px;
      }
    }
  }
  &--visa {
    background-position:0 -300px;
  }
  &--amex {
    background-position:0 0;
  }
  &--maestro {
    background-position:0 -60px;
  }
  &--master {
    background-position:0 -120px;
  }
  &--sofort {
    background-position:0 -240px;
  }
  &--paypal {
    background-position:0 -180px;
  }
}