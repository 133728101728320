.to-the-top {
  &.on {
    visibility: visible;
    right: 10px;
  }
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s linear;
  background: $color-white;
  border: 1px solid $theme__base--color;
  border-radius: 30px;
  svg {
    width: 12px;
    height: 20px;
    fill: $theme--accent-color;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    background: $theme__secondary--accent-color;
    border-color: $color-white;
    svg {
      fill: $color-white;
    }
  }
  @include min-screen($screen__m) {

    &.on {

    }
    svg {

    }
  }
}