//
// Global
// --------------------------------------------------
/** GLOBAL SHOP STYLES **/
* {
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
}
input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
input[type="email"] {
  font-size: $font-size__m;
}
select {
  appearance: none;
  background: #ffffff url('../images/select-bg.svg') no-repeat 100% 45%;
  background-size: 30px 60px;
  border: 1px solid $theme--grey-light;
  height: 32px;
  padding: 5px 25px 4px 10px;
  text-indent: .01em;
  text-overflow: '';
}
@include min-screen($screen__m) {
  .visible-mobile {
    display: none;
  }
}
.cms-content div:empty, .cms-content p:empty {
  display: none;
}
%clearfix {
  @include lib-clearfix();
  @include lib-clearer();
  clear: both;
}
@include max-screen($screen__m) {
  .page-title {
    margin-bottom: 15px;
  }
  .visible-desktop {
    display: none;
  }
}
