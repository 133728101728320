.promo {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	&--home {

	}
	&__item {
		position: relative;
		padding-right: 15px;
		padding-left: 15px;
		width: 100%;
		@include min-screen($screen__m) {
			width: 66.66666666666666%;

			&:first-child {
				width: 33.33333333333334%;
				.promo-image__holder {
				}
			}
		}

	}
	&-image__holder {
		position: relative;
	}
	&__image {
		display: block;
	}
	&__title {
		font-family: $font-family-name__heading;
		font-weight: $font-weight__regular;
		font-size: $font-size__l;
		position: relative;
		display: inline-block;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 15px;
		@include min-screen($screen__l) {
			font-size: $font-size__xl;
		}
		&:after,
		&:before {
			content: '•';
			position: absolute;
			font-size: 40px;
			display: inline-block;
			top: calc(50% - 3px);
			transform: translateY(-50%);
		}
		&:after {
			left: 0;
		}
		&:before {
			right: 0;
		}
	}
	&__button {
		position: absolute;
		bottom: 18px;
		z-index: 1;
		left: 50%;
		transform: translateX(-50%);
		display: block;
		color: $theme__base--color;
		margin-left: auto;
		margin-right: auto;
		border: 1px solid rgba(255, 255, 255, 0.80);
		font-weight: $font-weight__heavier;
		font-size: $font-size__xs;
		padding: 15px 20px;
		max-width: 100%;
		background: rgba(255, 255, 255, 0.80);
		text-transform: uppercase;
		&:hover, &:focus {
			border-color: $link__hover__color;
			background: $link__hover__color;
			text-decoration: none;
			color: $color-white;
		}
		&:visited {

		}
		@include min-screen($screen__m) {
			font-size: $font-size__s;
		}
	}
}