
.items {
    @include lib-list-reset-styles();
}

@include lib-font-face(
    $family-name: 'Montserrat',
    $font-path: '../fonts/montserrat-regular/montserrat-regular',
    $font-weight: 400,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Montserrat',
    $font-path: '../fonts/montserrat-bold/montserrat-bold',
    $font-weight: 700,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Montserrat',
    $font-path: '../fonts/montserrat-medium/montserrat-medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: 'Montserrat',
    $font-path: '../fonts/montserrat-semibold/montserrat-semibold',
    $font-weight: 600,
    $font-style: normal
);
@font-face {
    font-family: 'Pe-icon-7-stroke';
    src:url('../fonts/stroke_7/Pe-icon-7-stroke.eot?d7yf1v');
    src:url('../fonts/stroke_7/Pe-icon-7-stroke.eot?#iefixd7yf1v') format('embedded-opentype'),
    url('../fonts/stroke_7/Pe-icon-7-stroke.woff?d7yf1v') format('woff'),
    url('../fonts/stroke_7/Pe-icon-7-stroke.ttf?d7yf1v') format('truetype'),
    url('../fonts/stroke_7/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke') format('svg');
    font-weight: normal;
    font-style: normal;
}
//
//    Desktop
//--------------------------------------
h1 {
    margin-bottom: 15px;
}

@include min-screen($screen__l) {
    h1 {
        font-size: $h1__font-size-desktop;
        margin-bottom: 20px;
    }
}

@include min-screen($screen__l2) {
    h1 {
        margin-bottom: $h1__margin-bottom__desktop;
    }
}
