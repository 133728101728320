//   if your changes deeper copy
//  delete this file and make the changes on the Original File
// 13
.products-grid {
    [class^="swatch-opt"] {
        padding: 5px 10px 5px;
        display: inline-block;

        .swatch-attribute-options {
            display: inline-block;
        }
    }

    .product {
        &-item {
            text-align: center;

            &-info {
                margin-top: 15px;
                box-sizing: border-box;
                padding: 0 0 40px;
                width: 100%;
                position: relative;
                background: $color-white;
                border: 1px solid $border-grey-color;
                min-height: 445px;
                @media (min-width: $screen__m) and (max-width: $screen__m_plus) {
                    min-height: 375px;
                }
                @media (max-width: $screen__m) {
                    min-height: unset;
                }
            }
        }
    }
}

.block.widget .products-grid .product-items {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

// custom
.block-products-list:not(:first-child) {
}

@include min-screen($screen__s) {
    .products-grid {
        .product {
            &-item {
                margin-bottom: 0;

                &-info {
                    padding: 0 0 0px;
                    min-height: 375px;
                }
            }
        }
    }
}

// Line 62

@include max-screen($screen__s) {
    .block.widget .products-grid .product-item {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        width: 50%;
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;

        &:nth-child(2n+1) {
            margin-left: 0;
        }

        &:nth-child(2n+1) {
            margin-left: 0;
        }
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .block.widget .products-grid .product-item {
        width: 50%;
        margin-left: 0;
        padding: 0;

        &:nth-child(4n+1) {
            margin-left: 0;
        }

        &:nth-child(n+5) {
            display: none;
        }
    }
}

@media (min-width: $screen__s) and (max-width: $screen__l) {
    .block.widget .products-grid .product-item {
        &:nth-child(n+4),
        &:nth-child(n+5) {
            display: none;
        }
    }
}

@media (min-width: $screen__l) and (max-width: $screen__l_plus) {
    .block.widget .products-grid .product-item {
        &:nth-child(n+4) {
            display: block !important;
        }
    }
}

@include min-screen($screen__m) {
    .block.widget .products-grid .product-item {
        width: 33.33333333333333%;
        padding-left: 15px;
        padding-right: 15px;
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        width: 33.33333333333333%;
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;

        &:nth-child(4n+1) {
            margin-left: 0;
        }

        &:nth-child(5n+1) {
            margin-left: 0;
        }
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 33.33333333333333%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .block.widget .products-grid .product-item {
        width: 33.33333333333333%;
        margin-left: 0;
        padding: 0;

        &:nth-child(4n+1) {
            margin-left: 0;
        }
    }
}

@include min-screen($screen__l) {
    .block.widget .products-grid .product-item {
        width: 20%;
        margin-bottom: 25px;

        &:nth-child(n+5) {
            display: block;
        }

        &:nth-child(n+6) {
            display: none;
        }
    }
    .page-layout-1column .block.widget .products-grid .product-item {
        width: 20%;
    }
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 33.33333333333333%;

    }
    .block.widget .products-grid .product-item {
        width: 20%;
    }
}

.new-grid {
    .block {
        .block-title {
            text-align: left;

            strong {
                text-transform: uppercase;
                background: #e1e1e1;
                font-weight: 500;
                color: $color-dark-gray;
                font-size: 20px;
                padding: 10px 30px;
                margin-bottom: 0;
            }

            border-bottom: 2px solid $border-grey-color;
        }
    }

    .pager {
        display: none;
    }
}
