.partner {
	border-top: 1px solid $theme__border--base-color;
	border-bottom: 1px solid $theme__border--base-color;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 10px 0;
	margin: 0 -15px;

	@include min-screen($screen__m) {
	}
	@include min-screen($screen__l) {
		justify-content: space-between;
	}
	&__item {
		padding: 5px 15px;
		flex: 0 1 33%;
		@include min-screen($screen__m) {
			flex-basis: 16%;
		}
		@include min-screen($screen__l) {

		}
	}
}
