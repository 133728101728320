.fieldset {
  .legend {
  }
  > .field {
    > .label {
      font-size: $font-size__15;
      font-weight: $font-weight__semibold;
    }
    > .control {
      font-size: $font-size__15;
    }
  }
}
.product-add-form .fieldset {
  > .field {
    > .label {
      line-height: 2;
      margin-bottom: 13px;
    }
    > .control {
      margin-bottom: 11px;
      line-height: 2;
    }
  }
}
.product-add-form select {
  background-clip: padding-box;
  border: 1px solid $theme__secondary--color;
  border-radius: 0;
  color: inherit;
  font-size: $font-size__m;
  height: 48px;
  line-height: 28px;
  margin: 0;
  padding: 9px 20px 10px;
  box-sizing: border-box;
  -webkit-appearance:none; /* remove the strong OSX influence from Webkit */
}

@include min-screen($screen__l) {
  .product-add-form .fieldset {

    > .field, .field:not(.choice) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      > .label {
        line-height: 3.5;
        width: 100%;
        float: left;
      }
      > .control {
        line-height: 1;
        width: 65%;
        float: left;
      }
    }
  }
}