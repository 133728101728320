//   if your changes deeper copy
//  delete this file and make the changes on the Original File
// Line 15 extend

.products.list.items,
.table-comparison {
  .action {
    &.towishlist {
      @extend .abs-actions-addto-gridlist;
      &:before {
        content: none;
      }
    }
  }
}


.product-items {

  .actions-secondary {
    display: flex;
    > .action {
      display: flex;
      align-items: flex-start;
    }
  }
  .add-to-wishlist__svg {
    width: 23px;
    height: 23px;
    .st0 {
      fill: $color-white;
    }
  }
}

