.product-info-main {
	.box-tocart {
		display: block;
		margin: 0;
		.action.tocart {
			width: 370px;
			max-width: 100%;
			padding: 14px 12px 15px;
			font-family: $font-family-name__heading;
			line-height: 1.3;
			font-weight: $font-weight__heavier;
			font-size: $font-size__s;

		}
	}



	.action.towishlist {
		@extend .transparen-button;
		font-size: $font-size__s;
		line-height: 1.5;
		font-weight: $font-weight__heavier;
		font-family: $font-family-name__heading;
		position: relative;
		text-transform: uppercase;
		color: $link__color;
		padding: 17px;
		width: 100%;
		max-width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		&:after {
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size: 20px;
			line-height: 20px;
			color: $text__color;
			content: $icon-wishlist;
			font-family: $icons-customer__font-name;
			margin: 0;
			vertical-align: top;
			display: inline-block;
			font-weight: normal;
			overflow: hidden;
			speak: none;
			text-align: center;
			width: 31px;
		}


		&:hover {
			background-color: #666;
            border-color: #666;
			&:after {
				color: $color-white;
				fill: $color-white;
				stroke: $color-white;
			}
		}

        span {
            display: none;
        }
	}

    .product-addto-links {
        margin: 0 !important;
    }

	@include min-screen($screen__m) {
		.product-addto-links {

			.action.towishlist {
				width: 70px;
				padding: 19px 10px;
				font-size: $font-size__s + 1;

			}
		}
		.box-tocart {
			.action.tocart {
				padding: 20px 15px;
				font-size: $font-size__sm + 1;

			}
		}
	}

	@include min-screen($screen__l2 + 30) {

		.product-addto-links {
			.action.towishlist {
				padding: 17px 17px 18px;
				font-size: $font-size__s + 1;
				line-height: 1.8;
				&:after {
					font-size: 25px;
					line-height: 25px;
				}
			}
		}
		.box-tocart {
			.action.tocart {
				padding: 21px 13px;
				font-size: $font-size__sm;
			}
		}
	}

    @include max-screen($screen__m) {
        .box-tocart {
            .actions {
                text-align: left !important;

                #instant-purchase {
                    display: none;
                }
            }
            .action.tocart {
                width: calc(100% - 60px) !important;
                margin-right: 5px !important;
            }
        }
        .product-addto-links {
            width: 50px !important;
            margin-top: 0px !important;
            display: inline-block !important;

            .towishlist {
                width: 50px !important;
                padding: 12px;
            }
        }
    }

}

