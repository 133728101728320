.checkout-agreements-block {
    .checkout-agreements {
        margin-bottom: $indent__base;
        .checkout-agreement {
            display: flex;
            text-align: left;
             label .action-show:not(:focus) {
                text-align: left;
                padding-left: 10px;
                margin-bottom: 10px;
            }
            &:before {
                display: none;
            }
            input {
                margin-top: 5px;
                min-width: 16px;
            }
            label button span {
                color: $theme--special-color;
            }
            label {
                &:after {
                    display: none;
                }
            }
        }
        div.mage-error[generated] {
            @include min-screen($screen__m) {
                position: absolute;
                top: 15px;
            }
            color: $color-red9;
        }
    }

    .action-show {
        @extend .abs-action-button-as-link;
        vertical-align: baseline;
    }
}

//  Checkout Agreements in popup
.checkout-agreements-items {
    @extend .abs-reset-list;
    padding-bottom: $indent__l;

    .checkout-agreements-item {
        margin-bottom: $indent__base;
    }

    .checkout-agreements-item-title {
        @extend .abs-checkout-title;
        border-bottom: 0;
    }
}

//  Checkout Agreements
.checkout-agreement-item-content {
    overflow: auto;
}
