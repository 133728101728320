body {
    .um-gal-image {
        img {
            -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
        }
    }

    .um-gallery-layout-no-filter {
        .um-gallery-item {
            img {
                -webkit-filter: grayscale(0%);
                filter: grayscale(0%);
            }
        }
    }

    .um-gallery-widget-container {
        .block-title {
            display: none;
        }
    }
}

#maincontent {
    .columns {
        clear: both;
    }
}

.um-gallery-block,
.um-gallery-container-view {
    .um-gal-image {
        img {
            margin-bottom: 0;
        }
    }
}

.um-gal-no-move-img-caption .um-gal-template-slider .um-ginf-caption-block {
    margin: 0;
}

.um-gal-template-grid .um-gal-image-wrapper:nth-child(4n+1) {
    clear: both;
}

@media only screen and (max-width: 767px) {
    .um-gal-template-grid .um-gal-image-wrapper .um-gal-image {
        &:not(.um-gal-image-video) {
            pointer-events: none;
        }

        &.um-gal-image-video {
            .um-gi-icon {
                visibility: visible;
            }
        }
    }

    .pp_gallery,
    .pp_nav {
        display: none !important;
    }
}
