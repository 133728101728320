
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$enable-grid-classes:       true !default;
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	xl: 1140px
) !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;


//
//  Typography
//  ---------------------------------------------

//  Headings
$h1__margin-bottom__desktop                                   : $indent__xl !default;

//
//  Layout grid
//  ---------------------------------------------

//  Header
$header-icons-color                                           : $color-gray56 !default;
$header-icons-color-hover                                     : $color-gray20 !default;

//
//  Icons
//  ---------------------------------------------
//

$icon-edit                                                    : '\e606' !default;
$icon-bag                                                     : '\e800';
$icon-emblem                                                  : '\e801';
$icon-facebook                                                : '\e802';
$icon-instagram                                               : '\e803';
$icon-lagernd                                                 : '\e804';
$icon-lieferzeit                                              : '\e805';
$icon-nicht-lagernd                                           : '\e806';
$icon-search-2                                                : '\e807';
$icon-twitter                                                 : '\e808';
$icon-up-2                                                    : '\e809';
$icon-user                                                    : '\e80a';
$icon-user-1                                                  : '\e811';
$icon-wishlist_full                                           : '\e80b';
$icon-wishlist                                                : '\e80c';
$icon-twitter-2                                               : '\f099';
$icon-facebook-2                                              : '\f09a';
$icon-gplus                                                   : '\f0d5';
$icon-youtube                                                 : '\f167';
$icon-instagram-2                                             : '\f16d';
$icon-pinterest                                               : '\f231';
$icon-vimeo                                                   : '\f27d';
$icon-phone                                                   : '\e80e';
$icon-location                                                : '\e80d';
$icon-mail                                               	  : '\e80f';
$icon-clock                                             	  : '\e810';
$icons-customer__font-name: 'custom';
$icons-customer__font-path: "../fonts/aos/custom";


$icon-lagernd--font-size: 23px;

//
//  Popup
//  ---------------------------------------------

//  Checkout tooltip
$checkout-tooltip-icon-arrow__font-size                       : 10px !default;
$checkout-tooltip-icon__font-size                             : 21px !default;

$checkout-tooltip-content-mobile__right                       : -($indent__s) !default;
$checkout-tooltip-content-mobile__top                         : 30px + $checkout-tooltip-icon-arrow__font-size !default;
$checkout-tooltip-content__background-color                   : $color-gray-light01 !default;
$checkout-tooltip-content__padding                            : 12px !default;
$checkout-tooltip-content__border-width                       : 1px !default;

$checkout-tooltip-content__border-color                       : $color-gray60 !default;
$checkout-tooltip-content__font-size                          : $font-size__base !default;
$checkout-tooltip-content__width                              : 270px !default;
$checkout-tooltip-content__active__border-color               : darken($checkout-tooltip-content__border-color, 20%) !default;
$checkout-tooltip-icon-arrow__left                            : -( $checkout-tooltip-content__padding + $checkout-tooltip-icon-arrow__font-size - $checkout-tooltip-content__border-width) !default;

$checkout-billing-address-details__line-height                : 27px !default;

// Checkout
$checkout-step-title__padding                                 : $indent__s !default;
$checkout-step-title__border                                  : $border-width__base solid $color-gray80 !default;
$checkout-step-title__font-size                               : 20px !default;
$checkout-step-title__font-weight                             : $font-weight__light !default;
$checkout-sidebar__columns                                    : 4 !default;
$checkout-shipping-address__max-width                         : 500px !default;

//
//  Sidebar
//  ---------------------------------------------

// Used in cart sidebar, Checkout sidebar, Tier Prices, My account navigation, Rating block background
$sidebar__background-color                                    : $color-white-smoke !default;

//
//  Variables missing in extend.scss
//  ---------------------------------------------

$addto-color                                                  : $primary__color !default;
$addto-hover-color                                            : $link__hover__color !default;

//
//  Product
//  ---------------------------------------------

$product-name-link__color                                     : $text__color !default;
$product-name-link__color__active                             : $text__color !default;
$product-name-link__color__hover                              : $text__color !default;
$product-name-link__color__visited                            : $text__color !default;

$product-name-link__text-decoration                           : none !default;
$product-name-link__text-decoration__active                   : $link__hover__text-decoration !default;
$product-name-link__text-decoration__hover                    : $link__hover__text-decoration !default;
$product-name-link__text-decoration__visited                  : $link__hover__text-decoration !default;

//
//  Button
//  ---------------------------------------------

$button__shadow                                               : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

//
//  Minicart
//  ---------------------------------------------

$minicart-icons-color                                         : $theme__secondary--accent-color;
$minicart-icons-color-hover                                   : $header-icons-color-hover !default;

// ThemeStyle Begin
// Here You Can Also Override Variables from Modules
//  ---------------------------------------------

// ThemeStyle Begin
//  ---------------------------------------------


$font-family__sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-name__base: 'Open Sans';
$font-family-name__heading: 'Montserrat', Helvetica, Arial, sans-serif ;
$font-family__base: $font-family-name__base, $font-family__sans-serif;

$screen__xs2: 420px;
$screen__l2: 1170px;
$screen__l3: 1300px;


// Boxshadows
$standard-box: rgba(0,0,0,.12);
$header-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
$tothetop-box-shadow: rgba(0,0,0,.16);

// Header Search
$minisearch-hover-color: $primary__color;

// Header Minicart
$header-icons-color                       : $text__color;
$header-icons-color-hover                 : $link__hover__color;


$product-grid-items-per-row-layout-default         : 2;

$product-grid-items-per-row-layout-1-screen-s      : 3;
$product-grid-items-per-row-layout-1-screen-m      : 3;
$product-grid-items-per-row-layout-1-screen-l      : 3;

$product-grid-items-per-row-layout-2-left-screen-s : 3;
$product-grid-items-per-row-layout-2-left-screen-m : 3;
$product-grid-items-per-row-layout-2-left-screen-l : 3;

$product-grid-items-per-row-layout-2-right-screen-s: 3;
$product-grid-items-per-row-layout-2-right-screen-m: 3;
$product-grid-items-per-row-layout-2-right-screen-l: 3;

$product-grid-items-per-row-layout-3-screen-s      : 3;
$product-grid-items-per-row-layout-3-screen-m      : '';
$product-grid-items-per-row-layout-3-screen-l      : '';

// Product View
$product-info-price: 25px;
$towishlist-border: $link__color;

//  Color nesting
// ThemeStyle Begin
//  ---------------------------------------------


$primary__color: $theme__base--color;

$text__color: $text__color__grey;
$link__color: $theme--accent-color;

$link__text-decoration: none;

$link__visited__color: $link__color;
$link__visited__text-decoration: none;

$link__hover__color: $theme--accent-color--hover;
$link__hover__text-decoration: underline;

$link__active__color: $link__color;
$link__active__text-decoration: underline;


// form UI
$form-field-type-label-inline__align: left;


// Theme Generell
$theme__border--base-color: $theme__base--color;
// ThemeStyle End
//  --

$icon-font__color-hover: $theme--accent-color--hover;

// buttons
$button__font-size__l                         : $font-size__sm;
$button__line-height__l                       : $font-size__sm;
$button__padding__l                           : 18px 40px 18px 40px;