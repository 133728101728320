.listed-items {
  &.cms-items {
    > .block {
      padding-bottom: 0;
      @include min-screen($screen__l) {
        padding-bottom: 45px;
      }
    }
  }
}

.link.tooltip {
  text-decoration: underline;
}


.cms-page-view {
  .page-title-wrapper {
    margin:  0 auto;
    max-width: 800px;
  }
}
.block-content, .block-promo {
  display: block;
  font-size: $font-size__sm;
  line-height: 20px;
  p {
    font-size: $font-size__sm;
    line-height: 20px;
  }

  span.content, p {
    display: block;
    margin-bottom: 40px;
  }
  img {
    display: block;
    margin: 0 0 40px 0;
  }
}
.cms-content, .cms-page-view .column.main {
  color: $color-dark-gray;
  .slick-slider--outer {
    margin-top: 30px;
    padding-left: 0;
    padding-right: 0;
    .banner {
      &__item {
        min-height: inherit;
      }
    }
  }
  > *:not(.cms-content):not(script) {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    &.content__cms--img-width {
      max-width: 1280px;
      margin-bottom: 50px;
    }
    > div:not(.clearfix):first-child {
      margin-bottom: 50px;
    }
  }
  a {
    color: $theme__secondary--accent-color;
  }
  p {
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 1.67857;
    + img {
      margin-top: 25px;
      @include min-screen($screen__m) {
        margin-top: 50px;
      }
      @include max-screen($screen__xs) {
        p:first-child {
          margin-top: 0;
        }
      }
    }
    + p {
      > div.clearfix {
        margin-top: 25px;
        @include min-screen($screen__m) {
          margin-top: 50px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  > ul, > ol {
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.77857;
    padding-left: 15px;
    + img {
      margin-top: 50px;
      @include max-screen($screen__m) {
        margin-top: 25px;
      }
    }
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 18px;
    margin-top: 30px;
    &:first-child {
      margin-top: 0;
    }
  }
  > img:first-child, div.js-home-banners:first-child {
    max-width: 1280px;
    @include max-screen($screen__m) {
      margin-top: 0;
    }
  }

  > p:first-child:empty + img,  p:first-child:empty + div.js-home-banners {
    max-width: 1280px;

    @include max-screen($screen__m) {
      margin-top: 25px;
    }
  }
  > img, > div:not(.clearfix) {
    width: 100%;
    margin-bottom: 50px;
    @include max-screen($screen__m) {
      margin-bottom: 25px;
    }
  }
  img {
    &.margin-right {
      margin-right: 30px;
      margin-bottom: 15px;
      @include min-screen($screen__l2) {
        margin-left: -175px;
      }
    }
    &.margin-left {
      margin-left: 30px;
      margin-bottom: 15px;
      @include min-screen($screen__l2) {
        margin-right: -175px;
      }
    }

    @include max-screen($screen__l) {
      &.margin-right, &.margin-left {
        max-width: 40%;
      }
    }
    @include max-screen($screen__xs) {
      &.margin-right, &.margin-left {
        max-width: 100%;
        margin: 0;
        float: none !important;
      }
    }
  }
  table {
    display: table !important;
  }
}
form#contact-form {
  float: left;
  width: 100%;
  .honig {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 0;
      width: 0;
      z-index: -1;
  }
}